import actions from './actions';
import getters from './getters';
import state from './state';
import mutations from './mutations';

const namespaced = true;

export default {
  namespaced,
  actions,
  getters,
  state,
  mutations,
};
