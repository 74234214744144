const pluses = /\+/g;
const defaults = {};
const lpCookie = (() => {
  function raw(s) {
    return s;
  }

  function decoded(s) {
    return decodeURIComponent(s.replace(pluses, ' '));
  }

  function converted(s) {
    let str;
    if (s.indexOf('"') === 0) {
      // This is a quoted cookie as according to RFC2068, unescape
      str = s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
    }
    try {
      return JSON.parse(str);
    } catch (er) {
      // cannot use logger here as it will cause a circular dependency
      /* eslint-disable no-console */
      console.error('lp-cookie:converted - can not parse json', er);
    }
    return -1;
  }

  function set(key, value, options) {
    const opts = { ...defaults, ...options };
    if (typeof opts.expires === 'number') {
      const days = opts.expires;
      opts.expires = new Date();
      const t = opts.expires;
      t.setDate(t.getDate() + days);
    }
    const valueStr = JSON.stringify(JSON.stringify(value));

    (document.cookie = [
      this.raw ? key : encodeURIComponent(key),
      '=',
      this.raw ? valueStr : encodeURIComponent(valueStr),
      opts.expires ? `; expires=${opts.expires.toUTCString()}` : '', // use expires attribute, max-age is not supported by IE
      opts.path ? `; path=${opts.path}` : '',
      opts.domain ? `; domain=${opts.domain}` : '',
      opts.secure ? '; secure' : '',
    ].join(''));
    return document.cookie;
  }

  function get(key) {
    const decode = this.raw ? raw : decoded;
    const cookies = document.cookie.split('; ');
    let result = key ? undefined : {};
    for (let i = 0, l = cookies.length; i < l; i += 1) {
      const parts = cookies[i].split('=');
      const name = decode(parts.shift());
      const cookie = decode(parts.join('='));

      if (key && key === name) {
        result = converted.call(this, cookie);
        break;
      }

      if (!key) {
        result[name] = converted.call(this, cookie);
      }
    }
    return result;
  }

  function removeCookie(key, options) {
    if (this.get(key) !== undefined) {
      // Must not alter options, thus mergeing a fresh object...
      this.set(key, '', { ...options, expires: -1 });
      return true;
    }
    return false;
  }

  return {
    set,
    get,
    removeCookie,
    raw,
    decoded,
  };
})();

export default lpCookie;
