/**
 * @deprecated Since version 2.0. Will be deleted in 3.0. This isn't a complete implementation
 */
export const isEmpty = (obj) => (!obj || (!obj.length && !Object.keys(obj).length));

/**
 * @deprecated Since version 2.0. Will be deleted in 3.0. Use JSON.stringify instead
 */
export const stringify = (value) => {
  /* eslint-disable no-extend-native */
  let stringified;
  let toJSONPrototype;
  if (typeof Array.prototype.toJSON === 'function') {
    toJSONPrototype = Array.prototype.toJSON;
    delete Array.prototype.toJSON;
    try {
      stringified = JSON.stringify(value);
    } catch (e) {
      Array.prototype.toJSON = toJSONPrototype;
      throw e;
    }
    Array.prototype.toJSON = toJSONPrototype;
  } else {
    stringified = JSON.stringify(value);
  }
  return stringified;
};

/**
 * @deprecated Since version 2.0. Will be deleted in 3.0. Use https://developer.mozilla.org/en-US/docs/Web/API/structuredClone instead
 */
export const clone = (obj) => {
  let retVal;
  try {
    retVal = JSON.parse(stringify(obj));
  } catch (e) {
    // cannot use logger here as it will cause a circular dependency
    /* eslint-disable no-console */
    console.error('Unable to clone object', obj, e);
  }
  return retVal;
};

/**
 * @deprecated Since version 2.0. Will be deleted in 3.0.
 * Use `{ ...obj, ...extendObj }` instead
 */
export const extend = (obj, extendObj, deep) => {
  let newObj = {};

  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  if (!extendObj) {
    return clone(obj);
  }

  newObj = extendObj;
  Object.keys(obj).forEach((prop) => {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      newObj[prop] = deep ? extend(obj[prop], undefined, deep) : obj[prop];
    }
  });

  return newObj;
};

/**
 * @deprecated Since version 2.0. Will be deleted in 3.0.
 */
export const arrayToMapById = (arr, keyField) => arr.reduce((acc, cur) => {
  const featureId = cur[keyField];
  acc[featureId] = cur;
  return acc;
}, {});

/**
 * @deprecated Since version 2.0. Will be deleted in 3.0.
 */
export const getValue = (name, obj) => {
  if (typeof obj[name] === 'undefined') {
    return undefined;
  }
  return obj[name];
};

/**
 * @deprecated Since version 2.0. Will be deleted in 3.0.
 */
export const getValues = (obj) => {
  if (typeof obj === 'undefined') {
    return undefined;
  }
  return Object.values(obj);
};

/**
 * @deprecated Since version 2.0. Will be deleted in 3.0.
 * Use `name in obj` directly instead of this function
 */
export const isValidAttr = (name, obj) => name in obj;

/**
 * @deprecated Since version 2.0. Will be deleted in 3.0.
 * This can easily be replaced with `Object.keys(yourObject).length`
 */
export const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

export default {
  isEmptyObject,
  arrayToMapById,
  isValidAttr,
  getValue,
  extend,
  clone,
  stringify,
  isEmpty,
};
