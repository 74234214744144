/* eslint-disable max-len */
import { SESSION_STATE_KEY } from './keys';

const MUTATION_TYPES = require('./mutation-types');

const mutations = {};
/* eslint-disable no-param-reassign */
mutations[MUTATION_TYPES.POPULATE_SESSION] = (state, sessionObject) => {
  state[SESSION_STATE_KEY.CSRF] = sessionObject[SESSION_STATE_KEY.CSRF];
  state[SESSION_STATE_KEY.WSUK] = sessionObject[SESSION_STATE_KEY.WSUK];
  state[SESSION_STATE_KEY.GLOB] = sessionObject[SESSION_STATE_KEY.GLOB];
  state[SESSION_STATE_KEY.TTL] = sessionObject[SESSION_STATE_KEY.TTL];
  state[SESSION_STATE_KEY.USER_PID] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.USER_PID];
  state[SESSION_STATE_KEY.LOGIN_NAME] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.LOGIN_NAME];
  state[SESSION_STATE_KEY.USER_ID] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.USER_ID];
  state[SESSION_STATE_KEY.SERVER_CURRENT_TIME] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.SERVER_CURRENT_TIME];
  state[SESSION_STATE_KEY.TIME_DIFF] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.TIME_DIFF];
  state[SESSION_STATE_KEY.SERVER_TIME_ZONE] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.SERVER_TIME_ZONE];
  state[SESSION_STATE_KEY.SERVER_TIME_GMT_DIFF] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.SERVER_TIME_GMT_DIFF];
  state[SESSION_STATE_KEY.ACCOUNT_TIMEZONE_ID] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.ACCOUNT_TIMEZONE_ID];
  state[SESSION_STATE_KEY.LPA] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.LPA];
  state[SESSION_STATE_KEY.ADMIN] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.ADMIN];
  state[SESSION_STATE_KEY.SSO_LOGOUT_URL] = sessionObject[SESSION_STATE_KEY.CONFIG][SESSION_STATE_KEY.SSO_LOGOUT_URL];
  state[SESSION_STATE_KEY.BASE_URIS] = sessionObject[SESSION_STATE_KEY.CSDS_COLLECTION_RESPONSE][SESSION_STATE_KEY.BASE_URIS];
};

export default mutations;
