export const SESSION_STATE_KEY = {
  WSUK: 'wsuk',
  CSRF: 'csrf',
  TTL: 'sessionTTl',
  CONFIG: 'config',
  USER_ID: 'userId',
  LOGIN_NAME: 'loginName',
  SERVER_CURRENT_TIME: 'serverCurrentTime',
  SERVER_TIME_GMT_DIFF: 'serverTimeGMTDiff',
  SERVER_TIME_ZONE: 'serverTimeZoneName',
  DEFAULT_PERSONA: 'defaultPersona',
  PERSONA: 'persona',
  PERSONAS: 'personas',
  TIME_DIFF: 'timeDiff',
  BASE_URIS: 'baseURIs',
  CSDS_COLLECTION_RESPONSE: 'csdsCollectionResponse',
  USER_PRIVILEGES: 'userPrivileges',
  USER_PRIVILEGES_MAP: 'userPrivilegesMap',
  LPA: 'isLPA',
  ADMIN: 'isAdmin',
  ACCOUNT_TIMEZONE_ID: 'accountTimeZoneId',
  OTK: 'otk',
  USER_PID: 'userPid',
  FEATURE_ID: 'compoundFeatureID',
  FEATURES_MAP: 'featuresMap',
  GLOB: 'glob',
  ID: 'id',
  SETTINGS_MAP: 'settingMap',
  ITEMS: 'items',
  AGENT_GROUPS_DATA: 'agentGroupsData',
  AGENT_GROUPS_MAP: 'agentGroupsMap',
  SETTINGS: 'settings',
  FEATURES: 'features',
  PROVISION_DATA: 'provisionData',
  ACCOUNT_DATA: 'accountData',
  SETTINGS_DATA: 'settingsData',
  SSO_LOGOUT_URL: 'ssoUnifiedLoginRedirectLogoutUrl',
  ACCOUNT_ID: 'accountId',
  GUEST: 'guest',
};
export const other = 'other';
