import 'js2RestUtilsAsync';

import { extend } from '../../utils';
import CONST from '../Const';

const lpTag = window.lpTag || {};
const storage = lpTag.storageMethods;

/**
 * Determines if the log will be logged to Loggos
 * level 1 required for logging to Loggos
 * */

// map of levels for logger
const LoggerLevels = {
  metrics: 0,
  graph: 0,
  error: 1,
  warn: 2,
  info: 3,
  infoTam: 1,
  debug: 4,
};

const base = function base(protoProps, staticProps) {
  /* eslint-disable prefer-rest-params */
  /* eslint-disable no-underscore-dangle */
  const parent = this;
  let child;

  // The constructor function for the new subclass is either defined by you
  // (the 'constructor' property in your `extend` definition), or defaulted
  // by us to simply call the parent's constructor.
  if (protoProps && Object.prototype.hasOwnProperty.call(protoProps, 'constructor')) {
    child = protoProps.constructor;
  } else {
    child = function func() {
      return parent.apply(this, arguments);
    };
  }

  // Add static properties to the constructor function, if supplied.
  extend(parent, child);
  extend(staticProps, child);

  // Set the prototype chain to inherit from `parent`, without calling
  // `parent`'s constructor function.
  const Surrogate = function func() {
    this.constructor = child;
  };

  Surrogate.prototype = parent.prototype;
  child.prototype = new Surrogate();

  // Add prototype properties (instance properties) to the subclass,
  // if supplied.
  if (protoProps) {
    extend(protoProps, child.prototype);
  }

  // Set a convenience property in case the parent's prototype is needed
  // later.
  child.__super__ = parent.prototype;

  return child;
};

const AbstractAppender = function func(attributes) {
  this.attrs = attributes || {};
  // eslint-disable-next-line prefer-spread
  this.initialize.apply(this, arguments);
};

// Attach all inheritable methods to the AbstractAppender prototype.
extend({
  defaultStrategy: {
    enabled: true,
    stacktrace: true,
  },
  initialize(options) {
    this.updateStrategy(options.strategy);
  },
  constructStoreKey(key) {
    return `le${this.appenderType()}${key}`;
  },
  getStoredItem(key) {
    const stored = storage.getSessionData(this.constructStoreKey(key));
    return (stored && JSON.parse(stored)) || undefined;
  },
  storeItem(key, value) {
    let item;
    try {
      item = JSON.stringify(value);
      storage.setSessionData(this.constructStoreKey(key), item);
    } catch (ex) {
      // cannot use logger here as it will cause a circular dependency
      /* eslint-disable no-console */
      console.log(ex);
    }
  },
  getStoredStrategy() {
    return this.getStoredItem('Strategy');
  },
  storeStrategy() {
    if (this.currentStrategy) {
      this.storeItem('Strategy', this.currentStrategy);
    }
  },
  updateStrategy(strategy) {
    let _strategy = strategy || undefined;
    if (!_strategy) {
      _strategy = (this.getStoredStrategy() && typeof this.getStoredStrategy() !== 'string') ? this.getStoredStrategy() : this.defaultStrategy;
    }

    this.currentStrategy = extend(_strategy, this.currentStrategy, true);
    this.storeStrategy();
  },
  setStrategy(strategy) {
    let _strategy = strategy || undefined;
    if (!_strategy) {
      _strategy = this.getStoredStrategy() || this.defaultStrategy;
    }

    this.currentStrategy = _strategy;
    this.storeStrategy();
  },
  buildStackObject(level, deep, start) {
    /* eslint-disable no-param-reassign */
    /* eslint-disable no-useless-escape */
    deep = (deep
      || (this.currentStrategy.stacktrace && !Number.isNaN(this.currentStrategy.stacktrace)
      && this.currentStrategy.stacktrace)
      || (CONST.LOG_LEVEL.ERROR === level ? 3 : 2));

    start = start || 4;
    const err = new Error('dummy');

    if (err.stack) {
      let stack = err.stack
        .replace(/^[^\(]+?[\n$]/gm, '')
        .replace(/^\s+at\s+/gm, '')
        .replace(/^Object.<anonymous>\s*\(/gm, '{anonymous}()@')
        .split('\n');

      if (start < stack.length - 1) {
        stack.splice(0, start);

        if (deep < stack.length - 1) {
          stack = stack.slice(0, deep + 1);
        }
        return stack;
      }
    }
    return [];
  },
  parseDirObject(dirObj, options) {
    if (this.dirObj) {
      this.dirObj(dirObj);
    } else {
      const dirOptions = extend(options, {}, true);
      dirOptions.msg = JSON.stringify(dirObj);

      delete dirOptions.obj;

      this.logMsg(dirOptions);
    }
  },
  logStacktraceTop(options) {
    // is stack trace Enabled from the logger
    options = options || {};
    const trace = typeof options.trace === 'undefined' ? this.currentStrategy.stacktrace : options.trace;
    if (trace) {
      options.level = !Number.isNaN(options.level) ? options.level : CONST.LOG_LEVEL.ERROR;
      const stackObject = this.buildStackObject(options.level, options.deep);

      if (stackObject && stackObject.length > 0) {
        const logStack = {
          obj: stackObject,
          context: options.context,
          msg: `${options.level.toUpperCase()}: STACKTRACE`,
        };
        this.parseDirObject(logStack);
      }
    }
  },
  parseLogMessage(options) {
    options = options || {};

    if (this.isEnabled()) {
      // Add stacktrace
      this.logStacktraceTop({
        trace: options.trace,
        logLevel: options.logLevel,
        context: options.context,
      });
      this.logMsg(options);
    }
  },
  isLevelEnable(source, target) {
    const loggerLevels = (this.currentStrategy
      && this.currentStrategy.loggerLevels) || LoggerLevels;
    const lvl = (this.currentStrategy
      && this.currentStrategy.logLevel) || source;
    // source - is the level the logger was set
    return (loggerLevels[lvl] >= loggerLevels[target]);
  },
  getLevelUp() {
    const loggerLevels = (this.currentStrategy
      && this.currentStrategy.loggerLevels)
      || LoggerLevels;
    const level = (this.currentStrategy
      && this.currentStrategy.logLevel
      && loggerLevels[this.currentStrategy.logLevel])
      || loggerLevels.error;
    let levelUp = { key: this.currentStrategy.logLevel, value: level };

    // Find Next
    Object.keys(loggerLevels).forEach((lvl) => {
      if (Object.prototype.hasOwnProperty.call(loggerLevels, lvl)
        && loggerLevels[lvl] > level && (levelUp.value === level
          || levelUp.value > loggerLevels[lvl])) {
        levelUp = { key: lvl, value: loggerLevels[lvl] };
      }
    });
    return levelUp.key;
  },
  getLevelDown() {
    const loggerLevels = (this.currentStrategy
      && this.currentStrategy.loggerLevels) || LoggerLevels;
    const level = (this.currentStrategy
      && this.currentStrategy.logLevel
      && loggerLevels[this.currentStrategy.logLevel]) || loggerLevels.error;
    let levelDown = { key: this.currentStrategy.logLevel, value: level };

    // Find Next
    Object.keys(loggerLevels).forEach((lvl) => {
      if ((Object.prototype.hasOwnProperty.call(loggerLevels, lvl)
        && loggerLevels[lvl] < level) && (levelDown.value === level
        || levelDown.value < loggerLevels[lvl])) {
        levelDown = { key: lvl, value: loggerLevels[lvl] };
      }
    });

    return levelDown.key;
  },
  appenderType() {
    throw new Error('AbstractAppender::appenderType You must implement abstractMethod');
  },
  isEnabled() {
    throw new Error('AbstractAppender::isEnabled You must implement abstractMethod');
  },
  logMsg() {
    throw new Error('AbstractAppender::logMsg You must implement abstractMethod');
  },
}, AbstractAppender.prototype);

AbstractAppender.extend = base;

export default AbstractAppender;
