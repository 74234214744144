import { getLogger } from '../logger/logger';
import EventEmitter from '../lib/EventEmitter';
import { RECIPE_CLIENT_EVENTS, HTTP_RESPONSE_STATUS_CODES, RECIPE_RESPONSE_STATUSES } from './consts';
import {
  isValidRecipe, createRecipeRequestsBaseClients, createDependencyArr, hasResponseSuccessStatus,
} from './RecipeBaseClientUtils';

const logger = getLogger('Infra');

/**
 * Create a Recipe client in order to send multiple requests
 * with the ability to use data from the request's response for other requests,
 * extends EventEmitter.
 *
 * @param {Object} options - the recipe config object
 * @param {Array} options.requests - an array of all the recipe requests` configuration
 * @param {Object} options.requests[i].name - the request's name
 * @param {Object} options.requests[i].baseURL - the request's baseURL
 * @param {Object} options.requests[i].method - the request's crud method (default CRUDMethods.GET)
 * @param {Object} options.requests[i].url - the server URL that will be used for the request
 * for example: '/userId' (default undefined)
 * @param {Object} options.requests[i].config - the request's config (default undefined)
 * @param {Object} options.requests[i].config.params - the request's params.
 * @param {Object} options.requests[i].config.headers - the request's headers.
 * @param {Object} options.requests[i].config.timeout - timeout for the request.
 * param {Object} option.retryConfig - retry config, see details in the BaseClient (default {})
 * @param {Object} options.requests[i].body - the request's body (default undefined)
 * @param {String} options.requests[i].dependsOn - the name of the request that should send before
 * sending this request. The response of this request
 * will be the param of the preSendCallbackFunction
 * @param {Object} options.requests[i].preSendCallbackFunction - the function that should be
 * executed before sending the request.
 * This function will get as a param the response of the request
 * that is written in the dependsOn field.
 * The function should return a new object with the data
 * that will override the configuration that was passed before.
 * The returned object could contains the following fields: config, body, url.
 *
 */

export default class RecipeBaseClient extends EventEmitter {
  constructor(options) {
    super();
    this.recipeRequests = options.requests;

    if (!isValidRecipe(this.recipeRequests)) {
      logger.error('Recipe is not valid');
      throw new Error('not a valid recipe');
    }

    this.recipeRequestsBaseClients = createRecipeRequestsBaseClients(this.recipeRequests);
    this.dependencyArr = createDependencyArr(this.recipeRequests);
  }

  /**
   * Start the recipe polling and get events of the recipe response
   *
   * @param {number} options.interval - the polling recipe interval (default 10000)
   * @param options.rootRecipeUpdate - specifies fields to override in the root request
   */
  async startRecipePolling(options) {
    const interval = (options && options.interval) || 10000;
    const rootRecipeUpdate = (options && options.rootRecipeUpdate) || null;

    async function recipePolling(settings) {
      let res;
      try {
        res = await this.sendRecipe(settings);
      } catch (err) {
        // todo: Add another event for status failed
        res = err;
      }
      this.emit(RECIPE_CLIENT_EVENTS.onRecipeUpdateNotification, res);

      // verify previous timeout is clear.
      clearTimeout(this.pollingTimeoutRef);
      // We don't send the rootRecipeUpdate to the following polling calls,
      // since the recipe has already been updated.
      this.pollingTimeoutRef = setTimeout(recipePolling.bind(this), interval);
    }

    // sending the rootRecipeUpdate to the first time we send the recipe.
    await recipePolling.call(this, { rootRecipeUpdate });
  }

  /**
   * Stop the recipe polling and get events of the recipe response
   */
  stopRecipePolling() {
    clearTimeout(this.pollingTimeoutRef);
    this.emit(RECIPE_CLIENT_EVENTS.onRecipePollingStopped);
  }

  /**
   * Send the recipe requests
   * @param options.rootRecipeUpdate - specifies fields to override in the root request
   */
  async sendRecipe(options) {
    let recipeResponse;
    const responses = {};
    let failsCounts = 0;
    for (let i = 0; i < this.dependencyArr.length; i += 1) {
      const updatedCurrentLevelDependencyArray = this.updateCurrentLevelDependencyArray(i, responses, options);
      const promisesArr = updatedCurrentLevelDependencyArray.map(async (recipe) => {
        responses[recipe.name] = await this.sendReq(recipe);
      });

      // eslint-disable-next-line no-await-in-loop
      await Promise.all(promisesArr);
    }

    // calculate how many requests have failed
    const responsesArray = Object.values(responses);
    for (let i = 0; i < responsesArray.length; i += 1) {
      if (!hasResponseSuccessStatus(responsesArray[i])) {
        failsCounts += 1;
      }
    }

    let status;
    if (failsCounts > 0) {
      status = failsCounts !== this.recipeRequests.length
        ? RECIPE_RESPONSE_STATUSES.PARTIAL_SUCCESS : RECIPE_RESPONSE_STATUSES.FAILED;
      recipeResponse = { status, responses };
    } else {
      status = RECIPE_RESPONSE_STATUSES.SUCCESS;
      recipeResponse = { status, responses };
    }

    return recipeResponse;
  }

  /**
   * Update the dependencyArray of a specific level in the following way:
   * 1. Run the preSendCallbackFunction if needed
   * 2. Update the response of requests that their depended request has failed
   * and remove them from the CurrentLevelDependencyArray in order to prevent sending these requests
   *
   * @param levelIndex - the level index in the dependencyArr
   * @param responses- the recipe responses object
   * @param options.rootRecipeUpdate - overrides for levelIndex 0 requests
   * @returns {array} updatedCurrentLevelDependencyArray -
   * an updated dependency array for the current level
   */
  updateCurrentLevelDependencyArray(levelIndex, responses, options) {
    const updatedCurrentLevelDependencyArray = this.dependencyArr[levelIndex].slice();
    let removedItemsCount = 0;
    this.dependencyArr[levelIndex].forEach((req, index) => {

      // dependsOn is the name of the request that should send before sending this request.
      // The response of this request will be the param of the preSendCallbackFunction
      // that will be execute before sending this request
      // Alternatively, if this is a level 0 request and we received a rootRecipeUpdate
      // we can use that when preparing the request
      const canUpdateRootRecipe = !!options && !!options.rootRecipeUpdate && levelIndex === 0;
      if ((req.preSendCallbackFunction && req.dependsOn) || canUpdateRootRecipe) {
        let dependencyResponse;
        let rootRecipeUpdate;
        if (req.preSendCallbackFunction && req.dependsOn) {
          dependencyResponse = { ...responses[req.dependsOn] };
        } else if (canUpdateRootRecipe) {
          rootRecipeUpdate = options.rootRecipeUpdate;
        }

        // When the depended request is failed we don't want to execute the preSendCallbackFunction
        // and we don't want to send the depended requests
        if (rootRecipeUpdate || hasResponseSuccessStatus(dependencyResponse)) {
          // updating the req with the new configuration
          // we are getting from the preSendCallbackFunction or from the rootRecipeUpdate
          const updatedReqConfiguration = rootRecipeUpdate
            || req.preSendCallbackFunction(dependencyResponse);
          if (updatedReqConfiguration.body) {
            updatedCurrentLevelDependencyArray[index].body = updatedReqConfiguration.body;
          }
          if (updatedReqConfiguration.config) {
            updatedCurrentLevelDependencyArray[index].config = updatedReqConfiguration.config;
          }
          if (updatedReqConfiguration.url) {
            updatedCurrentLevelDependencyArray[index].url = updatedReqConfiguration.url;
          }
        } else {
          updatedCurrentLevelDependencyArray.splice(index - removedItemsCount, 1);
          removedItemsCount += 1;
          /* eslint-disable-next-line no-param-reassign */
          responses[req.name] = {
            data: 'Missing dependency',
            status: HTTP_RESPONSE_STATUS_CODES.FAILED_DEPENDENCY,
          };
        }
      }
    });
    return updatedCurrentLevelDependencyArray;
  }

  /**
   * Send a single request using the BaseClient
   */
  async sendReq(recipeObj) {
    const recipe = {
      url: undefined,
      body: undefined,
      config: undefined,
      ...recipeObj,
    };
    let res;
    try {
      const client = this.recipeRequestsBaseClients[recipe.name];
      res = await client[recipe.method](recipe.url, recipe.body || recipe.config, recipe.config);
    } catch (e) {
      const errorResponse = (e && e.response) || {};
      logger.error(`sendReq: ${errorResponse}`);
      res = Promise.resolve(errorResponse);
    }
    return res;
  }
}
