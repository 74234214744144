/* eslint-disable prefer-destructuring */
/**
 * A tiny logging mechanism to log messages to the console.log (see /read-me/le.api.logger.txt).
 * to use, call Logger.getLogger("myContext");
 * and you will get a logger for myContext with 5 methods:
 * debug - logger.debug("log this message");
 * info - logger.info("log this message");
 * infoTam - logger.infoTam("log this message");
 * warn - logger.warn("log this warning");
 * error - logger.error("log this error");
 * dir - logger.dir(myObj, "log a message about myObj");
 * Only error & infoTam will be showed in the Kibana (You can change this by changing the log level)
 */
import 'js2RestUtilsAsync';
import CONST from './Const';
import { extend } from '../utils';
import sessionManager from '../auth/session/sessionManager';

/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
const storage = lpTag.storageMethods;

// appenders logger
const appenders = {};
const ver = '1.0.0';

// map of loggers created by context
const contextualLoggers = {};
let additionalInfo = {};
let enabled = true;
let loggerLevel = CONST.LOG_LEVEL.ERROR;
let loggerContexts = {};
let loggerContextsBlackList = {};
let trace = false;

const Logger = (function func() {
  function addInitLoggerContexts(contexts) {
    let contextObjs;

    if (typeof contexts === 'string') {
      try {
        contextObjs = JSON.parse(contexts);
      } catch (e) {
        const ctxs = contexts.split(',');
        contextObjs = {};

        for (let i = 0; i < ctxs.length; i += 1) {
          contextObjs[ctxs[i]] = ctxs[i];
        }
      }
    } else if (typeof contexts === 'object') {
      contextObjs = contexts;
    }

    if (contextObjs) {
      return contextObjs;
    }
    return {};
  }

  function pad(number, width, padding) {
    /* eslint-disable no-param-reassign */
    number = `${number}`;
    padding = padding || '0';
    return number.length >= width ? number
      : new Array((width - number.length) + 1).join(padding) + number;
  }

  function isContextEnable(context) {
    let isEnable = false;

    if (Object.prototype.hasOwnProperty.call(loggerContexts, '*') && !Object.prototype.hasOwnProperty.call(loggerContextsBlackList, context)) {
      isEnable = true;
    } else if (typeof context !== 'undefined') {
      // the following is when having LE Application:SubModule
      const contextArray = context.split(':');
      // the following is when having LE Application:SubModule
      if (contextArray.length === 2) {
        if (Object.prototype.hasOwnProperty.call(loggerContexts, contextArray[0])
          || Object.prototype.hasOwnProperty.call(loggerContexts, contextArray[1])) {
          isEnable = true;
        }
      } else if (Object.prototype.hasOwnProperty.call(loggerContexts, context)) {
        isEnable = true;
      }
    }
    return isEnable;
  }

  /**
   * the method checks if the level of the message we are trying to log is enable
   * @param level - level of the message we are trying to log
   * @param appender - the appender trying to log to
   * @returns {boolean} - true if enable, false otherwise
   */
  function isLevelEnable(level, appender) {
    // loggerLevel - is the level the logger was set
    return appender.isLevelEnable(loggerLevel, level);
  }

  function isLogEnable(context, logLvl, appender) {
    return (enabled && isContextEnable(context) && isLevelEnable(logLvl, appender));
  }

  // utility to format the log date-time
  function getLogDateTime() {
    const now = new Date();
    const dateSeparator = '-';
    const timeSeparator = ':';
    const day = pad(now.getDate(), 2);
    const month = pad(now.getMonth() + 1, 2);
    const year = now.getFullYear();
    const hour = pad(now.getHours(), 2);
    const min = pad(now.getMinutes(), 2);
    const sec = pad(now.getSeconds(), 2);
    const ms = pad(now.getMilliseconds(), 3);
    return `${year}${dateSeparator}${month}${dateSeparator}${day} ${hour}${timeSeparator}${min}${timeSeparator}${sec},${ms}`;
  }

  function logMsgAppenders(options) {
    Object.keys(appenders).forEach((appender) => {
      if (Object.prototype.hasOwnProperty.call(appenders, appender)
        && appenders[appender].isEnabled()) {
        if (isLogEnable(options.context, options.logLevel, appenders[appender])) {
          // if in the parseDirObject is passed, this means
          // we want to print dir style, check if in the appender exist API(dir)
          if (typeof options.parseDirObject === 'object') {
            appenders[appender].parseDirObject(options);
          } else if (typeof (appenders[appender].logMsg) === 'function') {
            appenders[appender].logMsg(options);
          }
        }
      }
    });
  }

  function isStacktraceEnabled(level) {
    return (level && (CONST.LOG_LEVEL.DEBUG === level || CONST.LOG_LEVEL.ERROR === level) && trace);
  }

  function logMsg(options) {
    const info = {
      time: getLogDateTime(),
      clientTimeUTC: Date.now(),
      trace: isStacktraceEnabled(options.level),
      immediate: (options && options.immediate === true) || false,
    };

    if (options.level) {
      info.logLevel = options.level;
    }
    if (options.context) {
      info.context = options.context;
    }
    if (options.msg) {
      info.msg = options.msg;
    }
    if (options.namespace) {
      info.namespace = options.namespace;
    }
    if (options.name) {
      info.name = options.name.replace(/\W+/g, '_');
    }
    if (options.obj) {
      info.obj = options.obj;
    }
    if (options.parseDirObject) {
      info.parseDirObject = options.parseDirObject;
    }
    if (options.appName) {
      info.appName = options.appName;
    }

    const line = extend(additionalInfo, info, true);

    logMsgAppenders(line);
  }

  const LoggerFactory = {
    debug(msg, namespace, obj) {
      logMsg({
        level: CONST.LOG_LEVEL.DEBUG,
        context: this._innerContext,
        appName: this.appName,
        msg,
        namespace,
        obj,
      });
    },
    info(msg, namespace, obj) {
      logMsg({
        level: CONST.LOG_LEVEL.INFO,
        context: this._innerContext,
        appName: this.appName,
        msg,
        namespace,
        obj,
      });
    },
    infoTam(msg, namespace, obj) {
      logMsg({
        level: CONST.LOG_LEVEL.INFOTAM,
        context: this._innerContext,
        appName: this.appName,
        msg,
        namespace,
        obj,
      });
    },
    warn(msg, namespace, obj) {
      logMsg({
        level: CONST.LOG_LEVEL.WARN,
        context: this._innerContext,
        appName: this.appName,
        msg,
        namespace,
        obj,
      });
    },
    error(msg, namespace, obj) {
      const finalMessage = {
        level: CONST.LOG_LEVEL.ERROR,
        context: this._innerContext,
        appName: this.appName,
        msg,
        namespace,
        obj,
      };
      if (obj && obj.immediate === true) {
        finalMessage.immediate = true;
      }
      logMsg(finalMessage);
    },
    metrics(obj, msg) {
      logMsg({
        level: CONST.LOG_LEVEL.METRICS,
        context: this._innerContext,
        appName: this.appName,
        msg,
        obj,
      });
    },
    graph(msg, namespace, obj) {
      const item = extend({
        level: CONST.LOG_LEVEL.GRAPH,
        context: this._innerContext,
        appName: this.appName,
        namespace,
        obj,
      }, msg || {}, true);
      logMsg(item);
    },
    bam(obj) {
      const isBamEnabled = sessionManager.getFeaturePropertyState('LEUI.BAM_Enabled');
      if (isBamEnabled) {
        logMsg({
          level: CONST.LOG_LEVEL.BAM,
          context: this._innerContext,
          appName: this.appName,
          obj,
        });
      }
    },
    // eslint-disable-next-line consistent-return
    track(name, data) {
      const isPendoEnabled = sessionManager.getFeaturePropertyState('LEUI.isPendoEnabled');
      if (isPendoEnabled) {
        if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
          return window.pendo.track(name, {
            userId: sessionManager.getUserId(),
            accountId: sessionManager.getAccountId(),
            ...data,
          });
        }
        setTimeout(() => {
          this.track(name, data);
        }, 500);
      }
    },
    dir(obj, msg) {
      logMsg({
        level: CONST.LOG_LEVEL.DEBUG,
        context: this._innerContext,
        appName: this.appName,
        msg,
        obj,
        parseDirObject: true,
      });
    },
  };

  function getInitialValue(name, defaultValue, isObject) {
    let variable = storage.getPersistentData(name);
    if ((typeof variable !== 'boolean' && (typeof variable !== 'string')) || variable.length === 0) {
      variable = defaultValue;
    } else if (isObject) {
      try {
        variable = JSON.parse(variable);
      } catch (e) {
        variable = {};
      }
    }

    return variable;
  }

  function setLoggerState(enable) {
    if (typeof enable === 'boolean') {
      enabled = enable;
      storage.setPersistentData('LELogger', enable);
    }
  }

  function setLoggerLevel(loggerLvl) {
    if (loggerLvl && CONST.LOG_LEVEL[loggerLvl.toUpperCase()]) {
      loggerLevel = loggerLvl;
      storage.setPersistentData('LELoggerLevel', loggerLvl);
    }
  }

  function setLoggerTrace(trc) {
    trace = !!trc;
    storage.setPersistentData('LELoggerTrace', trace);
  }

  function initialize() {
    enabled = getInitialValue('LELogger', true, true);
    loggerLevel = getInitialValue('LELoggerLevel', CONST.LOG_LEVEL.ERROR, false);
    trace = getInitialValue('LELoggerTrace', false, true);
    loggerContexts = addInitLoggerContexts(getInitialValue('LELoggerContext', { '*': '*' }, true));
    loggerContextsBlackList = addInitLoggerContexts(getInitialValue('LELoggerContextBlackList', {}, true));
  }

  function setInitialValues(options) {
    options = options || {};

    setLoggerState(options.enable);
    setLoggerLevel(options.level);
    setLoggerTrace(options.trace);

    addInitLoggerContexts(options.context);
  }

  // logs msg to the using the appender objects
  function addLoggerContext(context) {
    const savedContextStr = storage.getPersistentData('LELoggerContext');
    let contextObj = ((savedContextStr) ? JSON.parse(savedContextStr) : {}) || {};

    if (typeof context !== 'undefined' && !Object.prototype.hasOwnProperty.call(loggerContexts, context)) {
      if (context === '*') {
        // if we want to set "*" - all context, first clear the
        // existing object and then add "*", very simple logic
        contextObj = {};
        loggerContexts = {};
      } else if (loggerContexts['*'] || contextObj['*']) {
        // if we have context to set && we don't have already added, remove the "*" all contexts
        delete loggerContexts['*'];
        delete contextObj['*'];
      }

      loggerContexts[context] = context; // add it to the loggerContext object

      // if we have saved data, add it to the object
      contextObj[context] = context;

      storage.setPersistentData('LELoggerContext', JSON.stringify(contextObj));
    }
  }

  /**
   * the method will remove the given context of the list of the available contexts
   * we are adding the given context to the 'black list' meaning we won't log
   * @param context: string
   */
  function removeLoggerContext(context) {
    // if the context that we get is valid
    if (typeof context !== 'undefined') {
      // if the loggerContexts is '*' meaning we need to log all,
      // we can add the given context to the
      // 'black list' - don't log this context +_ save it in thr localStorage(for next time)
      if (Object.prototype.hasOwnProperty.call(loggerContexts, '*')) {
        loggerContextsBlackList[context] = context;
        storage.setPersistentData('LELoggerContextBlackList', JSON.stringify(loggerContextsBlackList));
      } else if (Object.prototype.hasOwnProperty.call(loggerContexts, context)) {
        // if we its not '*', meaning we can remove it from the loggerContexts
        delete loggerContexts[context];
        storage.setPersistentData('LELoggerContext', JSON.stringify(loggerContexts));
      }
    }
  }

  function getAppenders() {
    return appenders;
  }

  function setAdditionalInfo(info) {
    additionalInfo = extend(info, additionalInfo, true);
  }

  function addAppender(options) {
    options = options || {};
    appenders[options.name] = options.appender;
  }

  initialize();

  return {
    // Logger exposes this function to get a logger for the specific context
    // the context argument is mandatory
    addAppender,
    setAdditionalInfo,
    getLogger(context, appName) {

      // if context is false or not a string, log an error and return null
      if (!context || typeof (context) !== 'string') {
        logMsg({
          level: CONST.LOG_LEVEL.ERROR,
          context: 'Logger',
          appName: 'vua-infra',
          msg: `context string expected, got: ${typeof context}`,
        });
        return null;
      }
      // if the context doesn't already have a logger, create one and store it in the hash
      if (!contextualLoggers[context]) {

        contextualLoggers[context] = {
          _innerContext: context,
          appName,
          getLogger(internalContext) {
            // only if the context(main context) is a
            // module that exist in the application allow nested logger
            const subContext = `${context}:${internalContext}`;
            contextualLoggers[subContext] = {
              _innerContext: subContext,
              appName,
            };
            extend(LoggerFactory, contextualLoggers[subContext]);
            return contextualLoggers[subContext];
          },
          version() {
            return logMsg({
              level: CONST.LOG_LEVEL.INFO,
              context: 'Logger',
              appName: 'vua-infra',
              msg: `logger.js version ${ver}`,
            });
          },
          setLoggerState,
          setLoggerLevel,
          setLoggerTrace,
          setInitialValues,
          addLoggerContext,
          removeLoggerContext,
          getLoggerState() {
            return enabled;
          },
          getLoggerLevel() {
            return loggerLevel;
          },
          getLoggerTrace() {
            return trace;
          },
          getLoggerContext() {
            return loggerContexts;
          },
          clearLoggerContext() {
            loggerContexts = {};
            storage.setPersistentData('LELoggerContext', JSON.stringify(loggerContexts));
          },
          setAdditionalInfo,
          addAppender,
          getAppenders,
        };
      } else {
        contextualLoggers[context].appName = appName || contextualLoggers[context].appName;
        contextualLoggers[context].getLogger = (internalContext) => {
          // only if the context(main context) is a
          // module that exist in the application allow nested logger
          const subContext = `${context}:${internalContext}`;
          contextualLoggers[subContext] = {
            _innerContext: subContext,
            appName: appName || contextualLoggers[context].appName,
          };
          extend(LoggerFactory, contextualLoggers[subContext]);
          return contextualLoggers[subContext];
        };
      }
      extend(LoggerFactory, contextualLoggers[context]);
      return contextualLoggers[context];
    },
  };
}());

export const getLogger = Logger.getLogger;
export const setAdditionalInfo = Logger.setAdditionalInfo;
export const addAppender = Logger.addAppender;

export default Logger;
