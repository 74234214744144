/* eslint-disable no-param-reassign */
import getDefaultState from './defaults';
import { arrayToMapById } from '../../../utils';
import {
  ADD_PERSONA_TO_ARRAY,
  ADD_PERSONA_TO_ARRAY_BY_POSITION,
  CLEAR_PERSONAS,
  CONCAT_PERSONAS_TO_ARRAY,
  RESET_STATE,
  SESSION_INIT,
  SET_AGENT_GROUPS_DATA,
  SET_DEFAULT_VALUE,
  SET_FEATURES,
  SET_USER_PRIVILEGES,
  SET_VALUE,
  SETTINGS_DATA,
} from './action-types';

import {
  INFRA_ADD_TO_ARRAY_BY_POSITION,
  INFRA_CLEAR_ARRAY,
  INFRA_CONCAT_ARRAY,
  INFRA_PUSH_TO_ARRAY,
  INFRA_RESET_STATE,
  INFRA_SET_ATTRIBUTE,
} from '../../infra/mutation-types';
import { POPULATE_SESSION } from './mutation-types';
import { SESSION_STATE_KEY } from './keys';
import { getLogger } from '../../../logger/logger';

const defaultState = getDefaultState();

const logger = getLogger('vue-infra');
const actions = {
  [RESET_STATE](store) {
    store.commit(INFRA_RESET_STATE, getDefaultState());
  },
  [SET_VALUE]({ commit }, payload) {
    commit(INFRA_SET_ATTRIBUTE, {
      attribute: payload.attribute,
      value: payload.value,
    });
  },
  [ADD_PERSONA_TO_ARRAY]({ commit }, value) {
    commit(INFRA_PUSH_TO_ARRAY, {
      attribute: SESSION_STATE_KEY.PERSONAS,
      value,
    });
  },
  [CONCAT_PERSONAS_TO_ARRAY]({ commit }, value) {
    commit(INFRA_CONCAT_ARRAY, {
      attribute: SESSION_STATE_KEY.PERSONAS,
      value,
    });
  },
  [ADD_PERSONA_TO_ARRAY_BY_POSITION]({ commit }, payload) {
    commit(INFRA_ADD_TO_ARRAY_BY_POSITION, {
      attribute: SESSION_STATE_KEY.PERSONAS,
      pos: payload.pos,
      value: payload.value,
    });
  },
  [CLEAR_PERSONAS]({ commit }) {
    commit(INFRA_CLEAR_ARRAY, {
      attribute: SESSION_STATE_KEY.PERSONAS,
    });
  },
  [SET_DEFAULT_VALUE]({ commit }, key) {
    if (!defaultState || !(Object.keys(defaultState).indexOf(key) > -1)) {
      logger.error(`${defaultState}: key not exist in defaults map.`);
    } else {
      commit(INFRA_SET_ATTRIBUTE, {
        attribute: key,
        value: defaultState[key],
      });
    }
  },
  [SESSION_INIT]({ commit }, sessionObject) {
    commit(POPULATE_SESSION, sessionObject);
  },
  [SET_USER_PRIVILEGES]({ commit }, privileges) {
    const userPrivilegesMap = {};
    const customPrivileges = privileges.custom;
    const defaultPrivileges = privileges.default;
    const userPrivilegesList = customPrivileges || [];
    // Add the configured default privileges if any
    // We are using a mechanism of default privileges
    // Which can be pre-configured and added to the
    // list of privileges returned from the session creation
    // This is used for the web-agent beta for example
    // We add the web-agent privilege as a pre-configured
    // Default privilege to all loggged in users just to
    // Support old sites that were not created with the
    // Needed privileges
    if (Array.isArray(defaultPrivileges)) {
      defaultPrivileges.forEach((privilege) => {
        if (userPrivilegesList.indexOf(privilege) === -1) {
          userPrivilegesList.push(privilege);
        }
      });
    }
    commit(INFRA_SET_ATTRIBUTE, {
      attribute: SESSION_STATE_KEY.USER_PRIVILEGES,
      value: userPrivilegesList,
    });
    userPrivilegesList.forEach((userPrivilege) => {
      userPrivilegesMap[userPrivilege] = userPrivilege;
    });
    commit(INFRA_SET_ATTRIBUTE, {
      attribute: SESSION_STATE_KEY.USER_PRIVILEGES_MAP,
      value: userPrivilegesMap,
    });
  },
  [SET_FEATURES]({ commit }, featuresList) {
    const featuresMap = arrayToMapById(featuresList, SESSION_STATE_KEY.FEATURE_ID);
    commit(INFRA_SET_ATTRIBUTE, {
      attribute: SESSION_STATE_KEY.FEATURES_MAP,
      value: featuresMap,
    });
  },
  [SETTINGS_DATA]({ commit }, settingsData) {
    const settingsMap = arrayToMapById(settingsData, SESSION_STATE_KEY.ID);
    commit(INFRA_SET_ATTRIBUTE, {
      attribute: SESSION_STATE_KEY.SETTINGS_MAP,
      value: settingsMap,
    });
  },
  [SET_AGENT_GROUPS_DATA]({ commit }, agentGroupData) {
    const agentGroupMap = arrayToMapById(agentGroupData, SESSION_STATE_KEY.ID);
    commit(INFRA_SET_ATTRIBUTE, {
      attribute: SESSION_STATE_KEY.AGENT_GROUPS_MAP,
      value: agentGroupMap,
    });
  },
};

export default actions;
