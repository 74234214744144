/* eslint-disable prefer-arrow-callback */
import { getClient, captureException, withScope } from '@sentry/vue';
import AbstractAppender from './AbstractAppender';
import {
  SENTRY_LOG_LEVELS,
  SENTRY_APPENDER,
  LP_LOGGER_LEVELS_LOG,
  LP_LOGGER_LEVELS_ERROR,
  SENTRY_OPTION_PROPERTY_NOT_APPLICABLE,
} from '../sentryConsts';

const SentryAppender = AbstractAppender.extend({
  appenderType() {
    return SENTRY_APPENDER;
  },
  initialize(options) {
    /* eslint-disable no-param-reassign */
    options = options || {};
    AbstractAppender.prototype.initialize.call(this, options);
  },

  isEnabled() {
    if (getClient() !== null) {
      this.currentStrategy.enabled = true;
      return true;
    }
    this.currentStrategy.enabled = false;
    return false;
  },

  logMsg(options) {
    if (options.logLevel.trim().toLowerCase() !== LP_LOGGER_LEVELS_ERROR) {
      return;
    }

    if (!(options.logLevel in SENTRY_LOG_LEVELS)) {
      options.logLevel = LP_LOGGER_LEVELS_LOG;
    }

    if (options.appName || options.context) {
      const logMessage = `[${options.appName}] ${options.msg}`;
      const app = options.appName || options.context;
      withScope((scope) => {
        scope.setTag('appName', app);
        scope.setTag('loggingContext', options.context);
        scope.setTag('loggingNamespace', options.namespace || SENTRY_OPTION_PROPERTY_NOT_APPLICABLE);
        scope.setLevel(options.logLevel);
        captureException(new Error(logMessage));
      });
    }
  },
});
export default SentryAppender;
