import { isEmpty } from '../../utils';
import AbstractAppender from './AbstractAppender';
/* eslint-disable no-console */

/**
 * Determines if the log will be logged to the console
 * level 1 required for logging to show in console
 */
const ConsoleAppender = AbstractAppender.extend({
  defaultStrategy: {
    enabled: true,
    loggerLevels: { // Override logger levels to put metrics and graph in the same level as debug
      error: 1,
      warn: 2,
      info: 3,
      infoTam: 3,
      debug: 4,
      metrics: 4,
      graph: 4,
    },
  },
  appenderType() {
    return 'ConsoleAppender';
  },
  initialize(options) {
    /* eslint-disable no-param-reassign */
    options = options || {};
    AbstractAppender.prototype.initialize.call(this, options);
  },
  isEnabled() {
    // check if the console.log exist in the browser
    return (this.currentStrategy.enabled && typeof window !== 'undefined' && 'console' in window && 'log' in console);
  },
  // prints representation of obj to the console with the msg and context as a title
  dirObj(options) {
    options = options || {};
    options.msg = typeof (options.msg) !== 'undefined' ? options.msg : typeof (options.obj);
    const logTitle = `DIR: ${options.msg};  CONTEXT: ${options.context}; TIME: ${options.time}`;
    if ('group' in console && 'groupEnd' in console) {
      console.group(logTitle);
      console.groupEnd();
    } else {
      console.log(logTitle);
    }
  },
  logMsg(options) {
    options = options || {};
    const logFn = (options.logLevel in console) ? options.logLevel : 'log'; // default to console.log if the logLevel is not available

    // if namespace passed build it like: [namespace], otherwise set empty string
    if (typeof options.namespace !== 'undefined' && options.namespace !== null && !isEmpty(options.namespace)) {
      options.namespace = `[${options.namespace}]`;
    } else {
      options.namespace = '';
    }
    // if object passed in the method call to
    // dirObj and don't log twice that is the reason we 'return'
    if (typeof options.obj !== 'undefined' && options.obj !== null) { // obj parameter is defined
      const logObj = {
        obj: options.obj,
        time: options.time,
        context: options.context,
        msg: `${options.namespace}obj:${options.msg}`,
      };
      this.parseDirObject(logObj);
      return;
    }

    // for all the cases
    console[logFn]('%s %s [%s] %s %s', options.time, options.logLevel.toUpperCase(), options.context, options.namespace, options.msg);
  },
});
export default ConsoleAppender;
