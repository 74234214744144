import {
  CURRENT_PERSONA_UPDATE,
  MODULES_UPDATE,
  OUTER_ROUTE_UPDATE,
  PERSONA_NOTIFICATION_UPDATE,
  PERSONALIZATION_UPDATE,
  PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT_UPDATE,
  PERSONALIZATION_WIDGET_IS_OPEN_TOGGLE,
  PERSONALIZATION_WIDGET_IS_OPEN_UPDATE,
  PERSONALIZATION_WIDGET_IS_REPORTING_OPEN_UPDATE,
  PERSONALIZATION_WIDGET_PAGE_UPDATE,
  RESET_STATE,
  ROUTES_UPDATE,
  SET_BRAND_PROFILE,
  SET_CURRENT_AGENT_STATUS,
  REQUEST_AGENT_STATUS_CHANGE,
  UPDATE_AGENT_STATUS_DATA_STATE,
  TOP_BAR_NOTIFICATION_REMOVE,
  TOP_BAR_NOTIFICATION_UPDATE,
  UPDATE_CUSTOM_AWAY_REASONS,
  UPDATE_QUICK_LAUNCH_COLLECTION,
  UPDATE_REGIONS,
  VALID_APPS_UPDATE,
  LOGGED_IN_USER_DETAILS_UPDATE,
  UPDATE_AGENT_TIMER,
  SET_MESSAGING_AGENT_STATUS,
  SET_CHAT_AGENT_STATUS,
} from './actionTypes';
import {
  ADD_ROUTES,
  ADD_VALID_APPLICATION,
  CURRENT_PERSONA,
  OUTER_ROUTE,
  PERSONA_NOTIFICATION,
  PERSONALIZATION,
  PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT,
  PERSONALIZATION_WIDGET_IS_OPEN,
  PERSONALIZATION_WIDGET_IS_REPORTING_OPEN,
  PERSONALIZATION_WIDGET_PAGE,
  SET_MODULES,
  SET_REGIONS,
  TOP_BAR_NOTIFICATION,
  SET_LOGGED_IN_USER_DETAILS,
  SET_AGENT_TIMER,
} from './mutationTypes';
import { INFRA_RESET_STATE, INFRA_SET_ATTRIBUTE, SET_VALUE } from '../../infra/mutation-types';
import { ENVIRONMENT_STATE_KEY } from './keys';
import getDefaultState from './defaults';
import BaseClient from '../../../api-layer/BaseClient';

const actions = {};
actions[SET_VALUE] = ({ commit }, payload) => {
  commit(INFRA_SET_ATTRIBUTE, {
    attribute: payload.attribute,
    value: payload.value,
  });
};

actions[ROUTES_UPDATE] = ({ commit }, value) => {
  commit(ADD_ROUTES, value);
};

actions[LOGGED_IN_USER_DETAILS_UPDATE] = ({ commit }, value) => {
  commit(SET_LOGGED_IN_USER_DETAILS, value);
};

actions[UPDATE_AGENT_TIMER] = ({ commit }, value) => {
  commit(SET_AGENT_TIMER, value);
};

actions[VALID_APPS_UPDATE] = ({ commit }, value) => {
  commit(ADD_VALID_APPLICATION, value);
};

actions[OUTER_ROUTE_UPDATE] = ({ commit }, value) => {
  commit(OUTER_ROUTE, value);
};

actions[PERSONALIZATION_UPDATE] = ({ commit }, payload) => {
  commit(PERSONALIZATION, payload);
};

actions[TOP_BAR_NOTIFICATION_UPDATE] = ({ commit }, payload) => {
  commit(TOP_BAR_NOTIFICATION, payload);
};

actions[TOP_BAR_NOTIFICATION_REMOVE] = ({ commit }) => {
  commit(TOP_BAR_NOTIFICATION, undefined);
};

actions[PERSONALIZATION_WIDGET_IS_OPEN_TOGGLE] = (store) => {
  store.commit(
    PERSONALIZATION_WIDGET_IS_OPEN,
    !store.state[PERSONALIZATION][ENVIRONMENT_STATE_KEY.IS_OPEN],
  );
};

actions[PERSONALIZATION_WIDGET_IS_OPEN_UPDATE] = (store, value) => {
  store.commit(PERSONALIZATION_WIDGET_IS_OPEN, value);
};

actions[PERSONALIZATION_WIDGET_PAGE_UPDATE] = (store, value) => {
  store.commit(PERSONALIZATION_WIDGET_PAGE, value);
};

actions[PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT_UPDATE] = (store, value) => {
  store.commit(PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT, value);
};

actions[PERSONALIZATION_WIDGET_IS_REPORTING_OPEN_UPDATE] = (store, value) => {
  store.commit(PERSONALIZATION_WIDGET_IS_REPORTING_OPEN, value);
};

actions[CURRENT_PERSONA_UPDATE] = ({ commit }, value) => {
  commit(CURRENT_PERSONA, value);
};

actions[RESET_STATE] = (store) => {
  store.commit(INFRA_RESET_STATE, getDefaultState());
};

actions[MODULES_UPDATE] = (store, modules) => {
  store.commit(SET_MODULES, modules);
};

actions[UPDATE_REGIONS] = (store, obj) => {
  store.commit(SET_REGIONS, obj);
};

actions[SET_MESSAGING_AGENT_STATUS] = ({ commit }, payload) => {
  commit(INFRA_SET_ATTRIBUTE, {
    attribute: ENVIRONMENT_STATE_KEY.CURRENT_MESSAGING_AGENT_STATUS,
    value: payload,
  });
};

actions[SET_CHAT_AGENT_STATUS] = ({ commit }, payload) => {
  commit(INFRA_SET_ATTRIBUTE, {
    attribute: ENVIRONMENT_STATE_KEY.CURRENT_CHAT_AGENT_STATUS,
    value: payload,
  });
};
actions[SET_CURRENT_AGENT_STATUS] = ({ commit }, payload) => {
  commit(INFRA_SET_ATTRIBUTE, {
    attribute: ENVIRONMENT_STATE_KEY.CURRENT_AGENT_STATUS,
    value: payload,
  });
};
actions[REQUEST_AGENT_STATUS_CHANGE] = ({ commit }, payload) => {
  commit(INFRA_SET_ATTRIBUTE, {
    attribute: ENVIRONMENT_STATE_KEY.REQUEST_AGENT_STATUS_CHANGE,
    value: payload,
  });
};

actions[UPDATE_AGENT_STATUS_DATA_STATE] = ({ commit }, payload) => {
  commit(INFRA_SET_ATTRIBUTE, {
    attribute: ENVIRONMENT_STATE_KEY.AGENT_STATUS_DATA_STATE,
    value: payload,
  });
};

actions[SET_BRAND_PROFILE] = ({ commit }, payload) => {
  commit(INFRA_SET_ATTRIBUTE, {
    attribute: ENVIRONMENT_STATE_KEY.BRAND_PROFILE,
    value: payload,
  });
};

actions[UPDATE_CUSTOM_AWAY_REASONS] = ({ commit }, request) => {
  const client = new BaseClient({ config: { baseURL: request.url } });
  client.get('', request.data === undefined ? {} : { params: request.data }).then((response) => {
    commit(INFRA_SET_ATTRIBUTE, {
      attribute: ENVIRONMENT_STATE_KEY.CUSTOM_AWAY_REASONS,
      value: response.data,
    });
  });
};

actions[UPDATE_QUICK_LAUNCH_COLLECTION] = ({ commit }, request) => {
  const client = new BaseClient({ config: { baseURL: request.url } });
  client.get('', request.data === undefined ? {} : { params: request.data }).then((response) => {
    commit(INFRA_SET_ATTRIBUTE, {
      attribute: ENVIRONMENT_STATE_KEY.QUICK_LAUNCH_APPS,
      value: response.data,
    });
  });
};

actions[PERSONA_NOTIFICATION_UPDATE] = ({ commit }, data) => {
  commit(PERSONA_NOTIFICATION, data);
};

export default actions;
