import lpCookie from './lpCookie';

let logger;

const storageManager = {
  setLogger: (loggerArg) => {
    logger = loggerArg.getLogger('vue-infra');
  },
};
['localStorage', 'sessionStorage'].forEach((method) => {
  const defaults = {
    cookiePrefix: `fallback:${method}:`,
    cookieOptions: {
      path: '/',
      domain: document.domain,
      expires: (method === 'localStorage') ? 365 : undefined,
    },
  };

  function validateStorageSupport(methodInner) {
    try {
      if (methodInner in window && window[methodInner] !== null) {
        window[methodInner].setItem('jqueryStorageWriteSupport', '1');
        window[methodInner].removeItem('jqueryStorageWriteSupport');
        return true;
      }
    } catch (e) {
      logger.debug(`Browser not support ${methodInner}`);
    }
    return false;
  }

  function loadJson(data) {
    try {
      return (data && JSON.parse(data)) || undefined;
    } catch (e) {
      return data || undefined;
    }
  }

  storageManager[method] = function storage(itemKey, itemValue, opts) {
    const options = { ...defaults, ...opts };
    if (options.forceCookie) {
      this[method].storageSupport = false;
    }
    this.getItem = function getItem(key) {
      const returns = (innerKey) => {
        const val = this[method].storageSupport ? window[method].getItem(innerKey)
          : lpCookie.get(options.cookiePrefix + innerKey, options.cookieOptions);
        return val && (this[method].storageSupport ? loadJson(JSON.parse(val)) : val);
      };
      if (typeof key === 'string') return returns(key);

      return [];
    };
    this.setItem = function setItem(key, value) {
      // the double stringify is to make sure the string start with ""
      return this[method].storageSupport
        ? window[method].setItem(key, JSON.stringify(JSON.stringify(value)))
        : lpCookie.set(options.cookiePrefix + key, value, options.cookieOptions);
    };
    this.removeItem = function removeItem(key) {
      return window[method].removeItem(key);
    };
    this.clear = function clear() {
      return window[method].clear();
    };

    if (typeof itemKey !== 'undefined') {
      /* eslint-disable no-nested-ternary */
      return typeof itemValue !== 'undefined'
        ? (itemValue === null ? this.removeItem(itemKey) : this.setItem(itemKey, itemValue))
        : this.getItem(itemKey);
    }
    return this;
  };
  storageManager[method].storageSupport = validateStorageSupport(method);
  storageManager[method].options = defaults;
  return this;
});
export default storageManager;
