/**
 * languino Vue 3 Translation Plugin
 * Utilizes the translator instance from
 * `window.LE` (`LEApplication` instance) from fe-framework.
 *
 * Usage: `this.$translator.translate('key')`
 * Ensure correct translation key and value in the languino package.
 */
const TranslatorVue3Plugin = {
  install(app) {
    const { translator } = window.LE;

    if (app && translator) {
      Object.defineProperties(app.config.globalProperties, {
        $translator: { value: translator },
      });
    }
  },
};

export default TranslatorVue3Plugin;
