import { PERSONALIZATION_WIDGET } from './types';

export const MODULES = 'modules';
export const REGIONS = 'regions';
export const ROUTES = 'routes';
export const VALID_APPS = 'validApps';
export const RESOLVE_PERSONA = 'resolvePersona';
export const RESOLVE_PERSONA_BY_PREFIX = 'resolvePersonaByPrefix';
export const RESOLVE_MODULES = 'resolveModules';
export const RESOLVE_MODULE_NAME = 'resolveModuleName';
export const RESOLVE_MODULE_NAME_BY_PREFIX = 'resolveModuleNameByPrefix';
export const RESOLVE_MODULE_ROUTE_PREFIX = 'resolveModuleRoutePrefix';
export const RESOLVE_BRAND_PROFILE = 'resolveBrandProfile';
export const CURRENT_AGENT_STATUS = 'currentAgentStatus';
export const CURRENT_MESSAGING_AGENT_STATUS = 'currentMessagingAgentStatus';
export const CURRENT_CHAT_AGENT_STATUS = 'currentChatAgentStatus';
export const REQUEST_AGENT_STATUS_CHANGE = 'requestAgentStatusChange';
export const AGENT_STATUS_DATA_STATE = 'agentStatusDataState';
export const RESOLVE_CUSTOM_AWAY_REASONS = 'resolveCustomAwayReasons';
export const RESOLVE_QUICK_LAUNCH_APPS = 'resolveQuickLaunchApps';
export const RESOLVE_SOUND_ENABLE = 'resolveSoundEnable';
export const RESOLVE_LE_TRAIL_INFORMATION = 'resolveTrailInformation';
export const TOP_BAR_NOTIFICATION = 'topBarNotification';
export const CURRENT_PERSONA = 'currentPersona';
export const PERSONA_NOTIFICATION = 'personaNotification';
export const LOGGED_IN_USER_DETAILS = 'loggedInUserDetails';
export const CURRENT_ACTIVE_THEME = 'currentActiveTheme';
export const CURRENT_RING = 'currentRing';
export const AGENT_TIMER = 'agentTimer';

export const PERSONALIZATION_WIDGET_PAGE = `${PERSONALIZATION_WIDGET}/page`;
export const PERSONALIZATION_WIDGET_IS_OPEN = `${PERSONALIZATION_WIDGET}/isOpen`;
export const PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT = `${PERSONALIZATION_WIDGET}/featuresByRoutCount`;
