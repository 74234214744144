/* eslint-disable */
// error handler
import BaseClient from './BaseClient';
import {CRUDMethods, EVENTS, HEADERS, DEFAULT_PARSE_ID_NAME} from "./consts";
import apiLayerUtils from './apiLayerUtils'

/**
 * Create ACReadClient extends  BaseClient.
 * http client for AC.
 *
 * @param {Object} options - config object.
 * @param {String} options.mapIdName - map response list by id.
 * @param {String} options.config - conf object.
 * @param {String} options.config.baseURL - base url.
 * @param {String} options.config.headers - headers to load on request.
 *
 */
export default class ACReadClient extends BaseClient{
  constructor(options) {
    // composition for Base client
    super(Object.assign({}, options, { allowedMethods: ['get'] }));
    this.logger.debug(`ACReadClient created baseURL: ${options.baseURL}`);
    this.loadResponseInterceptors(options);
    this.loadRequestInterceptors();
    this.HEADERS = HEADERS;
  }

  loadRequestInterceptors() {
    super.useRequestInterceptor(request => apiLayerUtils.buildHeaders(request));
  };

  loadResponseInterceptors(options) {
    const parseIdName = options.mapIdName || DEFAULT_PARSE_ID_NAME;
    super.useResponseInterceptor(response => apiLayerUtils.parseResponse(response, parseIdName));
  };

  updateRevisionedSubscription(axiosSource, options, newRevision){
    /*
      let's unsubscribe but without cleaning all the listeners.
      if the listeners are cleaned then the revisionedSubscribe method
      won't listen to the onSubscriptionUpdateNotification anymore implying two issues:
      1- no more revision handling
      2- it won't emit the onRevisionedSubscriptionUpdateNotification event away anymore
      and the consumer just won't get the data...
    */
    super.unsubscribe(axiosSource, false);
    if (options.config) {
      options.config.revision = newRevision;
    }
    return super.subscribe(options);
  }

  async revisionedSubscribe(options = {}) {
    this.clearAllListeners(EVENTS.onSubscriptionUpdateNotification);
    this.clearAllListeners(EVENTS.onSubscriptionError);
    let axiosSource;
    const method = options.method || CRUDMethods.GET;
    const uri = options.uri || undefined;
    const body = options.body || undefined;
    const config = options.config || undefined;

    try {
      const res = await this.instance[method](uri, body || config,
        config).catch((e) => {
        this.emit(EVENTS.onRevisionedSubscriptionError, e);
        this.logger.error(`revisionedSubscribe: ${e}`);
      });
      if (res) {
        this.on(EVENTS.onSubscriptionUpdateNotification, (data) => {
          // only is response is hold data in body.
          const revision = data && data.parsed && data.parsed.meta && data.parsed.meta.revision;
          if(data.status === 200 && revision && revision !== options.config.revision) {
            this.logger.debug(`changing subscription: from revision ${options.config.revision} to revision ${data.parsed.meta.revision}`);
            axiosSource = this.updateRevisionedSubscription(axiosSource, options, data.parsed.meta.revision);
          } else {
            this.logger.debug(`data not changed status 304`);
          }
          this.emit(EVENTS.onRevisionedSubscriptionUpdateNotification, data, axiosSource);
        });
        this.on(EVENTS.onSubscriptionError, (data) => {
          this.emit(EVENTS.onRevisionedSubscriptionError, data);
        });
        options.config = {revision: res.parsed.meta.revision};
        axiosSource = super.subscribe(options);
        this.emit(EVENTS.onRevisionedSubscriptionUpdateNotification, res, axiosSource);
        return axiosSource;
      }
    } catch (e) {
      this.emit(EVENTS.onRevisionedSubscriptionError, e);
      this.logger.error(`revisionedSubscribe: ${e}`);
    }
  }
};
