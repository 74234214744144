// This API is currently in experimental stage and is not supported in all browsers
const isUserAgentMemorySupported = window.crossOriginIsolated
  && typeof performance.measureUserAgentSpecificMemory === 'function';
// performance.memory is only supported in chrome based browsers and will be deprecated in the future
const isPerformanceMemorySupported = typeof performance.memory === 'object';

const useUserAgentSpecificMemoryApi = async () => {
  const { bytes } = await performance.measureUserAgentSpecificMemory();
  const { totalJSHeapSize } = performance.memory ?? 0;
  return { usedJSHeapSize: bytes, totalJSHeapSize };
};

const usePerformanceMemoryApi = async () => {
  const { usedJSHeapSize, totalJSHeapSize } = performance.memory;
  return { usedJSHeapSize, totalJSHeapSize };
};

export const isBrowserMemoryTrackingSupported = () => window.performance && (isUserAgentMemorySupported || isPerformanceMemorySupported);

export const getMemoryUsage = async () => {
  switch (true) {
    case isUserAgentMemorySupported:
      return useUserAgentSpecificMemoryApi();
    case isPerformanceMemorySupported:
      return usePerformanceMemoryApi();
    default:
      return { usedJSHeapSize: 0, totalJSHeapSize: 0 };
  }
};
