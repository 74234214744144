import { ENVIRONMENT_STATE_KEY, AGENT_STATUS_DATA_STATES } from './keys';

const getDefaultState = () => ({
  [ENVIRONMENT_STATE_KEY.LOGGED_IN_USER_DETAILS]: {},
  [ENVIRONMENT_STATE_KEY.MODULES]: [],
  [ENVIRONMENT_STATE_KEY.CURRENT_ACTIVE_THEME]: {},
  [ENVIRONMENT_STATE_KEY.CURRENT_RING]: undefined,
  [ENVIRONMENT_STATE_KEY.AGENT_TIMER]: '',
  [ENVIRONMENT_STATE_KEY.VALID_APPS]: [],
  [ENVIRONMENT_STATE_KEY.REGIONS]: {},
  [ENVIRONMENT_STATE_KEY.OUTER_ROUTE]: undefined,
  [ENVIRONMENT_STATE_KEY.ROUTES]: [],
  [ENVIRONMENT_STATE_KEY.CURRENT_PERSONA]: undefined,
  [ENVIRONMENT_STATE_KEY.BRAND_PROFILE]: {},
  [ENVIRONMENT_STATE_KEY.CURRENT_AGENT_STATUS]: {
    value: 'Away',
    isFirstInit: true,
  },
  [ENVIRONMENT_STATE_KEY.CURRENT_MESSAGING_AGENT_STATUS]: {
    value: 'AWAY',
    isFirstInit: true,
  },
  [ENVIRONMENT_STATE_KEY.CURRENT_CHAT_AGENT_STATUS]: {
    value: 'Away',
    isFirstInit: true,
  },
  [ENVIRONMENT_STATE_KEY.REQUEST_AGENT_STATUS_CHANGE]: {
    value: null,
  },
  [ENVIRONMENT_STATE_KEY.AGENT_STATUS_DATA_STATE]: AGENT_STATUS_DATA_STATES.NOT_REQUESTED,
  [ENVIRONMENT_STATE_KEY.CUSTOM_AWAY_REASONS]: [],
  [ENVIRONMENT_STATE_KEY.QUICK_LAUNCH_APPS]: [],
  [ENVIRONMENT_STATE_KEY.TOP_BAR_NOTIFICATION]: undefined,
  [ENVIRONMENT_STATE_KEY.LE_SOUND_ENABLE]: true,
  [ENVIRONMENT_STATE_KEY.LE_TRAIL_INFORMATION]: {},
  [ENVIRONMENT_STATE_KEY.PERSONALIZATION]: {
    [ENVIRONMENT_STATE_KEY.FEATURE_BY_ROUT_COUNT]: 0,
    [ENVIRONMENT_STATE_KEY.IS_OPEN]: false,
    [ENVIRONMENT_STATE_KEY.PAGE]: null,
    [ENVIRONMENT_STATE_KEY.IS_REPORTING_OPEN]: null,
  },
  [ENVIRONMENT_STATE_KEY.PERSONA_NOTIFICATION]: {
    agent: {
      count: 0,
      tooltip: '',
    },
    marketer: {
      count: 0,
      tooltip: '',
    },
    'agent-mngt': {
      count: 0,
      tooltip: '',
    },
  },
});

export default getDefaultState;
