export const ENVIRONMENT_STATE_KEY = {
  CURRENT_PERSONA: 'currentPersona',
  MODULES: 'modules',
  VALID_APPS: 'validApps',
  REGIONS: 'regions',
  ROUTES: 'routes',
  PERSONALIZATION: 'personalization',
  FEATURE_BY_ROUT_COUNT: 'featuresByRoutCount',
  IS_OPEN: 'isOpen',
  PAGE: 'page',
  IS_REPORTING_OPEN: 'isReportingOpen',
  OUTER_ROUTE: 'outerRoute',
  CURRENT_AGENT_STATUS: 'currentAgentStatus',
  CURRENT_MESSAGING_AGENT_STATUS: 'currentMessagingAgentStatus',
  CURRENT_CHAT_AGENT_STATUS: 'currentChatAgentStatus',
  REQUEST_AGENT_STATUS_CHANGE: 'requestAgentStatusChange',
  AGENT_STATUS_DATA_STATE: 'agentStatusDataState',
  BRAND_PROFILE: 'brandProfile',
  CUSTOM_AWAY_REASONS: 'customAwayReasons',
  QUICK_LAUNCH_APPS: 'quickLaunchApps',
  LE_SOUND_ENABLE: 'leSoundEnable',
  LE_TRAIL_INFORMATION: 'trailInformation',
  TOP_BAR_NOTIFICATION: 'topBarNotification',
  PERSONA_NOTIFICATION: 'personaNotification',
  LOGGED_IN_USER_DETAILS: 'loggedInUserDetails',
  CURRENT_ACTIVE_THEME: 'currentActiveTheme',
  CURRENT_RING: 'currentRing',
  AGENT_TIMER: 'agentTimer',
};

export const other = 'other';

export const AGENT_STATUS_DATA_STATES = {
  NOT_REQUESTED: 'notRequested',
  AWAITING_DENVER: 'awaitingDenver',
  STATUS_UPDATE_FAILED: 'statusUpdateFailed', // Status update failed in Denver
  AWAITING_UMS: 'awaitingUMS',
  STATUS_DISCREPANCY: 'statusDiscrepancy', // Status update failed in UMS
  SUCCESS: 'success',
};
