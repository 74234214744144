/* eslint-disable no-param-reassign */
import BaseClient from './BaseClient';
import apiLayerUtils from './apiLayerUtils';
import { DEFAULT_PARSE_ID_NAME } from './consts';

export default class ACWriteClient extends BaseClient {
  constructor(options) {
    super({ ...options, allowedMethods: ['post', 'delete', 'patch'] });
    this.logger.debug(`ACReadWriteBase created baseURL: ${options.baseURL}`);
    this.loadRequestInterceptors();
  }

  loadRequestInterceptors() {
    super.useRequestInterceptor((request) => apiLayerUtils.buildHeaders(request));
  }

  loadResponseInterceptors(options) {
    const parseIdName = options.mapIdName || DEFAULT_PARSE_ID_NAME;
    super.useResponseInterceptor((response) => apiLayerUtils.parseResponse(response, parseIdName));
  }
}
