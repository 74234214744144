/* eslint-disable no-param-reassign */
import ACReadClient from './ACReadClient';
import ACWriteBase from './ACWriteClient';
import EventEmitter from '../lib/EventEmitter';
import { EVENTS, CLIENTS_CSDS } from './consts';
import SessionManager from '../auth/session/sessionManager';

function buildClientOptions(host, options) {
  const baseURI = `https://${host}${options.path}`;
  const opts = { ...options };
  opts.config = { baseURL: baseURI, ...options.config };
  return opts;
}

export default class ACBaseClient extends EventEmitter {
  constructor(options) {
    super();
    const readOptions = buildClientOptions(
      SessionManager.getCsdsServiceURI(CLIENTS_CSDS.read),
      options,
    );
    const writeOptions = buildClientOptions(
      SessionManager.getCsdsServiceURI(CLIENTS_CSDS.write),
      options,
    );

    this.readClient = new ACReadClient(readOptions);
    this.writeClient = new ACWriteBase(writeOptions);

    this.readClient.on(EVENTS.onRevisionedSubscriptionUpdateNotification, (...args) => {
      this.emit(EVENTS.onRevisionedSubscriptionUpdateNotification, ...args);
    });

    this.readClient.on(EVENTS.onRevisionedSubscriptionError, (...args) => {
      this.emit(EVENTS.onRevisionedSubscriptionError, ...args);
    });
  }

  get(...args) {
    return this.readClient.get(...args);
  }

  async subscribe(...args) {
    return this.readClient.revisionedSubscribe(...args);
  }

  unsubscribe(cancelObject) {
    return this.readClient.unsubscribe(cancelObject);
  }

  updateRevisionedSubscription(...args) {
    return this.readClient.updateRevisionedSubscription(...args);
  }

  patch(path, body = {}, config = {}) {
    config.headers = { ...config.headers || {}, 'X-HTTP-Method-Override': 'PUT' };
    return this.writeClient.post(path, body, config);
  }

  delete(path, config = {}) {
    config.headers = { ...config.headers || {}, 'X-HTTP-Method-Override': 'DELETE' };
    return this.writeClient.post(path, {}, config);
  }

  create(...args) {
    return this.writeClient.post(...args);
  }

  update(path, body = {}, config = {}) {
    config.headers = { ...(config && config.headers) || {}, 'X-HTTP-Method-Override': 'PUT' };
    return this.writeClient.post(path, body, config);
  }
}
