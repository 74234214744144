export default {
  LOG_LEVEL: {
    DEBUG: 'debug',
    INFO: 'info',
    INFOTAM: 'infoTam',
    WARN: 'warn',
    ERROR: 'error',
    METRICS: 'metrics',
    GRAPH: 'graph',
    BAM: 'bam',
  },
};
