import {
  browserTracingIntegration,
  browserProfilingIntegration,
  init,
  makeFetchTransport,
  makeMultiplexedTransport,
  moduleMetadataIntegration,
  replayIntegration,
  setTag,
  setUser,
  setMeasurement,
} from '@sentry/vue';
import {
  isBrowserMemoryTrackingSupported,
  getMemoryUsage,
} from '../lib/memoryUsage';
import sessionManager from '../auth/session/sessionManager';
import {
  DEFAULT_ACCOUNT_ID,
  DEFAULT_ENDPOINT,
  DEFAULT_ENVIRONMENT,
  DEFAULT_SAMPLING,
  DEFAULT_USER_ID,
  DEFAULT_VERSION,
  ENDPOINTS,
  EXTRA_KEY,
  SENTRY_AC_FEATURE,
  SENTRY_RATE,
  SENTRY_SITE_SETTING,
  AUTH_SYSTEM_KEYS,
} from './consts';
import { getLogger } from '../logger/logger';

const logger = getLogger('Infra');

class SentryInitializer {
  constructor(
    vueApps,
    dsn,
    sampleRate,
    tracesSampleRate,
    tracePropagationTargets,
    blockedAccounts,
    profilesSampleRate,
    userSamplingProperties,
  ) {
    try {
      if (!vueApps.length) {
        logger.error('Requires at least one Vue App');
      }
      if (window.leConfiguration && window.leConfiguration.lp_grid
        && window.leConfiguration.lp_phase) {
        this.environment = `${window.leConfiguration.lp_grid}-${window.leConfiguration.lp_phase}`;
        this.version = window.leResourceBaseUrl.split('/').filter(Boolean).pop();
      } else {
        logger.error('window.leConfiguration is not defined');
        this.environment = DEFAULT_ENVIRONMENT;
        this.version = DEFAULT_VERSION;
      }
    } catch (error) {
      logger.error('Error initializing Sentry configuration:', error);
      this.environment = DEFAULT_ENVIRONMENT;
      this.version = DEFAULT_VERSION;
    }
    this.tracePropagationTargets = tracePropagationTargets || [];
    this.EXTRA_KEY = EXTRA_KEY;
    this.apps = vueApps;
    this.dsn = dsn;
    this.sampleRate = sampleRate || DEFAULT_SAMPLING;
    this.tracesSampleRate = tracesSampleRate || DEFAULT_SAMPLING;
    this.profilesSampleRate = profilesSampleRate || DEFAULT_SAMPLING;
    this.blockedAccounts = blockedAccounts || [];
    this.userSamplingProperties = userSamplingProperties || {};
  }

  initialize() {
    try {
      const userId = sessionManager.getUserId() || DEFAULT_USER_ID;
      this.accountId = sessionManager.getAccountId() || DEFAULT_ACCOUNT_ID;
      const authSystem = sessionManager.getAccountSettingValueByID(AUTH_SYSTEM_KEYS.SITE_SETTING);
      this.appendMemoryUsage = false;

      const updatedSamplingProperties = this.applyUserSamplingProperties(userId);
      this.sampleRate = updatedSamplingProperties.sampleRate;
      this.tracesSampleRate = updatedSamplingProperties.tracesSampleRate;
      this.profilesSampleRate = updatedSamplingProperties.profilesSampleRate;
      const disableSampling = this.shouldDisableSamplingForBlockedAccounts(this.accountId);
      const chosenSampleRates = this.determineSiteSettingsSampleRates();
      if (chosenSampleRates) {
        setTag('enforcedSampling', true);
        chosenSampleRates.forEach(({ rate, type }) => {
          this[type] = rate;
        });
      }
      const sampleRate = disableSampling ? DEFAULT_SAMPLING : this.sampleRate;
      const tracesSampleRate = disableSampling ? DEFAULT_SAMPLING : this.tracesSampleRate;
      const profilesSampleRate = disableSampling ? DEFAULT_SAMPLING : this.profilesSampleRate;
      init({
        app: this.apps,
        transport: this.createMultiplexedTransport(),
        environment: this.environment,
        release: this.version,
        dsn: this.dsn,
        integrations: [
          browserTracingIntegration({
            enableInp: true,
            beforeStartSpan: (context) => {
              const endpoint = this.determineApp();
              setTag('location', endpoint);
              setTag('accountId', this.accountId);
              setTag('userId', userId);
              setTag('authSystem', authSystem);

              if (this.appendMemoryUsage) {
                this.trackMemoryUsage();
              }

              return {
                ...context,
                name: window.location.hash,
              };
            },
          }),
          browserProfilingIntegration(),
          moduleMetadataIntegration(),
          replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
        beforeSend: (event) => this.beforeSendFunc(event),
        replaysSessionSampleRate: DEFAULT_SAMPLING,
        replaysOnErrorSampleRate: DEFAULT_SAMPLING,
        sampleRate,
        trackComponents: true,
        tracesSampleRate,
        tracePropagationTargets: this.tracePropagationTargets,
        profilesSampleRate,
      });
      setUser({
        id: userId,
        accountId: this.accountId,
      });
    } catch (error) {
      logger.error('Error during Sentry initialization:', error);
    }
  }

  shouldDisableSamplingForBlockedAccounts(accountId) {
    return !!(this.blockedAccounts.includes(accountId) || window.isAutomated);
  }

  beforeSendFunc(event) {
    try {
      if (event && event.exception && event.exception.values[0]
        && event.exception.values[0].stacktrace
        && event.exception.values[0].stacktrace.frames) {
        const { frames } = event.exception.values[0].stacktrace;
        const routeTo = frames
          .filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
          .map((v) => v.module_metadata);

        if (routeTo.length) {
          // eslint-disable-next-line no-param-reassign
          event.extra = {
            ...event.extra,
            [this.EXTRA_KEY]: routeTo,
          };
        }
      }
      return event;
    } catch (error) {
      logger.error('Error in beforeSend:', error);
    }
    return event;
  }

  /* eslint-disable class-methods-use-this */
  determineApp() {
    try {
      const endpoint = window.location.hash;
      const keys = Object.keys(ENDPOINTS);
      const matchedKey = keys.find((key) => endpoint.includes(key));
      return matchedKey ? ENDPOINTS[matchedKey] : DEFAULT_ENDPOINT;
    } catch (error) {
      logger.error('Error determining app:', error);
      return DEFAULT_ENDPOINT;
    }
  }

  createMultiplexedTransport() {
    return makeMultiplexedTransport(makeFetchTransport, (args) => {
      try {
        const event = args.getEvent();
        if (event && event.extra && Array.isArray(event.extra[this.EXTRA_KEY])) {
          return event.extra[this.EXTRA_KEY];
        }
      } catch (error) {
        logger.error('Error in makeMultiplexedTransport callback:', error);
      }
      return [];
    });
  }

  isValidSampleRate = (rate) => typeof rate === 'number' && rate >= 0 && rate <= 1;

  determineSiteSettingsSampleRates() {
    const isEnforcedSampling = sessionManager.getFeaturePropertyState(SENTRY_AC_FEATURE.SENTRY_IS_ENFORCED_SAMPLING);
    if (!isEnforcedSampling) {
      return null;
    }
    const sampleRates = [];
    const settings = {
      [SENTRY_SITE_SETTING.SENTRY_SAMPLE_RATE]: SENTRY_RATE.SAMPLE_RATE,
      [SENTRY_SITE_SETTING.SENTRY_TRACES_SAMPLE_RATE]: SENTRY_RATE.TRACES_SAMPLE_RATE,
      [SENTRY_SITE_SETTING.SENTRY_PROFILES_SAMPLE_RATE]: SENTRY_RATE.PROFILES_SAMPLE_RATE,
    };

    Object.entries(settings).forEach(([setting, rateType]) => {
      const rate = sessionManager.getAccountSettingValueByID(setting);
      if (rate != null && this.isValidSampleRate(rate)) {
        sampleRates.push({ rate, type: rateType });
      }
    });

    return sampleRates.length > 0 ? sampleRates : null;
  }

  // enable the memory usage tracking
  enableMemoryTracking() {
    if (!isBrowserMemoryTrackingSupported()) {
      logger.warn('browser does not support memory usage tracking');
      return;
    }
    this.appendMemoryUsage = true;
  }

  applyUserSamplingProperties(userId) {
    const userProps = this.userSamplingProperties[userId] || {};
    return {
      sampleRate: userProps.sampleRate ?? this.sampleRate,
      tracesSampleRate: userProps.tracesSampleRate ?? this.tracesSampleRate,
      profilesSampleRate: userProps.profilesSampleRate ?? this.profilesSampleRate,
    };
  }

  async trackMemoryUsage() {
    try {
      const [MEGABYTE, PERCENT] = ['megabyte', 'percent'];

      const { usedJSHeapSize, totalJSHeapSize } = await getMemoryUsage();

      const heapMemoryInUse = usedJSHeapSize / (1024 * 1024);
      const availableMemory = totalJSHeapSize / (1024 * 1024);
      const memoryUsedPercentage = usedJSHeapSize === 0 || totalJSHeapSize === 0 ? 0 : (usedJSHeapSize / totalJSHeapSize) * 100;

      logger.debug('Memory in use', heapMemoryInUse);
      setMeasurement('memoryInUse', heapMemoryInUse, MEGABYTE);

      logger.debug('available memory', availableMemory);
      setMeasurement('availableMemory', availableMemory, MEGABYTE);

      logger.debug('memory used percentage', memoryUsedPercentage);
      setMeasurement('memoryUsedPercentage', memoryUsedPercentage, PERCENT);

    } catch (error) {
      logger.error('Error in getMemoryUsage:', error);
    }
  }
}

export default SentryInitializer;
