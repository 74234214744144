import {
  includes, intersection, indexOf, isArray, isEmpty, isFunction, isNumber, isString, isUndefined, merge,
} from 'lodash-es';
import lpStorage from '../../lib/lpStorage';
import TransportLayer from '../transportLayer/TransportLayer';

import {
  ADD_PERSONA_TO_ARRAY_BY_POSITION,
  CONCAT_PERSONAS_TO_ARRAY,
  SESSION_INIT,
  SET_AGENT_GROUPS_DATA,
  SET_FEATURES,
  SET_USER_PRIVILEGES,
  SET_VALUE,
  SETTINGS_DATA,
} from '../../store/modules/session/action-types';
import { GET_FEATURE_STATE, GET_VALUE } from '../../store/modules/session/getter-types';
import SESSION_DEFAULTS from '../../store/modules/session/defaults';
import { DEFAULT_PERSONA_PRIORITY_MAP, PERSONA, PRIVILEGE_TO_PERSONA_MAP } from './keys';
import { SESSION_STATE_KEY } from '../../store/modules/session/keys';

import { SESSION } from '../../store/types';
import COMMON_CONSTANTS from '../../assets/common-constants-ids.json';
import gChannel from '../../lib/gChannel';
import ACCOUNT_SETTINGS_ID from '../../assets/account-settings-ids.json';
import COMPOUND_FEATURE_ID from '../../assets/compound-features-ids.json';
import BaseClient from '../../api-layer/BaseClient';

const registeredCallbacks = {};

const KEY = {
  LAST_STORAGE_KEY: '__last_storage_event_key',
  LAST_STORAGE_NEWVALUE: '__last_storage_event_newValue',
  LAST_STORAGE_OLDVALUE: '__last_storage_event_oldValue',
  LAST_STORAGE_TAB_CONTEXT: '__last_storage_tab_context',
  SESSION_STORAGE_TAB_CONTEXT: '__session_tab_storage_context',
  BILLING_DATA: 'billingData',
  IS_TRIAL_ACCOUNT: 'isTrailAccount',
  REVISION: 'revision',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  VALUE: 'value',
  IS_DELETED: 'isDeleted',
  CREATED_DATE: 'createdDate',
  TYPE: 'type',
  PROPERTY_VALUE: 'propertyValue',
  DELETED: 'deleted',
  SSO_LOGOUT_URL: 'ssoUnifiedLoginRedirectLogoutUrl',
  CURRENT_ACCOUNT_ID: 'currentAccountId',
  LAST_TOUCH: 'lastTouch',
  INVALIDATED: 'invalidated',
  INVALID: 'invalid',
  DESTROY: 'destroy',
};
Object.assign(KEY, SESSION_STATE_KEY);
const SESSION_KEYS_MAP = {
  'config.loginName': KEY.LOGIN_NAME,
  'config.isAdmin': KEY.ADMIN,
  'config.isLPA': KEY.LPA,
  'config.accountTimeZoneId': KEY.ACCOUNT_TIMEZONE_ID,
  'config.serverCurrentTime': KEY.SERVER_CURRENT_TIME,
  'config.serverTimeGMTDiff': KEY.SERVER_TIME_GMT_DIFF,
  'config.serverTimeZoneName': KEY.SERVER_TIME_ZONE,
  'config.timeDiff': KEY.TIME_DIFF,
  'config.userId': KEY.USER_ID,
  'config.userPid': KEY.USER_PID,
  'config.userPrivileges': KEY.USER_PRIVILEGES,

  'accountData.agentGroupsData': KEY.AGENT_GROUPS_DATA,
  'accountData.provisionData': KEY.PROVISION_DATA,
  'accountData.provisionData.features': KEY.FEATURES,
  'accountData.settingsData': KEY.SETTINGS_DATA,
  'accountData.settingsData.settings': KEY.SETTINGS,
  'csdsCollectionResponse.baseURIs': KEY.BASE_URIS,

  csdsCollectionResponse: KEY.CSDS_COLLECTION_RESPONSE,
  csrf: KEY.CSRF,
  glob: KEY.GLOB,
  GLOB: KEY.GLOB,
  sessionTTl: KEY.TTL,
  wsuk: KEY.WSUK,
};

// Public ENUM to control
// The keys
const CONFIG_ATTRIBUTE_ID = {
  LPA: 'isLPA',
  ADMIN: 'isAdmin',
};
// Define the default values for the model
const defaults = SESSION_DEFAULTS;

const sessionModelObj = {
  // Expose enums also on the model instance level

  KEY,
  ACCOUNT_SETTINGS_ID,
  COMPOUND_FEATURE_ID,
  idAttribute: KEY.ID,
  untouched: true,
  hasLiveEngage2: false,
  hasDataEncryptionFeature: false,
  hasMessagingFeature: false,
  hasSLAPerSkill: false,
  hasWrapUpFeature: false,
  hasLpAuthenticationFeature: false,
  isMigrating: false,
  unifiedWindow: false,
  limitStickySize: false,
  offsiteEngagement: false,
  isBlockAddingUsersFeature: false,
  isBlockSupportAccessFeature: false,
  isSeatBasedFeature: false,
  authenticationChat: false,
  blockMSTR: false,
  apiKeyManagement: false,
  enableLEMyAccount: false,
  multiChatCenterLoadBalancing: false,
  multiChatCenterCascadingLoadBalancing: false,
  agentTimer: false,
  lobFeature: false,
  name: 'sessionModel',
  defaults,
  $store: null,

  registerCallback(name, callBack) {
    if (isFunction(callBack)) {
      registeredCallbacks[name] = callBack;
    }
  },
  unregisterCallback(name) {
    if (Object.prototype.hasOwnProperty.call(registeredCallbacks, name)) {
      delete registeredCallbacks[name];
    }
  },
  initialize(options) {
    // Just call the method of the initialize logic
    this.$store = options.store;
    this.isSSO = false;
    this.logger = options.logger;
    this.resourceUrl = options.ACResource;
    this.load();
    this.KEY = KEY;
    this.transporter = options.transporter;
    this.LEConfig = options.LEConfig;
    this.logger.debug('sessionModel:initialize:SSO:Verify if it SSO connection');
    if (document.cookie.indexOf('partner_sso_csrf') > 0) {
      this.logger.debug('sessionModel:initialize:SSO:its sso connection');
      this.isSSO = true;
      const headers = {};
      // eslint-disable-next-line prefer-const
      headers['x-csrf-token'] = document.cookie.split(' ').find((c) => c.startsWith('partner_sso_csrf')).split('=')[1].replace(';', '');
      this.baseClient = new BaseClient({
        allowedMethods: ['get', 'delete', 'post'],
        errorHandler: options.sessionErrorHandler,
        config: {
          headers,
          baseURL: this.resourceUrl,
        },
      });
    } else {
      this.logger.debug('sessionModel:initialize:SSO:Its regular connection, not sso');
      this.baseClient = new BaseClient({
        allowedMethods: ['get', 'delete', 'post'],
        errorHandler: options.sessionErrorHandler,
        config: {
          baseURL: this.resourceUrl,
        },
      });
    }
  },
  setBaseClientBaseUrl(url) {
    if (!url) return;
    this.baseClient.setBaseUrl(url);
  },
  parse(response) {
    if (!isUndefined(response) && !isString(response) && !isEmpty(response)) {
      // For hacking multitab support
      // TODO: Remove when MultiTab support should be enabled
      // Validate there is no previous user on this session
      const previousUser = lpStorage.sessionStorage(`${KEY.USER_ID}_LE2`);
      const accountId = this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.ACCOUNT_ID);
      const loginName = response[KEY.CONFIG][KEY.LOGIN_NAME];

      if (!this.isNullOrEmptyString(previousUser) && previousUser !== (`${accountId}>>${loginName}`)) {
        const prevAccountId = previousUser.split('>>')[0];
        this.logger.debug('Holy Sh!!!!!!!!t, a different user is now using this tab - This can cause a Zombie session to be left active!');
        this.logger.debug('Lets clean zombies from storage!');
        this.flushStore({ accountId: prevAccountId, flushStore: { local: true, session: true } });
      }

      const persona = this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.PERSONA);

      // parse the privileges and assemble the valid personas array for this user
      const privileges = {
        default: (this.LEConfig && this.LEConfig.defaultPrivileges),
        custom: response[KEY.CONFIG][KEY.USER_PRIVILEGES],
      };
      this.$store.dispatch(`${SESSION}/${SET_USER_PRIVILEGES}`, privileges);
      this.$store.dispatch(`${SESSION}/${SET_FEATURES}`, response[KEY.ACCOUNT_DATA][KEY.PROVISION_DATA][KEY.FEATURES]);
      this.$store.dispatch(`${SESSION}/${SETTINGS_DATA}`, response[KEY.ACCOUNT_DATA][KEY.SETTINGS_DATA][KEY.SETTINGS]);
      this.$store.dispatch(`${SESSION}/${SET_AGENT_GROUPS_DATA}`, response[KEY.ACCOUNT_DATA][KEY.AGENT_GROUPS_DATA][KEY.ITEMS]);

      if (this.hasLiveEngage2Feature() || this.hasMigratingFeature()) {
        // Iterate over all available personas
        Object.entries(PRIVILEGE_TO_PERSONA_MAP).forEach(function iteratePrivileges([key, value]) {
          // Check if the privileges list contains the current persona and
          // that the account is not migrating and the current persona is admin
          // Account will be defined as migrating only if it has the migrating feature
          // and not the LE2 feature. For migrating accounts, the user management (admin persona)
          // should not be available

          // value can be either a number or array of numbers
          this.compareUserPrivilegesForPersona(value, key, response);
        }, this);
      } else {
        // Check if the privileges list contains the agent persona
        let agentPrivilege = PRIVILEGE_TO_PERSONA_MAP[PERSONA.AGENT];
        if (!(agentPrivilege instanceof Array)) {
          agentPrivilege = [agentPrivilege];
        }
        const matchPrivileges = intersection(
          this.$store.getters[`${SESSION}/${GET_VALUE}`]([KEY.USER_PRIVILEGES]),
          agentPrivilege,
        );
        if (matchPrivileges && matchPrivileges.length > 0) {
          // Add the agent persona as a default persona
          this.$store.dispatch(`${SESSION}/${SET_VALUE}`, {
            attribute: KEY.DEFAULT_PERSONA,
            value: PERSONA.AGENT,
          });

          // Add the agent and customizer persona to the personas array
          this.$store.dispatch(`${SESSION}/${CONCAT_PERSONAS_TO_ARRAY}`, [PERSONA.AGENT, PERSONA.REQ, PERSONA.CUSTOMIZER]);
        }
      }

      // Set the persona for the login
      if (!isUndefined(persona) && includes(this.$store.getters[`${SESSION}/${GET_VALUE}`]([KEY.PERSONA]), persona)) {
        this.$store.dispatch(`${SESSION}/${SET_VALUE}`, {
          attribute: KEY.PERSONA,
          value: persona,
        });
      } else {
        this.$store.dispatch(`${SESSION}/${SET_VALUE}`, {
          attribute: KEY.PERSONA,
          value: this.$store.getters[`${SESSION}/${GET_VALUE}`]([KEY.DEFAULT_PERSONA]),
        });
      }

      // Clean the OTK value
      this.$store.dispatch(`${SESSION}/${SET_VALUE}`, {
        attribute: KEY.OTK,
        value: undefined,
      });

      // Set the ID to indicate a successful authentication
      // (and trigger an event which modules can listen to)
      this.$store.dispatch(`${SESSION}/${SET_VALUE}`, {
        attribute: KEY.ID,
        value: 'authenticated',
      });

      this.$store.dispatch(`${SESSION}/${SESSION_INIT}`, response);

      this.logger.debug(`multiChatCenterLoadBalancing = ${this.hasMultiChatCenterLoadBalancing()}`);
      this.logger.debug(`multiChatCenterCascadingLoadBalancing = ${this.hasMultiChatCenterCascadingLoadBalancing()}`);
      this.logger.debug(`agentTimer = ${this.hasAgentTimer()}`);
      this.logger.debug(`hasLiveEngage = ${this.hasLiveEngage2Feature()}`);
      this.logger.debug(`hasDataEncryptionFeature = ${this.hasDataEncryptionDataFeature()}`);
      this.logger.debug(`hasMessagingFeature = ${this.hasAsyncMessagingFeature()}`);
      this.logger.debug(`hasWrapUpTimeFeature = ${this.hasWrapUpTimeFeature()}`);
      this.logger.debug(`hasUserTypeBot = ${this.hasUserTypeBotFeature()}`);
      this.logger.debug(`hasLpAuthenticationFeature = ${this.hasLpAuthenticationSupportFeature()}`);
      this.logger.debug(`isMigrating = ${this.hasMigratingFeature()}`);
      this.logger.debug(`isAgentGroups = ${this.hasAgentGroupsFeature()}`);
      this.logger.debug(`isVisitorFeedPreFiltering = ${this.hasVisitorFeedPreFiltering()}`);
      this.logger.debug(`limitStickySize = ${this.hasLimitStickySizeFeature()}`);
      this.logger.debug(`offsiteEngagement = ${this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.OFFSITE_ENGAGEMENT)}`);
      this.logger.debug(`hasBlockAddingUsersFeature = ${this.hasBlockAddingUsersFeature()}`);
      this.logger.debug(`hasBlockSupportAccessFeature = ${this.hasBlockSupportAccessFeature()}`);
      this.logger.debug(`hasSeatBasedFeature=  ${this.hasSeatBasedFeature()}`);
      this.logger.debug(`authenticationChat = ${this.hasAuthenticationChatFeature()}`);
      this.logger.debug(`blockMSTR = ${this.hasBlockMSTRAccessFeature()}`);
      this.logger.debug(`apiKeyManagement = ${this.hasApiKeyManagementFeature()}`);
      this.logger.debug(`ipRestriction = ${this.hasIpRestrictionFeature()}`);
      this.logger.debug(`enableLEMyAccount = ${this.hasLEMyAccount()}`);
      this.logger.debug(`lobFeature = ${this.hasLobFeature()}`);
      this.logger.debug(`entitiesTaggingByLob = ${this.hasEntitiesTaggingByLobFeature()}`);
      this.logger.debug(`connectionBarCaseManagementVisible = ${this.hasConnectionBarCaseManagementVisible()}`);
      this.logger.debug(`connectionBarDisplay = ${this.hasConnectionBarDisplay()}`);
      this.logger.debug(`connectionBarOpenCaseVisible = ${this.hasConnectionBarOpenCaseVisible()}`);
      this.logger.debug(`connectionBarNotificationVisible = ${this.hasConnectionBarNotificationVisible()}`);
      this.logger.debug(`reportBuilderAccessFeature = ${this.hasReportBuilderAccessFeature()}`);
      this.logger.debug(`reportBuilderNonLpaAccessFeature = ${this.hasReportBuilderNonLpaAccessFeature()}`);
      this.logger.debug(`lpaProfilesAssignmentFeature = ${this.hasLpaProfilesAssignmentFeature()}`);
      this.logger.debug(`liveChatQueuePriority = ${this.hasLiveChatQueuePriorityFeature()}`);
      this.logger.debug(`contentProviderAccountFeature = ${this.hasContentProviderAccountFeature()}`);
      this.logger.debug(`messagingSurveyFeature = ${this.hasMessagingSurveyFeature()}`);
      this.logger.debug(`messagingSurveyFeature = ${this.hasMessagingTransferToAgentFeature()}`);
      this.logger.debug(`aiAssistWidgetFeature =  ${this.hasAIAssistWidgetFeature()}`);
    }
    return response;
  },

  clear(options) {
    this.flushStore(options);
  },
  isNew() {
    return this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.ID) === undefined;
  },
  async save(creds, options) {
    /* eslint-disable prefer-rest-params */
    /* eslint-disable no-param-reassign */
    options = options || {};

    const { success } = options;

    // Want to store the data upon success
    options.success = function successCallback(model) {
      // parse session model to store
      this.parse(model);

      // init local-storage
      this.store();

      if (success) {
        success.apply(this, [].slice.apply(arguments));
      }
    }.bind(this);

    options.url = this.resourceUrl;
    // First lets validate
    if (this.isSSO || this.isNewSession(creds)) {
      const clearOptions = merge({ flushStore: { session: false, local: true } }, creds);
      // First lets be sure there is no previous garbage (clear old data)
      this.clear(clearOptions);
      options.data = JSON.stringify(creds);
      let session;
      try {
        this.logger.debug('sessionModel:save:SSO:verify if its SSO');
        if (this.isSSO) {
          this.logger.info('sessionModel:save:SSO:sending sso request');
          session = await this.baseClient.get(undefined);
          this.logger.bam({
            serviceName: 'Vue-Infra',
            appName: 'CCUI',
            eventName: 'SSO connection to CCUI',
          });
        } else {
          this.logger.debug('sessionModel:save:SSO:sending get request');
          session = await this.baseClient.post(undefined, creds);
        }
      } catch (error) {
        if (this.isSSO) {
          this.logger.bam({
            serviceName: 'Vue-Infra',
            appName: 'CCUI',
            eventName: 'SSO connection to CCUI failed .',
          });
          this.logger.error(`sessionModel:save:SSO:sending get request: session created failed, ${error.message}`);
        }
        this.logger.error(`[sessionManager,save]: session created failed, ${error.message}`);
        return options.error(error.response, error, options);
      }
      this.logger.debug('[sessionManager,save]: session created successfully');
      return options.success(session.data, session, options);
      // return TransportLayer.create.call(this, options, this.transporter);
    }
    // I just want to update the client data for the existing session
    // So I need to fetch (GET)
    // Since fetch does not validate the data (OTK||CSRF & ACCOUNT ID)
    // I'll trigger the validation
    const invalid = this.validate.call(this, creds);

    if (invalid) {
      registeredCallbacks[KEY.INVALID](invalid);
    } else {
      // Now lets get the data
      let session;
      try {
        const csrf = this.$store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.CSRF);
        const headers = {};
        if (csrf) {
          headers['x-csrf-token'] = csrf;
        }
        session = await this.baseClient.get(undefined, { headers });
      } catch (error) {
        this.logger.error(`[sessionManager,save]: session refresh failed, ${error.message}`);
        return options.error(error.response, error, options);
      }
      this.logger.debug('[sessionManager,save]: session refresh successfully');
      return options.success(session.data, session, options);
    }
    return -1;
  },
  async destroy(options) {
    options.url = this.resourceUrl;
    const { success } = options;
    options.success = (resp) => {
      if (!this.isNew()) {
        if (registeredCallbacks
          && registeredCallbacks[KEY.DESTROY]
          && typeof registeredCallbacks[KEY.DESTROY] === 'function') {
          registeredCallbacks[KEY.DESTROY]();
        }
        success(this, resp);
      }
    };
    options.dataType = TransportLayer.DEFAULTS.contentType.text;
    let session;
    try {
      const csrf = this.$store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.CSRF);
      const headers = {};
      if (csrf) {
        headers['x-csrf-token'] = csrf;
      }
      session = await this.baseClient.delete(undefined, { headers });
    } catch (error) {
      this.logger.error(`[sessionManager,destroy]: session destroyed failed, ${error.message}`);
      return options.error(error.response, error, options);
    }
    this.logger.debug('[sessionManager,destroy]: session destroyed successfully');
    return options.success(session.data, session, options);
  },

  set(obj, value) {
    if (typeof obj === 'object') {
      Object.keys(obj).forEach((key) => {
        this.setAttribute(key, obj[key]);
      });
    } else if (typeof obj === 'string') {
      this.setAttribute(obj, value);
    }
  },

  setAttribute(name, value) {
    if (Object.values(SESSION_STATE_KEY).indexOf(name) > -1) {
      this.$store.dispatch(`${SESSION}/${SET_VALUE}`, {
        attribute: name,
        value,
      });
    } else if (Object.keys(SESSION_KEYS_MAP).indexOf(name) > -1) {
      this.$store.dispatch(`${SESSION}/${SET_VALUE}`, {
        attribute: SESSION_KEYS_MAP[name],
        value,
      });
    }
  },

  get(name) {
    if (Object.prototype.hasOwnProperty.call(SESSION_KEYS_MAP, name)) {
      return this.$store.getters[`${SESSION}/${GET_VALUE}`](SESSION_KEYS_MAP[name]);
    }
    return undefined;
  },

  isNullOrEmptyString(string) {
    return (!isString(string) || string.length === 0);
  },

  isNewSession(creds) {
    creds = creds || {};

    if (!this.isNullOrEmptyString(creds[KEY.OTK])) {
      return true;
    }

    if (!this.isNullOrEmptyString(creds[KEY.ACCOUNT_ID])) {
      this.load({ accountId: creds[KEY.ACCOUNT_ID] });
      this.$store.dispatch(`${SESSION}/${SET_VALUE}`, { attribute: KEY.ACCOUNT_ID, value: creds[KEY.ACCOUNT_ID] });
    }

    return (this.isNullOrEmptyString(this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.CSRF)));
  },

  hasAttributeValue(key) {
    return !this.isNullOrEmptyString(this.$store.getters[`${SESSION}/${GET_VALUE}`](key));
  },

  validate(attrs) {
    const errors = [];

    if (this.isNullOrEmptyString(attrs[KEY.ACCOUNT_ID])) {
      this.logger.error('Invalid accountId Parameter');
      this.logger.graph({ name: 'framework:sessionModel:validate:invalidAccountId' });

      errors.push({ name: 'Login Parameters', message: 'Invalid accountId Parameter' });
    } else {
      this.load({ accountId: attrs[KEY.ACCOUNT_ID] });
    }

    if (this.isNullOrEmptyString(attrs[KEY.OTK])) {
      if (this.isNullOrEmptyString(attrs[KEY.CSRF])
        && this.isNullOrEmptyString(this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.CSRF))) {
        this.logger.error('Could not find no OTK and no CSRF token - NO CAN BE!!!');
        this.logger.graph({ name: 'framework:sessionModel:validate:noOtkNoCSRF' });

        errors.push({ name: 'Login Parameters', message: 'Invalid OTK and CSRF' });
      }
    }

    if (errors.length > 0) {
      return errors;
    }
    return false;
  },
  loadJSON(data) {
    return (data && JSON.parse(data)) || undefined;
  },

  loadValue(options) {
    options = options || {};

    if (KEY.CURRENT_ACCOUNT_ID === options.key) {
      return lpStorage.localStorage(KEY.CURRENT_ACCOUNT_ID);
    }

    if (this.isNullOrEmptyString(options.accountId)) {
      options.accountId = this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.ACCOUNT_ID);
      options.setAccountIdOptions = undefined;
    }

    if (this.isNullOrEmptyString(options.accountId)) {
      return undefined;
    }
    const jsonObject = lpStorage.localStorage(`${options.accountId}_${options.key}`);

    if (!isUndefined(options.applyOptions) && !isUndefined(jsonObject)) {
      this.$store.dispatch(`${SESSION}/${SET_VALUE}`, { attribute: options.key, value: jsonObject });
    }

    return jsonObject;
  },

  getStored(key) {
    return this.loadValue({ key });
  },

  hasValidValue(key, options) {
    const val = this.$store.getters[`${SESSION}/${GET_VALUE}`](key);

    if (options && options.stored) {
      const stored = this.getStored.call(this, key);
      return !this.isNullOrEmptyString(val) && !this.isNullOrEmptyString(stored);
    }
    return !this.isNullOrEmptyString(val);
  },

  localStorageStoreItem(key, jsonString) {
    // For hacking multitab support
    // TODO: Remove when MultiTab support should be enabled
    if (KEY.CURRENT_ACCOUNT_ID === key) {
      // event is not sent if the storage is not mutated!
      if (lpStorage.localStorage('rand') !== null) {
        this.localStorageClearItem('rand');
      }

      lpStorage.localStorage(
        KEY.LAST_STORAGE_TAB_CONTEXT,
        this.getSessionContext(),
      );
    }

    lpStorage.localStorage(key, jsonString);
  },

  sessionStorageStoreItem(key, jsonString) {
    lpStorage.sessionStorage(key, jsonString);
  },

  localStorageClearItem(key) {
    lpStorage.localStorage().removeItem(key);
  },

  sessionStorageClearItem(key) {
    lpStorage.sessionStorage().removeItem(key);
  },
  getCurrentAccountIds() {
    const current = this.loadValue({ key: KEY.CURRENT_ACCOUNT_ID });

    if (this.isNullOrEmptyString(current)) {
      return [];
    }
    return current.split('!');
  },

  getCurrentAccountId() {
    // TODO test
    const ids = this.getCurrentAccountIds();

    // Get the last account ID in the list of active accounts
    if (!ids || ids.length === 0) {
      return undefined;
    }
    let pointer = ids.length - 1;
    let id = ids[pointer];
    while (this.isNullOrEmptyString(id) && pointer >= 0) {
      pointer -= 1;
      id = ids[pointer];
    }
    return (!this.isNullOrEmptyString(id) ? id : undefined);
  },

  setCurrentAccountId(accountId) {
    if (!this.isNullOrEmptyString(accountId)) {
      let current = this.loadValue({ key: KEY.CURRENT_ACCOUNT_ID });

      if (this.isNullOrEmptyString(current)) {
        current = accountId;
      } else {
        current = this.removeAccountIdFromCurrent(accountId);

        if (isString(current)) {
          current += '!';
        } else {
          current = '';
        }
        current += accountId;
      }

      this.localStorageStoreItem(KEY.CURRENT_ACCOUNT_ID, current);
    }
  },

  removeAccountIdFromCurrent(accountId) {
    let current = this.loadValue({ key: KEY.CURRENT_ACCOUNT_ID });

    if (!this.isNullOrEmptyString(accountId)) {
      if (!this.isNullOrEmptyString(current)) {
        const ids = current.split('!');
        const ind = indexOf(ids, accountId);
        if (ind !== -1) {
          ids.splice(ind, 1);
          if (ids.length > 0) {
            current = ids.join('!');
            this.localStorageStoreItem(KEY.CURRENT_ACCOUNT_ID, current);
          } else {
            current = undefined;
            this.localStorageClearItem(KEY.CURRENT_ACCOUNT_ID);
          }
        }
      }
    }

    return current;
  },

  hasCurrentAccountId(options) {
    options = options || {};
    let accountIdFound = false;

    const accountId = options.accountId || this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.ACCOUNT_ID);

    if (!this.isNullOrEmptyString(accountId)) {
      const current = options.current || this.loadValue({ key: KEY.CURRENT_ACCOUNT_ID });

      if (!this.isNullOrEmptyString(current)) {
        const ids = current.split('!');
        const ind = indexOf(ids, accountId);

        accountIdFound = (ind !== -1);
      }
    }

    return accountIdFound;
  },

  invalidateAccountSession(accountId) {
    this.storeValue({ key: KEY.INVALIDATED, value: true, accountId });
  },

  isInvalidatedSession() {
    let invalidated = this.getStored(KEY.INVALIDATED);
    if (invalidated === 'true') {
      invalidated = true;
    }
    return invalidated || false;
  },

  isValidSession() {
    const valid = this.hasValidValue(KEY.ID)
      && this.hasValidValue(KEY.ACCOUNT_ID)
      && this.hasValidValue(KEY.CSRF)
      && this.hasValidValue(KEY.GLOB)
      && this.hasValidValue(KEY.WSUK);

    return valid && !this.isInvalidatedSession() && !this.isNew();
  },

  storeValue(options) {
    options = options || {};

    if (!this.isNullOrEmptyString(options.key)) {
      if (isUndefined(options.value)) {
        options.value = this.$store.getters[`${SESSION}/${GET_VALUE}`](options.key);
        options.setValueOptions = undefined;
      }

      if (!isUndefined(options.value)) {
        if (!isUndefined(options.setValueOptions)) {
          this.$store.dispatch(`${SESSION}/${SET_VALUE}`, { attribute: options.key, value: options.value });
        }
        if (KEY.ACCOUNT_ID === options.key) {
          this.setCurrentAccountId(options.value);
        } else {
          if (!this.isNullOrEmptyString(options.accountId)
            && !isUndefined(options.setAccountIdOptions)) {
            this.$store.dispatch(`${SESSION}/${SET_VALUE}`, { attribute: KEY.ACCOUNT_ID, value: options.accountId });
            this.setCurrentAccountId(options.accountId);
          }
          options.accountId = options.accountId || this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.ACCOUNT_ID);

          const key = `${options.accountId}_${options.key}`;
          this.localStorageStoreItem(key, options.value);
        }
      }
    }
  },

  updateLastTouched() {
    this.storeValue({ key: KEY.LAST_TOUCH, value: (new Date()).getTime() });
  },

  getLastTouched() {
    /* eslint-disable no-nested-ternary */
    const last = this.getStored(KEY.LAST_TOUCH);
    return isNumber(last) ? last : (!Number.isNaN(last) ? parseInt(last, 10) : 0);
  },

  randomizeContext(placeholder) {
    /* eslint-disable no-bitwise */
    /* eslint-disable no-mixed-operators */
    return placeholder ? (placeholder ^ Math.random() * 16 >> placeholder / 4)
      .toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
      .replace(/[018]/g, this.randomizeContext);
  },

  getSessionContext() {
    let context = lpStorage.sessionStorage(`${KEY.SESSION_STORAGE_TAB_CONTEXT}_LE2`);

    if (this.isNullOrEmptyString(context)) {
      context = this.randomizeContext();
      this.sessionStorageStoreItem(`${KEY.SESSION_STORAGE_TAB_CONTEXT}_LE2`, context);
    }

    return context;
  },

  isLastUpdateOwner() {
    return this.getSessionContext()
      === lpStorage.localStorage(KEY.LAST_STORAGE_TAB_CONTEXT);
  },

  load(options) {
    // TODO: implement the load (to localStorage or cookies)
    // For now I just use jquery localStorage plugin with cookies fallback
    options = options || {};

    const accountId = options.accountId || this.getCurrentAccountId();
    if (!this.isNullOrEmptyString(accountId)) {
      this.loadValue({
        key: KEY.CSRF,
        accountId,
        setAccountIdOptions: { silent: true },
        applyOptions: { silent: true },
      });
      this.loadValue({
        key: KEY.PERSONA,
        accountId,
        setAccountIdOptions: { silent: true },
        applyOptions: { silent: true },
      });
    }
  },

  parsePrivilegeObject(privilegeValue) {
    return {
      num: (privilegeValue && privilegeValue.privilege) || privilegeValue,
      condition: privilegeValue && privilegeValue.condition,
    };
  },

  compareUserPrivilegesForPersona(privilegeValue, privilegeKey) {
    /* eslint-disable no-mixed-operators */
    if (!isArray(privilegeValue)) {
      privilegeValue = [privilegeValue];
    }

    if (this.hasLiveEngage2Feature() || PERSONA.ADMIN !== privilegeKey) {
      privilegeValue.forEach(function intratePrivilegeValue(privValue) {
        const privValueObject = this.parsePrivilegeObject(privValue);
        const privValueNum = privValueObject.num;
        const privValueCondition = privValueObject.condition;
        const checkPersona = !privValueCondition
          || privValueCondition && privValueCondition.method
          && this[privValueCondition.method]
          && this[privValueCondition.method].call(this, ...privValueCondition.args);

        if (checkPersona
          && includes(this.$store.getters[`${SESSION}/${GET_VALUE}`]([KEY.USER_PRIVILEGES]), privValueNum)) {
          // Check if this persona should be the default persona
          if (isUndefined(this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.DEFAULT_PERSONA))
            || (DEFAULT_PERSONA_PRIORITY_MAP[privilegeKey]
              > DEFAULT_PERSONA_PRIORITY_MAP[this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.DEFAULT_PERSONA)])) {
            // Add the persona as a default persona
            this.$store.dispatch(`${SESSION}/${SET_VALUE}`, { attribute: KEY.DEFAULT_PERSONA, value: privilegeKey });
          }

          // Add the persona to the personas array based on priority
          if (this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.PERSONAS).indexOf(privilegeKey) === -1) {
            this.$store.dispatch(`${SESSION}/${ADD_PERSONA_TO_ARRAY_BY_POSITION}`, {
              attribute: KEY.PERSONAS,
              pos: DEFAULT_PERSONA_PRIORITY_MAP[privilegeKey],
              value: privilegeKey,
            });

            // TODO: remove!!!!!
            // add req perona for development.
            if (privilegeKey === PERSONA.AGENT) {
              this.$store.dispatch(`${SESSION}/${ADD_PERSONA_TO_ARRAY_BY_POSITION}`, {
                attribute: KEY.PERSONAS,
                pos: DEFAULT_PERSONA_PRIORITY_MAP[privilegeKey],
                value: PERSONA.REQ,
              });
            }
          }
        }
      }, this);
    }
  },
  async sustain(creds, options) {
    options = options || {};

    const { success } = options;
    // Want to store the data upon success

    options.success = function successCallback() {
      this.updateLastTouched();

      if (success) {
        gChannel.trigger(COMMON_CONSTANTS.INFRA_CHANNEL, COMMON_CONSTANTS.ON_SESSION_RECONNECT);
        success.apply(this, [].slice.apply(arguments));
      }
    }.bind(this);

    options.url = this.resourceUrl;
    // First lets validate
    if (this.isNewSession(creds)) {
      // Needs to create the session first
      // options.data = JSON.stringify(creds);
      let session;
      try {
        session = await this.baseClient.post(undefined, creds);
      } catch (error) {
        this.logger.error(`[sessionManager,sustain]: new session sustain failed, ${error.message}`);
        return options.error(error.response, error, options);
      }
      this.logger.debug('[sessionManager,sustain]: new session sustained successfully');
      return options.success(session.data, session, options);
    }
    // So I need to fetch (GET)
    // Since fetch does not validate the data (OTK||CSRF & ACCOUNT ID)
    // I'll trigger the validation
    const invalid = this.validate.call(this, creds);
    if (invalid) {
      registeredCallbacks[KEY.INVALID](invalid);
    } else {
      let session;
      const csrf = this.$store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.CSRF);
      const headers = {};
      if (csrf) {
        headers['x-csrf-token'] = csrf;
      }
      try {
        session = await this.baseClient.get(
          undefined,
          { headers, params: { refresh: true } },
        );
      } catch (error) {
        this.logger.error(`[sessionManager,sustain]: session sustain failed, ${error.message}`);
        return options.error(error.response, error, options);
      }
      this.logger.debug('[sessionManager,sustain]: session sustained successfully.');

      return options.success(session.data, session, options);
    }
    return -1;
  },

  flushStore(options) {
    options = options || {};
    options.flushStore = options.flushStore || {};

    // For hacking multitab support
    // TODO: Remove when MultiTab support should be enabled
    if (!options.noDestroy) {
      const accountId = options.accountId || this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.ACCOUNT_ID);

      if (!this.isNullOrEmptyString(accountId)) {
        this.localStorageClearItem(`${accountId}_KEY.CSRF`);
        this.localStorageClearItem(`${accountId}_KEY.PERSONA`);
        this.localStorageClearItem(`${accountId}_KEY.LAST_TOUCH`);
        this.localStorageClearItem(`${accountId}_KEY.INVALIDATED`);
        this.removeAccountIdFromCurrent(accountId);
      }
    }

    if (options.flushStore.session) {
      this.sessionStorageClearItem(`${KEY.USER_ID}_LE2`);
    }
  },

  store(options) {
    options = options || {};

    const silentOption = { silent: true };
    let storeAccountIdOptions = silentOption;
    let { accountId } = options;

    if (this.isNullOrEmptyString(accountId)) {
      accountId = this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.ACCOUNT_ID);
      storeAccountIdOptions = undefined;
    }
    if (this.isNullOrEmptyString(accountId)) {
      accountId = this.getCurrentAccountId();
      storeAccountIdOptions = silentOption;
    }

    if (!this.isNullOrEmptyString(accountId)) {
      let setCsrfOptions = silentOption;
      let setPersonaOptions = silentOption;
      let { csrf } = options;
      let { persona } = options;

      if (this.isNullOrEmptyString(csrf)) {
        csrf = this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.CSRF);
        setCsrfOptions = undefined;
      }

      if (this.isNullOrEmptyString(persona)) {
        persona = this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.PERSONA);
        setPersonaOptions = undefined;
      }

      if (!this.isNullOrEmptyString(csrf)) {
        this.storeValue({
          key: KEY.CSRF,
          value: csrf,
          accountId,
          setValueOptions: setCsrfOptions,
        });
      }
      if (!this.isNullOrEmptyString(persona)) {
        this.storeValue({
          key: KEY.PERSONA,
          value: persona,
          accountId,
          setValueOptions: setPersonaOptions,
        });
      }

      this.storeValue({
        key: KEY.ACCOUNT_ID,
        value: accountId,
        setValueOptions: storeAccountIdOptions,
      });
      this.updateLastTouched();

      this.sessionStorageStoreItem(`${KEY.USER_ID}_LE2`, `${accountId}>>${this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.LOGIN_NAME)}`);
    }
  },

  expire(options) {
    // Set auth to false to trigger a change:auth event
    // The server also returns a new csrf token so that
    // the user can relogin without refreshing the page
    options = options || {};

    const hasAccount = !this.isNullOrEmptyString(options[KEY.ACCOUNT_ID]);
    const hasCsrf = !this.isNullOrEmptyString(options[KEY.CSRF]);
    const hasPersona = !this.isNullOrEmptyString(options[KEY.PERSONA]);

    const expiration = {};
    expiration[KEY.CSRF] = hasCsrf ? options[KEY.CSRF] : defaults[KEY.CSRF];
    expiration[KEY.PERSONA] = hasPersona ? options[KEY.PERSONA] : defaults[KEY.PERSONA];
    expiration[KEY.PERSONAS] = defaults[KEY.PERSONAS];

    this.set(expiration);

    const accountId = hasAccount ? options[KEY.ACCOUNT_ID] : this.$store.getters[`${SESSION}/${GET_VALUE}`](KEY.ACCOUNT_ID);
    this.clear(merge(
      { flushStore: { session: false, local: true } },
      options,
      { accountId },
    ));
    if (registeredCallbacks
      && registeredCallbacks[KEY.DESTROY]
      && typeof registeredCallbacks[KEY.DESTROY] === 'function') {
      registeredCallbacks[KEY.DESTROY]();
    }
  },

  hasDataEncryptionDataFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.DATA_ENCRYPTION);
  },

  hasAsyncMessagingFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.ASYNC_MESSAGING);
  },

  hasSLAPerSkillFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.SLA_PER_SKILL);
  },

  hasWrapUpTimeFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.WRAP_UP_TIME);
  },

  hasUserTypeBotFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.USER_TYPE_BOT);
  },

  hasConversationSourcesFeature() {
    return this.hasAsyncMessagingFeature()
      && this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.CONVERSATION_SOURCES);
  },

  hasMessagingAutoMessagesFeature() {
    return this.hasAsyncMessagingFeature()
      && this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.MESSAGING_AUTO_MESSAGES);
  },

  hasUnauthMessagingFeature() {
    return this.hasAsyncMessagingFeature()
      && this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.MESSAGING_UNAUTH_MESSAGING);
  },

  hasEditMessagingAutoMessagesFeature() {
    return this.hasAsyncMessagingFeature()
      && this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.MESSAGING_EDIT_AUTO_MESSAGES);
  },

  hasGoogleSourceFeature() {
    return this.hasConversationSourcesFeature()
      && this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.GOOGLE_SOURCE);
  },

  hasLpAuthenticationSupportFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.LIVEPERSON_AUTHENTICATION_METHOD);
  },

  hasLiveEngage2Feature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COMMON_LIVE_ENGAGE_2);
  },

  hasMigratingFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COMMON_LIVE_ENGAGE_2_MIGRATING);
  },

  hasAgentGroupsFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.AGENT_GROUPS);
  },

  hasVisitorFeedPreFiltering() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.VISITOR_FEED_PREFILTERING);
  },

  hasBlockAddingUsersFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COMMON_BLOCK_ADDING_USERS);
  },

  hasBlockSupportAccessFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COMMON_BLOCK_SUPPORT_ACCESS);
  },

  hasBlockMSTRAccessFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COMMON_BLOCK_MSTR);
  },

  hasApiKeyManagementFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COMMON_APIKEY_MANAGEMENT);
  },

  hasIpRestrictionFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COMMON_IP_RESTRICTION);
  },

  hasLEMyAccount() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.LE_UI_MYACCOUNT);
  },

  hasReportBuilderAccessFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.REPORT_BUILDER_ACCESS);
  },

  hasReportBuilderNonLpaAccessFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.REPORT_BUILDER_NON_LPA_ACCESS);
  },

  hasSeatBasedFeature() {
    return this.hasLiveEngage2Feature()
      && !this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COMMON_BILLING_CPI2);
  },

  isUnifiedWindowEnabled() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.UNIFIED_WINDOW);
  },

  hasLimitStickySizeFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.STICKY_SIZE_LIMIT);
  },

  hasHotTopicsFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.HOT_TOPICS);
  },

  hasAuthenticationChatFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.AUTHENTICATED_CHAT);
  },

  isLPA() {
    return this.$store.getters[`${SESSION}/${GET_VALUE}`](CONFIG_ATTRIBUTE_ID.LPA);
  },

  isAdmin() {
    return this.$store.getters[`${SESSION}/${GET_VALUE}`](CONFIG_ATTRIBUTE_ID.ADMIN);
  },

  hasMultiChatCenterLoadBalancing() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.MULTI_CHAT_SET_BALANCING);
  },

  hasCoBrowseFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COBROWSE_INTEGRATION);
  },

  hasMultiChatCenterCascadingLoadBalancing() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.MULTI_CHAT_CASCADING_SET_BALANCING);
  },

  hasLobFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.LOB_SUPPORT);
  },

  hasEntitiesTaggingByLobFeature() {
    return this.hasLobFeature()
      && this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.ENTITIES_TAGGING_BY_LOB);
  },

  hasProfilesFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.PROFILES_FEATURE);
  },

  hasConnectionBarCaseManagementVisible() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](
      COMPOUND_FEATURE_ID.LE_UI_CONNECTION_BAR_CASE_MANAGEMENT_VISIBLE,
    );
  },

  hasConnectionBarDisplay() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](
      COMPOUND_FEATURE_ID.LE_UI_CONNECTION_BAR_DISPLAY,
    );
  },

  hasConnectionBarOpenCaseVisible() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](
      COMPOUND_FEATURE_ID.LE_UI_CONNECTION_BAR_OPEN_CASE_VISIBLE,
    );
  },

  hasConnectionBarNotificationVisible() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](
      COMPOUND_FEATURE_ID.LE_UI_CONNECTION_BAR_NOTIFICATION_VISIBLE,
    );
  },

  hasLpaProfilesAssignmentFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.LPA_PROFILES_ASSIGNMENT_FEATURE);
  },

  hasAuditTrailFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.AUDIT_TRAIL_FEATURE);
  },

  hasMultipleAgentStatesFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.MULTIPLE_AGENT_STATES);
  },

  hasConfigForMaximumCustomAwayStatus() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.CONFIG_MAXIMUM_CUSTOM_AWAY_STATUS);
  },

  hasRefreshButtonFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.REFRESH_BUTTON);
  },

  hasAgentTimer() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.AGENT_STATUS_TIMER);
  },

  hasLiveChatQueuePriorityFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.LIVE_CHAT_QUEUE_PRIORITY);
  },

  hasContentProviderAccountFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.CONTENT_PROVIDER_ACCOUNT_FEATURE);
  },

  hasMessagingSurveyFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.MESSAGING_SURVEY);
  },

  hasMessagingTransferToAgentFeature() {
    return this.hasAsyncMessagingFeature()
      && this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.MESSAGING_TRANSFER_TO_AGENT);
  },

  hasAutoClosePerSkillFeature() {
    return this.hasAsyncMessagingFeature()
      && this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.MESSAGING_AUTO_CLOSE_PER_SKILL);
  },

  hasAutomationAreaFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.AUTOMATION_AREA);
  },
  hasAIAssistWidgetFeature() {
    return this.$store.getters[`${SESSION}/${GET_FEATURE_STATE}`](COMPOUND_FEATURE_ID.COMMON_AI_ASSIST_WIDGET);
  },
};

export default sessionModelObj;
