// sentryConstants.js

// Default values for environment and version
const DEFAULT_ENVIRONMENT = 'unknown-environment';
const DEFAULT_VERSION = 'unknown-version';
const DEFAULT_USER_ID = 'unknown-user';
const DEFAULT_ACCOUNT_ID = 'unknown-account';

const DEFAULT_SAMPLING = 0;
const EXTRA_KEY = 'ROUTE_TO';
const ENDPOINTS = {
  aw: 'NewAgentWorkspace',
  wa: 'AgentWorkspaceForChat',
  botPlatform: 'ThirdPartyBots',
  intentManager: 'IntentManager',
  botBuilder: 'ConversationBuilder',
  knowledgeBase: 'KnowledgeAI',
  conversationAssist: 'ConversationAssist',
  amd: 'ManagerWorkspace',
  analyze: 'IntentAnalyzer',
  users: 'UsersSkills',
  camp: 'CampaignBuilder',
  ihu: 'IntegrationHub',
  faasui: 'Functions',
  maven: 'ConversationOrchestrator',
  performance: 'Performance Optimizer',
  mcs: 'MCSToolkit',
};
const DEFAULT_ENDPOINT = 'le-ui';
// Regular expression for trace propagation
const TRACE_PROPAGATION_TARGETS = [/^https:\/\/z(1-a|1|2|3)\.le\.liveperson\.net\/.*$/];

const SENTRY_SITE_SETTING = {
  SENTRY_SAMPLE_RATE: 'sentry.sampleRate',
  SENTRY_TRACES_SAMPLE_RATE: 'sentry.tracesSampleRate',
  SENTRY_PROFILES_SAMPLE_RATE: 'sentry.profilesSampleRate',
};

const SENTRY_AC_FEATURE = {
  SENTRY_IS_ENFORCED_SAMPLING: 'sentry.isEnforcedSampling',
};

const SENTRY_RATE = {
  SAMPLE_RATE: 'sampleRate',
  TRACES_SAMPLE_RATE: 'tracesSampleRate',
  PROFILES_SAMPLE_RATE: 'profilesSampleRate',
};

const AUTH_SYSTEM_KEYS = {
  SITE_SETTING: 'le.auth.login.system',
};

// Exporting all constants
export {
  DEFAULT_ENVIRONMENT,
  DEFAULT_VERSION,
  DEFAULT_USER_ID,
  DEFAULT_ACCOUNT_ID,
  DEFAULT_SAMPLING,
  ENDPOINTS,
  DEFAULT_ENDPOINT,
  EXTRA_KEY,
  TRACE_PROPAGATION_TARGETS,
  SENTRY_SITE_SETTING,
  SENTRY_RATE,
  SENTRY_AC_FEATURE,
  AUTH_SYSTEM_KEYS,
};
