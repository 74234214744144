import { bind } from 'lodash-es';
import gChannel from '../lib/gChannel';
import COMMON_CONSTANTS from '../assets/common-constants-ids.json';

function isAuthenticated() {
  /* jshint validthis:true */
  return this.model.isValidSession();
}

function establish(creds, options) {
  /* eslint-disable no-param-reassign */
  options = options || {};

  const { success } = options;
  const { error } = options;

  /* jshint validthis:true */
  options.success = bind((model, resp, ...args) => {
    // Do something and than call handler

    // For hacking multi-tab support
    // Remove All accounts except the account we just logged in to from the correct accounts list
    // TODO: Remove when MultiTab support should be enabled
    /* var accountIds = this.model.getCurrentAccountIds();
    if (accountIds && 1 < accountIds.length) {
        var validAccountId = this.getAccountId();
        each(accountIds, function (accountId, index) {
            if (validAccountId !== accountId) {
                this.invalidateAccountSession(accountId, true);
            }
        });
    } */
    this.logger.debug(`Session had been established successfully for account ${options.accountId}`);

    // Kick start the keep alive
    this.logger.debug('Kick start the keep alive');
    this.startKeepAlive();

    if (success) {
      gChannel.trigger(COMMON_CONSTANTS.INFRA_CHANNEL, COMMON_CONSTANTS.ON_AUTHENTICATED);
      success.apply(this, [].slice.apply(args));
    }
  }, this);
  options.error = bind((model, resp, ...args) => {
    this.logger.error('Could not establish session with LE server!!!');
    this.logger.dir(resp);
    this.logger.graph({ name: 'framework:sessionManager:Could not establish session with LE server' });

    // Do something and than call handler
    if (error) {
      error.apply(this, [].slice.apply(args));
    }

    this.expire.call(this);
  }, this);

  // Do a POST to /session and send the serialized form creds
  this.model.save(creds, options);

  // Allow Chaining
  return this;
}

export default {
  isAuthenticated,
  establish,
};
