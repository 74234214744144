import * as vueExports from 'vue';
import { useRouter } from 'vue-router';
import Vuex from 'vuex';
import { store } from './store';
import ACClient from './api-layer/ACClient';
import BaseClient, {
  setLogger as setBaseClientLogger,
  setSessionManager as setBaseClientSessionManager,
} from './api-layer/BaseClient';
import RecipeBaseClient from './api-layer/RecipeBaseClient';
import apiLayerUtils from './api-layer/apiLayerUtils';
import APILayerConsts from './api-layer/consts';
import EventEmitter from './lib/EventEmitter';
import createBaseRouter from './base/BaseVueRouter';
import FrameworkPlugin from './plugin/FrameworkPlugin';
import * as sessionKeys from './auth/session/keys';
import lpStorage from './lib/lpStorage';
import lpCookie from './lib/lpCookie';
import * as infraMutationsTypes from './store/infra/mutation-types';
import * as sessionGetterTypes from './store/modules/session/getter-types';
import * as sessionActionTypes from './store/modules/session/action-types';
import { SESSION_STATE_KEY as sessionStateKeys } from './store/modules/session/keys';
import * as environmentActionTypes from './store/modules/environment/actionTypes';
import * as environmentGetterTypes from './store/modules/environment/getterTypes';
import * as environmentMutationTypes from './store/modules/environment/mutationTypes';
import {
  AGENT_STATUS_DATA_STATES,
  ENVIRONMENT_STATE_KEY as environmentStateKeys,
} from './store/modules/environment/keys';
import * as storeModuleTypes from './store/types';
import gChannel from './lib/gChannel';
import TransportLayer from './auth/transportLayer/TransportLayer';
import Logger from './logger/logger';
import LoggerConstants from './logger/Const';
import ConsoleAppender from './logger/appenders/ConsoleAppender';
import RemoteLoggosAppender from './logger/appenders/RemoteLoggosAppender';
import AbstractAppender from './logger/appenders/AbstractAppender';
import RemoteLoggosBamAppender from './logger/appenders/RemoteLoggosBamAppender';
import commonUtils from './utils';
import sessionManager from './auth/session/sessionManager';
import sessionModel from './auth/session/sessionModel';
import CustomRingsManager from './customRings/CustomRingsManager';
import TranslatorVue3Plugin from './manager/TranslatorVue3Plugin';
import packageInfo from '../package.json';
import COMMON_CONSTANTS from './assets/common-constants-ids.json';
import ACCOUNT_SETTINGS_IDS from './assets/account-settings-ids.json';
import COMPOUND_FEATURES_IDS from './assets/compound-features-ids.json';
import PRIVILEGES from './assets/privileges.json';
import { registerNetworkLogger } from './logger/appenders/NetworkAppender';
import SentryAppender from './logger/appenders/sentryAppender';
import SentryInitializer from './sentry/SentryInitializer';
import i18n from './manager/i18n';

sessionManager.setStore(store);
TransportLayer.setStore(store);
TransportLayer.setLogger(Logger);
lpStorage.setLogger(Logger);
setBaseClientLogger(Logger);
setBaseClientSessionManager(sessionManager);

const { version } = packageInfo;

window.addEventListener('load', registerNetworkLogger);

export { vueExports };
export { Vuex };
export { store };
export { createBaseRouter, useRouter };
export { FrameworkPlugin };
export { sessionModel };
export { lpStorage };
export { lpCookie };
export { sessionGetterTypes };
export { sessionActionTypes };
export { sessionKeys };
export { sessionStateKeys };
export { environmentActionTypes };
export { environmentGetterTypes };
export { infraMutationsTypes };
export { environmentMutationTypes };
export { environmentStateKeys };
export { AGENT_STATUS_DATA_STATES };
export { storeModuleTypes };
export { gChannel };
export { TransportLayer };
export { sessionManager };
export { CustomRingsManager };
export { Logger };
export { LoggerConstants };
export { ConsoleAppender };
export { RemoteLoggosAppender };
export { RemoteLoggosBamAppender };
export { AbstractAppender };
export { COMMON_CONSTANTS };
export { ACCOUNT_SETTINGS_IDS };
export { COMPOUND_FEATURES_IDS };
export { PRIVILEGES };
export { commonUtils };
export { ACClient };
export { BaseClient };
export { RecipeBaseClient };
export { APILayerConsts };
export { EventEmitter };
export { i18n };
export { TranslatorVue3Plugin };
export { version };
export { apiLayerUtils };
export { registerNetworkLogger };
export { SentryAppender };
export { SentryInitializer };
