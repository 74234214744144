import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import { getLogger } from '../logger/logger';

const logger = getLogger('vue-infra');

/**
 * i18n Utility
 * - Manages i18next initialization and translation retrieval via 'init' at startup.
 * - Simplifies i18next plugin installation with the 'install' method.
 *
 * Usage:
 * - In Vue templates: `this.$t('key')`
 * - In JavaScript files: `i18n.t('key')` and `i18n.getFixedT('key')`
 *
 * @property {object} i18next - The core i18next instance.
 * @property {function|null} t - Retrieves translations using i18next.
 * @property {function|null} getFixedT - Returns a fixed translation function.
 */
const i18n = {
  i18next,
  t: null,
  getFixedT: null,

  /**
   * Initializes the i18next instance with given options and sets up translation methods.
   *
   * @param {object} options - i18next initialization options.
   */
  init(options) {
    return i18next.init(options)
      .then((t) => {
        this.t = (...args) => t(...args);
        this.getFixedT = (...args) => i18next.getFixedT(...args);
      })
      .catch((e) => {
        logger.error('i18next initialization error:', e);
      });
  },

  /**
   * Vue plugin installation method.
   * Automatically integrates i18next with Vue using the I18NextVue plugin..
   *
   * @param {object} app - Vue 3 app instance.
   */
  install(app) {
    app.use(I18NextVue, { i18next });
  },
};

export default i18n;
