import { PERSONALIZATION_WIDGET } from './types';

export const SET_VALUE = 'setValue';
export const MODULES_UPDATE = 'modulesUpdate';
export const UPDATE_REGIONS = 'setModuleRegions';
export const RESET_STATE = 'resetState';
export const PERSONALIZATION_UPDATE = 'personalizationUpdate';
export const CURRENT_PERSONA_UPDATE = 'currentPersonaUpdate';
export const OUTER_ROUTE_UPDATE = 'outerRouteUpdate';
export const SET_CURRENT_AGENT_STATUS = 'setCurrentAgentStatus';
export const SET_MESSAGING_AGENT_STATUS = 'setMessagingAgentStatus';
export const SET_CHAT_AGENT_STATUS = 'setChatAgentStatus';
export const REQUEST_AGENT_STATUS_CHANGE = 'requestAgentStatusChange';
export const SET_BRAND_PROFILE = 'setBrandProfile';
export const UPDATE_CUSTOM_AWAY_REASONS = 'updateCustomAwayReasons';
export const UPDATE_QUICK_LAUNCH_COLLECTION = 'updateQuickLaunchCollection';
export const TOP_BAR_NOTIFICATION_UPDATE = 'topBarNotificationUpdate';
export const TOP_BAR_NOTIFICATION_REMOVE = 'topBarNotificationRemove';
export const VALID_APPS_UPDATE = 'validAppsUpdate';
export const ROUTES_UPDATE = 'routesUpdate';
export const PERSONA_NOTIFICATION_UPDATE = 'personaNotificationUpdate';
export const LOGGED_IN_USER_DETAILS_UPDATE = 'loggedInUserDetailsUpdate';
export const UPDATE_AGENT_TIMER = 'updateAgentTimer';
export const UPDATE_AGENT_STATUS_DATA_STATE = 'updateAgentStatusChange';

export const PERSONALIZATION_WIDGET_IS_REPORTING_OPEN_UPDATE = `${PERSONALIZATION_WIDGET}/isReportingOpenUpdate`;
export const PERSONALIZATION_WIDGET_IS_OPEN_TOGGLE = `${PERSONALIZATION_WIDGET}/isOpenToggle`;
export const PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT_UPDATE = `${PERSONALIZATION_WIDGET}/featureByRoutCountUpdate`;
export const PERSONALIZATION_WIDGET_IS_OPEN_UPDATE = `${PERSONALIZATION_WIDGET}/isOpenUpdate`;
export const PERSONALIZATION_WIDGET_PAGE_UPDATE = `${PERSONALIZATION_WIDGET}/pageUpdate`;
