import { getLogger } from '../../logger/logger';
import {
  INFRA_ADD_TO_ARRAY_BY_POSITION,
  INFRA_CLEAR_ARRAY,
  INFRA_CONCAT_ARRAY,
  INFRA_PUSH_TO_ARRAY,
  INFRA_RESET_STATE,
  INFRA_SET_ATTRIBUTE,
} from './mutation-types';

const logger = getLogger('vue-infra');

const mutations = {
  [INFRA_SET_ATTRIBUTE](state, payload) {
    if (Object.prototype.hasOwnProperty.call(state, payload.attribute)) {
      // eslint-disable-next-line no-param-reassign
      state[payload.attribute] = payload.value;
    } else {
      const error = `session store mutation ${INFRA_SET_ATTRIBUTE}, ${payload.attribute} attribute not exist in state`;
      logger.error(error);
      throw new Error(error);
    }
  },
  [INFRA_CONCAT_ARRAY](state, payload) {
    if (!Object.prototype.hasOwnProperty.call(state, payload.attribute)) {
      const error = `infra store mutation ${INFRA_CONCAT_ARRAY}, ${payload.attribute} attribute not exist in state`;
      logger.error(error);
      throw new Error(error);
    } else if (!Array.isArray(state[payload.attribute])) {
      const error = `infra store mutation ${INFRA_CONCAT_ARRAY}, ${payload.attribute} attribute not an array`;
      logger.error(error);
      throw new Error(error);
    } else {
      state[payload.attribute].push(...payload.value);
    }
  },
  [INFRA_PUSH_TO_ARRAY](state, payload) {
    if (!Object.prototype.hasOwnProperty.call(state, payload.attribute)) {
      const error = `infra store mutation ${INFRA_PUSH_TO_ARRAY}, ${payload.attribute} attribute not exist in state`;
      logger.error(error);
      throw new Error(error);
    } else if (!Array.isArray(state[payload.attribute])) {
      const error = `infra store mutation ${INFRA_PUSH_TO_ARRAY}, ${payload.attribute} attribute not an array`;
      logger.error(error);
      throw new Error(error);
    } else {
      state[payload.attribute].push(payload.value);
    }
  },
  [INFRA_ADD_TO_ARRAY_BY_POSITION](state, payload) {
    if (!Object.prototype.hasOwnProperty.call(state, payload.attribute)) {
      const error = `infra store mutation ${INFRA_ADD_TO_ARRAY_BY_POSITION}, ${payload.attribute} attribute not exist in state`;
      logger.error(error);
      throw new Error(error);
    } else if (!Array.isArray(state[payload.attribute])) {
      const error = `infra store mutation ${INFRA_ADD_TO_ARRAY_BY_POSITION}, ${payload.attribute} attribute not an array`;
      logger.error(error);
      throw new Error(error);
    } else {
      state[payload.attribute].splice(payload.pos, 0, payload.value);
    }
  },
  [INFRA_CLEAR_ARRAY](state, payload) {
    if (!Object.prototype.hasOwnProperty.call(state, payload.attribute)) {
      const error = `infra store mutation ${INFRA_CLEAR_ARRAY}, ${payload.attribute} attribute not exist in state`;
      logger.error(error);
      throw new Error(error);
    } else if (!Array.isArray(state[payload.attribute])) {
      const error = `infra store mutation ${INFRA_CLEAR_ARRAY}, ${payload.attribute} attribute not an array`;
      logger.error(error);
      throw new Error(error);
    } else {
      // eslint-disable-next-line no-param-reassign
      state[payload.attribute].length = 0;
    }
  },
  [INFRA_RESET_STATE](state, defaultState) {
    Object.assign(state, defaultState);
  },
};

export default mutations;
