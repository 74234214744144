const LP_LOGGER_LEVELS_ERROR = 'error';
const LP_LOGGER_LEVELS_FATAL = 'fatal';
const LP_LOGGER_LEVELS_WARN = 'warn';
const LP_LOGGER_LEVELS_WARNING = 'warning';
const LP_LOGGER_LEVELS_INFO = 'info';
const LP_LOGGER_LEVELS_INFOTAM = 'infotam';
const LP_LOGGER_LEVELS_DEBUG = 'debug';
const LP_LOGGER_LEVELS_METRICS = 'metrics';
const LP_LOGGER_LEVELS_GRAPH = 'graph';
const LP_LOGGER_LEVELS_LOG = 'log';
const SENTRY_APPENDER = 'SentryAppender';

const LP_LOGGER_LEVELS = {
  [LP_LOGGER_LEVELS_ERROR]: 1,
  [LP_LOGGER_LEVELS_WARN]: 2,
  [LP_LOGGER_LEVELS_INFO]: 3,
  [LP_LOGGER_LEVELS_INFOTAM]: 3,
  [LP_LOGGER_LEVELS_DEBUG]: 4,
  [LP_LOGGER_LEVELS_METRICS]: 4,
  [LP_LOGGER_LEVELS_GRAPH]: 4,
};
const SENTRY_LOG_LEVELS = {
  [LP_LOGGER_LEVELS_ERROR]: 1,
  [LP_LOGGER_LEVELS_FATAL]: 1,
  [LP_LOGGER_LEVELS_WARNING]: 2,
  [LP_LOGGER_LEVELS_INFO]: 3,
  [LP_LOGGER_LEVELS_LOG]: 3,
  [LP_LOGGER_LEVELS_DEBUG]: 4,
};

const SENTRY_OPTION_PROPERTY_NOT_APPLICABLE = 'N/A';

export {
  LP_LOGGER_LEVELS,
  SENTRY_LOG_LEVELS,
  SENTRY_APPENDER,
  LP_LOGGER_LEVELS_ERROR,
  LP_LOGGER_LEVELS_FATAL,
  LP_LOGGER_LEVELS_WARN,
  LP_LOGGER_LEVELS_WARNING,
  LP_LOGGER_LEVELS_INFO,
  LP_LOGGER_LEVELS_INFOTAM,
  LP_LOGGER_LEVELS_DEBUG,
  LP_LOGGER_LEVELS_METRICS,
  LP_LOGGER_LEVELS_GRAPH,
  LP_LOGGER_LEVELS_LOG,
  SENTRY_OPTION_PROPERTY_NOT_APPLICABLE,
};
