import CustomRings from './CustomRings';

const playerMap = {};
export default class CustomRingsManager {
  static getCustomRings(options) {
    const key = options && options.standalone ? 'true' : 'false';
    if (!playerMap[key]) {
      playerMap[key] = new CustomRings(options);
    }
    return playerMap[key];
  }

  static getMaxIndex() {
    return CustomRings.getMaxIndex();
  }

  static resetIndex() {
    if (playerMap.true) {
      playerMap.true.resetIndex();
    }
    if (playerMap.false) {
      playerMap.false.resetIndex();
    }
  }
}
