export const SESSION_INIT = 'setSession';
export const SET_DEFAULT_VALUE = 'setDefaultValue';
export const SET_USER_PRIVILEGES = 'setUserPrivileges';
export const SET_FEATURES = 'setFeatures';
export const SETTINGS_DATA = 'setSettingsData';
export const SET_AGENT_GROUPS_DATA = 'setAgentGroupsData';
export const ADD_PERSONA_TO_ARRAY = 'addPersonaToList';
export const CONCAT_PERSONAS_TO_ARRAY = 'addPersonasToList';
export const CLEAR_PERSONAS = 'clearPersona';
export const ADD_PERSONA_TO_ARRAY_BY_POSITION = 'addPersonaToListByPosition';
export const SET_VALUE = 'setValue';
export const RESET_STATE = 'resetState';
