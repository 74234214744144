import { extend } from '../../utils';
import CONST from '../Const';
import AbstractAppender from './AbstractAppender';

const lpTag = window.lpTag || {};

const RemoteLoggosBamAppender = AbstractAppender.extend({
  defaultStrategy: {
    enabled: true,
    logLevel: CONST.LOG_LEVEL.BAM,

    loggerLevels: { // Override logger levels to put metrics and graph in the same level as debug
      bam: 0,
    },
    cacheMaxSize: 2000,
    buffer: { // size of buffer logs if more then 100 then it push the log to the transport layer.
      itemsMax: 5,
      duration: 3000,
      interval: 1000,
    },
    throttle: { // allow 1000 logs in 1sec
      items: 1000,
      duration: 20000,
      interval: 1000,
    },
  },
  appenderType() {
    return 'RemoteLoggosBamAppender';
  },
  getStoredLogId() {
    return this.getStoredItem('LogId');
  },
  storeLogId() {
    if (this.getLogId()) {
      this.storeItem('LogId', this.getLogId());
    }
  },
  getFailureCounter() {
    return this.failureCounter;
  },
  /**
   * @param options
   *  options.ws - loggos server ws url.
   *  options.rest - loggos server rest url.
   */
  initialize(options) {
    /* eslint-disable no-param-reassign */

    options = options || {};

    if (options.rest && typeof options.rest === 'string') {
      this.restURL = options.rest;
    }

    AbstractAppender.prototype.initialize.call(this, options);

    this.buffer = [];
    this.bamObject = {};
    this.keysNamespace = {};
    this.failureCounter = 0;

    this.setLogId(this.getStoredLogId());
    this.initThrottleCheck();
    this.initBufferCheck();
  },
  /* eslint-disable prefer-arrow-callback */
  initThrottleCheck() {
    if (this.throttleInterval) {
      clearInterval(this.throttleInterval);
    }
    this.tick = new Date().getTime();
    this.count = 0;

    this.throttleInterval = setInterval(function func() {
      if (this.currentStrategy.throttle.duration <= new Date().getTime() - this.tick) {
        this.tick = new Date().getTime();
        this.count = 0;
      }
    }.bind(this), this.currentStrategy.throttle.interval);
  },
  /* eslint-disable prefer-arrow-callback */
  initBufferCheck() {
    if (this.bufferInterval) {
      clearInterval(this.bufferInterval);
    }
    this.buff = new Date().getTime();
    this.bufferInterval = setInterval(function func() {
      if (this.buffer.length
        && this.currentStrategy.buffer.duration <= new Date().getTime() - this.buff) {
        this.publishLogs();
      }
    }.bind(this), this.currentStrategy.buffer.interval);
  },
  isEnabled() {
    // check if the current strategy enables this appender
    return this.currentStrategy.enabled && (this.count < this.currentStrategy.throttle.items);
  },
  escapeText(text) {
    text = (text && text.toString()) || '';

    return text.replace(/&/g, '&#38;')
      .replace(/</g, '&#60;')
      .replace(/>/g, '&#62;')
      .replace(/'/g, '&#39;')
      .replace(/'/g, '&#34;');
  },
  getCustomData() {
    return {
      // You can remove & add custom data here from session/
      // localStorage, cookies, geolocation, language, mimetypes;
      referrer: this.escapeText(document.referrer),
      userAgent: this.escapeText(navigator.userAgent),
      platform: this.escapeText(navigator.platform),
      vendor: this.escapeText(navigator.vendor),
      language: this.escapeText(navigator.language),
      // eslint-disable-next-line no-restricted-globals
      screenWidth: this.escapeText(screen.width),
      // eslint-disable-next-line no-restricted-globals
      screenHeight: this.escapeText(screen.height),
      // accountId: UrlResolver.getUrlAccountId(),
      logId: this.getLogId(),
    };
  },
  buildLogsRequest(buffer) {
    const logId = this.getLogId();

    return buffer.map((item) => {
      if (typeof item === 'object' && logId) {
        item.logId = logId;
      }
      return item;
    });
  },
  setLogId(logId) {
    if (logId && !this.logId) {
      this.logId = logId;

      this.storeLogId();
    }
  },
  getLogId() {
    return this.logId;
  },
  configureTransports() {
    let configured = false;
    const conf = {
      server: this.wsURL,
      onlogid: this.setLogId.bind(this),
    };

    if (lpTag && lpTag.taglets && lpTag.taglets.loggosbamtransport) {
      configured = lpTag.taglets.loggosbamtransport.configure(conf);
    }

    return configured;
  },
  publishLogs() {
    let logs;
    let limit;

    // Check needed dependencies
    if (lpTag && lpTag.taglets && lpTag.taglets.lpAjax
      && lpTag.taglets.loggosbamtransport && lpTag.taglets.postmessage) {
      logs = this.buildLogsRequest(this.buffer);
      this.buffer.length = 0;

      this.initBufferCheck();

      if (!this.loggosTransportsConfigured) {
        this.loggosTransportsConfigured = this.configureTransports();
      }

      this.sendLogs(logs);
    } else if (this.currentStrategy.buffer
      && this.currentStrategy.buffer.itemsMax && this.buffer.length) {
      limit = this.currentStrategy.cacheMaxSize || this.currentStrategy.buffer.itemsMax;

      while (limit < this.buffer.length) {
        this.buffer.shift();
      }
    }
  },

  /* eslint-disable global-require */
  sendLogs(logs) {
    const sessionManager = require('../../auth/session/sessionManager').default;
    const glob = sessionManager.getGlob();
    const conf = {
      server: this.restURL,
      onlogid: this.setLogId.bind(this),
    };
    // Use the appName you are currently from the module activeModuleName
    // activeModuleName is controlled by LEUI and has custom settings for VUE and non VUE modules
    // ^^ LEUI: LiveEngageLayoutController.js (non vue), PersonalizationController.js (VUE)
    // restURL is also controlled by LEUI apiResources.json
    const appName = (!window.LE || !window.LE.context || !window.LE.context.attributes || !window.LE.context.attributes.activeModuleName) ? 'CCUI' : window.LE.context.attributes.activeModuleName;
    conf.server = conf.server.replace(/(appName=)[^&]+/, `$1${appName}`);

    lpTag.taglets.lpAjax.issueCall({
      transportOrder: ['postmessage'],
      url: conf.server,
      data: logs,
      method: 'POST',
      headers: {
        AUTHORIZATION: `Bearer ${glob}`,
      },
      success(data) {
        if (data && data.responseCode >= 400) {
          this.failureCounter += 1;
        } else if (data && data.body && data.body.logId) {
          conf.onlogid(data.body.logId);
        }
      },
      error() {
        this.failureCounter += 1;
      },
    });
  },
  /* eslint-disable global-require */
  async logMsg(options) {
    const sessionManager = require('../../auth/session/sessionManager').default;
    options = options || {};

    let appName;
    appName = options && options.obj && options.obj.appName ? options.obj.appName : undefined;
    appName = appName || options.appName;
    // eslint-disable-next-line max-len
    appName = appName || (window.LE && window.LE.context && window.LE.context.attributes && window.LE.context.attributes.activeModuleName ? window.LE.context.attributes.activeModuleName : undefined);
    appName = appName || 'CCUI';

    const { immediate } = options;
    if ((!this.currentStrategy.filters || !this.currentStrategy.filters(options))
      && options.logLevel === CONST.LOG_LEVEL.BAM) {
      const sessionInfo = {
        url: window.location.href,
        userId: sessionManager.getUserId(),
        roles: sessionManager.getRolesLost(),
        appName,
        phase: sessionManager.getAccountSettingValueByID('le.site.leui.phase'),
      };
      if (typeof (options.obj) === 'object') {
        extend(options.obj, this.bamObject, true);
        extend(options, this.bamObject, true);
        extend(sessionInfo, this.bamObject, true);
        delete this.bamObject.obj;
        if (Object.prototype.hasOwnProperty.call(this.bamObject, 'immediate')) {
          delete this.bamObject.immediate;
        }
        if (Object.prototype.hasOwnProperty.call(this.bamObject, 'trace')) {
          delete this.bamObject.trace;
        }
        this.buffer.push(JSON.parse(JSON.stringify(this.bamObject)));
        this.bamObject = {};
        this.count += 1;
        if (immediate === true
          || (this.buffer.length && this.currentStrategy.buffer.itemsMax <= this.buffer.length)) {
          this.publishLogs();
        }
      }
    }
  },
});

export default RemoteLoggosBamAppender;
