/* eslint-disable arrow-body-style */

import {
  CURRENT_AGENT_STATUS,
  REQUEST_AGENT_STATUS_CHANGE,
  AGENT_STATUS_DATA_STATE,
  CURRENT_PERSONA,
  MODULES,
  PERSONA_NOTIFICATION,
  PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT,
  PERSONALIZATION_WIDGET_IS_OPEN,
  PERSONALIZATION_WIDGET_PAGE,
  REGIONS,
  RESOLVE_BRAND_PROFILE,
  RESOLVE_CUSTOM_AWAY_REASONS,
  RESOLVE_QUICK_LAUNCH_APPS,
  RESOLVE_LE_TRAIL_INFORMATION,
  RESOLVE_MODULE_NAME,
  RESOLVE_MODULE_NAME_BY_PREFIX,
  RESOLVE_MODULE_ROUTE_PREFIX,
  RESOLVE_MODULES,
  RESOLVE_PERSONA,
  RESOLVE_PERSONA_BY_PREFIX,
  RESOLVE_SOUND_ENABLE,
  ROUTES,
  TOP_BAR_NOTIFICATION,
  VALID_APPS,
  LOGGED_IN_USER_DETAILS,
  CURRENT_ACTIVE_THEME,
  CURRENT_RING,
  AGENT_TIMER,
  CURRENT_MESSAGING_AGENT_STATUS,
  CURRENT_CHAT_AGENT_STATUS,
} from './getterTypes';
import sessionManager from '../../../auth/session/sessionManager';

const getters = {};
getters[MODULES] = (state) => state.modules;
getters[REGIONS] = (state) => state.regions;
getters[ROUTES] = (state) => state.routes;
// eslint-disable-next-line max-len
getters[PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT] = (state) => state.personalization.featuresByRoutCount;
getters[PERSONALIZATION_WIDGET_IS_OPEN] = (state) => state.personalization.isOpen;
getters[PERSONALIZATION_WIDGET_PAGE] = (state) => state.personalization.page;
getters[RESOLVE_CUSTOM_AWAY_REASONS] = (state) => state.customAwayReasons;
getters[RESOLVE_QUICK_LAUNCH_APPS] = (state) => state.quickLaunchApps;
getters[RESOLVE_SOUND_ENABLE] = (state) => state.leSoundEnable;
getters[RESOLVE_LE_TRAIL_INFORMATION] = (state) => state.trailInformation;
getters[CURRENT_AGENT_STATUS] = (state) => state.currentAgentStatus;
getters[CURRENT_MESSAGING_AGENT_STATUS] = (state) => state.currentMessagingAgentStatus;
getters[CURRENT_CHAT_AGENT_STATUS] = (state) => state.currentChatAgentStatus;
getters[REQUEST_AGENT_STATUS_CHANGE] = (state) => {
  return state.requestAgentStatusChange;
};
getters[AGENT_STATUS_DATA_STATE] = (state) => {
  return state.agentStatusDataState;
};
getters[RESOLVE_BRAND_PROFILE] = (state) => state.brandProfile;
getters[TOP_BAR_NOTIFICATION] = (state) => state[TOP_BAR_NOTIFICATION];
getters[VALID_APPS] = (state) => state[VALID_APPS];
getters[CURRENT_PERSONA] = (state) => state[CURRENT_PERSONA];
getters[PERSONA_NOTIFICATION] = (state) => state[PERSONA_NOTIFICATION];
getters[LOGGED_IN_USER_DETAILS] = (state) => state[LOGGED_IN_USER_DETAILS];
getters[CURRENT_ACTIVE_THEME] = (state) => state[CURRENT_ACTIVE_THEME];
getters[CURRENT_RING] = (state) => state[CURRENT_RING];
getters[AGENT_TIMER] = (state) => state[AGENT_TIMER];
getters[RESOLVE_PERSONA] = ((state) => (moduleName) => {
  return state.modules.personaMap && state.modules.personaMap[moduleName] ? state.modules.personaMap[moduleName] : '';
});

getters[RESOLVE_PERSONA_BY_PREFIX] = ((state) => (prefix) => {
  return state.modules.prefixMap && state.modules.prefixMap[prefix] ? state.modules.prefixMap[prefix] : '';
});

getters[RESOLVE_MODULES] = ((state) => (persona) => {
  /* eslint-disable no-param-reassign */
  if (!persona) {
    persona = sessionManager.getActivePersona();
  }
  return state.modules.moduleNames && state.modules.moduleNames[persona]
    ? state.modules.moduleNames[persona].list : [];
});

getters[RESOLVE_MODULE_NAME] = ((state) => (persona) => {
  /* eslint-disable no-param-reassign */
  if (!persona) {
    persona = sessionManager.getActivePersona();
  }
  return state.modules.moduleNames && state.modules.moduleNames[persona] ? state.modules.moduleNames[persona].moduleMapping : '';
});

getters[RESOLVE_MODULE_NAME_BY_PREFIX] = ((state) => (prefix) => {
  const persona = getters.resolvePersonaByPrefix(prefix);
  if (persona && persona !== '') {
    return getters.resolveModuleName(persona);
  }

  return state.modules.prefixMap && state.modules.prefixMap[prefix] ? state.modules.prefixMap[prefix] : '';
});

getters[RESOLVE_MODULE_ROUTE_PREFIX] = ((state) => (persona) => {
  let moduleRoutePrefix = '';
  if (!persona) {
    persona = sessionManager.getActivePersona();
  }
  if (state.modules.moduleNames && state.modules.moduleNames[persona]) {
    moduleRoutePrefix = state.modules.moduleNames[persona].routePrefix;
  } else {
    state.modules.moduleNames.forEach((name) => {
      // there are modules that doesn't have persona(personaMapping -
      // LEManager/LEContent ...) and let say
      // the url was saved like:
      // https://omerh-mac.dev.lprnd.net/a/qa42119490/#mng!data/list (bookmark copy paste)
      // our application is building the url to the AppServer like:
      // https://hc1/hc/web/public/pub/ma/lp/login.jsp?lpservice=liveEngage&servicepath=
      // a%2F~~accountid~~%2F%23%2C~~ssokey~~%3Bmng!data/list
      // mng - is acting like "persona"
      // if the "persona" is equal to the routePrefix is the case for the below scenario
      if (state.modules.moduleNames[name].routePrefix === persona) {
        moduleRoutePrefix = persona;
      }
    });
  }
  return moduleRoutePrefix;
});

export default getters;
