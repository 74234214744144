/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */

import { HEADERS } from './consts';
import { isEmptyObject, arrayToMapById } from '../utils';

const DEFAULT_ID = 'id';
const defaultSourceApplication = 'ccui'; // conversational cloud ui
const defaultContextName = '';
const defaultAppName = '';

/**
 * Enrich request data with AC headers.
 * Returns the passed request instance, for easy usage within interceptors.
 * @param {object} request - an axios request object.
 */
function buildHeaders(request) {
  if (!request) {
    return undefined;
  }
  if (!request.headers) {
    request.headers = {};
  }

  if (request.revision === undefined) {
    request.headers[HEADERS.IF_MATCH_HEADER] = -1;
  } else {
    request.headers[HEADERS.IF_MATCH_HEADER] = request.revision;
  }

  if (request.headers[HEADERS.LAST_MODIFIED_HEADER] === undefined
    && request[HEADERS.LAST_MODIFIED_HEADER]) {
    request.headers[HEADERS.LAST_MODIFIED_HEADER] = request[HEADERS.LAST_MODIFIED_HEADER];
  }

  return request;
}

/**
 * calculate meta data from the response data.
 * @param {Object} response - The response data.
 */
function calculateMeta(response) {
  const tempMeta = {};
  let meta;
  if (response) {
    if (response.headers[HEADERS.LAST_MODIFIED_HEADER] !== undefined) {
      tempMeta.lastModified = response.headers[HEADERS.LAST_MODIFIED_HEADER];
    }
    if (response.headers[HEADERS.AC_REVISION] !== undefined) {
      tempMeta.revision = response.headers[HEADERS.AC_REVISION];
    } else if (response.headers[HEADERS.E_TAG] !== undefined) {
      tempMeta.revision = response.headers[HEADERS.E_TAG];
    }
  }

  if (!isEmptyObject(tempMeta)) {
    meta = { ...tempMeta };
  }
  return meta;
}

/**
 * Expand response data with meta data and map the response list by id.
 *
 * @param {Object} response - The response data.
 * @param {String} mapIdName - The list id name (default: 'id').
 */
function parseResponse(response, mapIdName = DEFAULT_ID) {
  response.parsed = {
    meta: calculateMeta(response),
  };

  if (response.data
    && response.data.length > 0
    && Object.prototype.hasOwnProperty.call(response.data[0], mapIdName)) {
    response.parsed.map = arrayToMapById(response.data, mapIdName);
  }
  return response;
}

/**
 * get source name parts and construct the source string that can
 * clearfy the context of the request.
 * @param {Object} source .
 *  source.projectName - project name
 *  source.appName - app name,
 *  source.contextName - context name,
 */
function alignToStringReqs(str) {
  return str.toLocaleLowerCase().charAt(0).toUpperCase() + str.slice(1);
}

function buildSourceName({
  projectName = defaultSourceApplication,
  appName = defaultAppName,
  contextName = defaultContextName,
} = {}) {
  let src = `${projectName.toLocaleLowerCase()}`;
  if (appName) {
    const convertedAppName = alignToStringReqs(appName);
    src = `${src}${convertedAppName}`;
  }
  if (contextName) {
    const convertedContextName = alignToStringReqs(contextName);
    src = `${src}${convertedContextName}`;
  }
  return src;
}

export default {
  buildSourceName,
  buildHeaders,
  parseResponse,
  calculateMeta,
};
