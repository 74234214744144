import { PERSONALIZATION_WIDGET } from './types';

export const SET_MODULES = 'setModules';
export const SET_REGIONS = 'setRegions';
export const SET_ROUTES = 'setRoutes';
export const ADD_ROUTES = 'addRoutes';
export const CURRENT_PERSONA = 'currentPersona';
export const OUTER_ROUTE = 'outerRoute';
export const PERSONALIZATION = 'personalization';
export const TOP_BAR_NOTIFICATION = 'topBarNotification';
export const ADD_VALID_APPLICATION = 'addValidApplication';
export const PERSONA_NOTIFICATION = 'personaNotification';
export const SET_LOGGED_IN_USER_DETAILS = 'setLoggedInUserDetails';
export const SET_AGENT_TIMER = 'setAgentTimer';

export const PERSONALIZATION_WIDGET_PAGE = `${PERSONALIZATION_WIDGET}/page`;
export const PERSONALIZATION_WIDGET_IS_OPEN = `${PERSONALIZATION_WIDGET}/isOpen`;
export const PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT = `${PERSONALIZATION_WIDGET}/featureByRoutCount`;
export const PERSONALIZATION_WIDGET_IS_REPORTING_OPEN = `${PERSONALIZATION_WIDGET}/isReportingOpen`;
