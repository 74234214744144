/* eslint-disable */

function installGlobal(Vue, options) {
  // 1. add global method or property
  Vue.myGlobalMethod = function myGlobalMethod() {
    // something logic ...
  };

  Vue.frameworkInstalled = function frameworkInstalled() {
    return true;
  };
}

function installGlobalAsset(Vue, options) {
  // 2. add a global asset
  Vue.directive('my-directive', {
    bind(el, binding, vnode, oldVnode) {
      // something logic ...
    },
  });
}

function installMixin(Vue) {
  // 3. inject some component options
  Vue.mixin({
    created: () => {
      // something logic ...
    },
  });
}

function installInstance(Vue, options) {
  // 4. add an instance method
  Vue.prototype.$myMethod = function myMethod(opts) {
    // something logic ...
  };
}

function install(Vue, options) {
  // Check if already installed
  if (Vue && typeof Vue.frameworkInstalled === 'function' && Vue.frameworkInstalled()) {
    return;
  }

  // 1. add global method or property
  installGlobal(Vue, options);

  // 2. add a global asset
  installGlobalAsset(Vue, options);

  // 3. inject some component options
  installMixin(Vue);

  // 4. add an instance method
  installInstance(Vue, options);
}

// Auto Install
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install);
}

export default {
  install,
};
