import { store } from '../store';
import { ENVIRONMENT } from '../store/types';
import { ENVIRONMENT_STATE_KEY } from '../store/modules/environment/keys';

const DEFAULT_VALUE = 'default';
const FILE_EXTENSIONS = {
  MP3: 'mp3',
  WAV: 'wav',
};
const MAX_INDEX = 4;
let extension;

function checkFileExtension() {
  const audioElement = document.createElement('audio');
  const mp3 = audioElement.canPlayType('audio/mpeg;').replace(/^no$/, '');
  const wav = audioElement.canPlayType('audio/wav; codecs="1"').replace(/^no$/, '');
  return (wav === 'probably' && mp3 !== 'probably') ? FILE_EXTENSIONS.WAV : FILE_EXTENSIONS.MP3;
}

function getFileExtension() {
  if (!extension) {
    extension = checkFileExtension();
  }

  return extension;
}

function getCustomRingLocation(urlResolver) {
  return (urlResolver && urlResolver.getUrl) ? urlResolver.getUrl('https://{leCdnDomain}/p/lp-louvre/latest/private-apps-assets/le-ui-core/audio') : `${window.leResourceBaseUrl ? window.leResourceBaseUrl : ''}assets/audio`;
}

function updateCallbacksWithRing(callbacks, newRingURL, isDefaultRing) {
  callbacks.forEach((callback) => callback(isDefaultRing, newRingURL));
}

export default class CustomRings {
  constructor(options) {
    this.currentIndex = 0;
    this.standalone = !!options && !!options.standalone;
    this.urlResolver = options && options.urlResolver;
    this.location = getCustomRingLocation(this.urlResolver);
    this.changeCallbacks = [];
  }

  incrementIndex() {
    this.currentIndex = (this.currentIndex + 1) % MAX_INDEX;
    updateCallbacksWithRing(
      this.changeCallbacks,
      this.getCurrentCustomRingURL(),
      this.isDefaultRing(),
    );
  }

  resetIndex() {
    this.currentIndex = 0;
    updateCallbacksWithRing(
      this.changeCallbacks,
      this.getCurrentCustomRingURL(),
      this.isDefaultRing(),
    );
  }

  getCurrentCustomRing() {
    if (!this.standalone) {
      return store.getters[`${ENVIRONMENT}/${ENVIRONMENT_STATE_KEY.CURRENT_RING}`] || DEFAULT_VALUE;
    }
    return DEFAULT_VALUE;
  }

  isDefaultRing(ring) {
    const ringToUse = ring || this.getCurrentCustomRing();
    return ringToUse === DEFAULT_VALUE;
  }

  getCurrentCustomRingFileName(ring, index) {
    const current = (ring && ring.toLowerCase()) || this.getCurrentCustomRing();
    let currentIndex = index;
    if (current === DEFAULT_VALUE) {
      return `${current}.${getFileExtension()}`;
    }
    if (typeof currentIndex !== 'number' || currentIndex < 0 || currentIndex >= MAX_INDEX) {
      currentIndex = this.currentIndex;
    }
    return `${current}_${currentIndex}.${getFileExtension()}`;
  }

  getCurrentCustomRingURL(ring, index) {
    return `${this.location}/${this.getCurrentCustomRingFileName(ring, index)}`;
  }

  generateCustomRingURLs(ring) {
    const rings = [];
    for (let i = 0; i < MAX_INDEX; i += 1) {
      rings.push(this.getCurrentCustomRingURL(ring, i));
    }
    return rings;
  }

  onCustomRingChange(callback) {
    if (callback && typeof callback === 'function') {
      this.changeCallbacks.push(callback);
    }
  }

  static checkFileExtension = checkFileExtension;

  static getMaxIndex() {
    return MAX_INDEX;
  }
}
