/* eslint-disable */
import sessionManager from '../auth/session/sessionManager';

// TODO: remove after a while, need to clear....
try{
  const storage = lpTag.storageMethods;
  storage.removePersistentData('LELoggerLevel');
} catch (e) {

}


/**
 * @description:
 * execute func if defined
 * else
 * (privileges && features)
 *  will process routes in structure:
 *  meta: {
 *   auth: {
 *    privileges: [2222],
 *
 *    features: ['Analytics.Analytics.Third_Party_Enabled'],
 *     ovrFunc: function () {
 *       return false
 *     },
 *     unauthorizedRouteRedirect: `/${TABS.MY_CONNECTIONS}`,
 *   },
 * },
 * @param to target route
 * @param from source route
 * @param auth condition object
 */
function preProcessAuthData(to, from, options) {
  const currentPrivileges = sessionManager.getPrivileges();
  let hasPrivileges = false;
  let hasFeatures = false;
  const info = {
    valid: true,
  };
  // in case no condition was defined set to true.
  if (!options.privileges && !options.features && !options.ovrFunc) {
    return info;
  }

  if (options.ovrFunc) {
    try {
      info.valid = options.ovrFunc(to, from);
    } catch (e) {
      logger.error(`Route: ${to.path} ovrFunc not failed`, JSON.stringify(to));
    }
    return info;
  }

  if (options.privileges) {
    info.redirect = options.unauthorizedRouteRedirect;

    // Iterate through the needed privileges for the route and
    // look for it in the authenticated privileges
    if (options.privileges && options.privileges.length) {
      hasPrivileges = options.privileges.some(privilege =>
        currentPrivileges.indexOf(privilege) !== -1);
    }
  }
  if (options.features) {
    info.redirect = options.unauthorizedRouteRedirect;

    // Iterate through the needed privileges for the route and
    // look for it in the authenticated privileges
    if (options.features && options.features.length) {
      hasFeatures = options.features.some(feature =>
        sessionManager.getFeaturePropertyState(options.features[feature]) !== -1);
    }
  }

  info.valid = hasFeatures && hasPrivileges;
  return info;
}

function checkExistence(to, attr) {
  return to.meta &&
  to.meta.auth &&
  to.meta.auth[attr] ? to.meta.auth[attr] : undefined;
}

function validateRoutePermissions(to, from, isPrivilegesIgnored) {
  let info = {};
  if (!isPrivilegesIgnored) {
    info = {
      privileges: checkExistence('privileges'),
      features: checkExistence('features'),
      ovrFunc: checkExistence('ovrFunc'),
    };
  }

  info.auth = preProcessAuthData(to, from, info);
  return info;
}

export default {
  validateRoutePermissions,
};
