import { extend } from '../../utils';
import CONST from '../Const';
import AbstractAppender from './AbstractAppender';

const lpTag = window.lpTag || {};
const KEYS = {
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  l: 76,
};

const KEYS_INVERT = {
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  73: 'i',

  76: 'l',
  187: '+',
  189: '-',
};

const RemoteLoggosAppender = AbstractAppender.extend({
  defaultStrategy: {
    enabled: true,
    logLevel: CONST.LOG_LEVEL.ERROR,
    cacheMaxSize: 2000,
    buffer: {
      itemsMax: 100,
      duration: 10000,
      interval: 1000,
    },
    throttle: {
      items: 1000,
      duration: 20000,
      interval: 1000,
    },
  },
  appenderType() {
    return 'RemoteLoggosAppender';
  },
  getStoredLogId() {
    return this.getStoredItem('LogId');
  },
  storeLogId() {
    if (this.getLogId()) {
      this.storeItem('LogId', this.getLogId());
    }
  },
  getFailureCounter() {
    return this.failureCounter;
  },
  /**
   * @param options
   *  options.ws - loggos server ws url.
   *  options.rest - loggos server rest url.
   */
  initialize(options) {
    /* eslint-disable no-param-reassign */

    options = options || {};

    if (options.ws && typeof options.ws === 'string') {
      this.wsURL = options.ws;
    }

    if (options.rest && typeof options.rest === 'string') {
      this.restURL = options.rest;
    }

    AbstractAppender.prototype.initialize.call(this, options);

    this.buffer = [];
    this.keysNamespace = {};
    this.failureCounter = 0;

    this.setLogId(this.getStoredLogId());
    this.initShortcuts();
    this.initThrottleCheck();
    this.initBufferCheck();
  },
  initShortcuts() {
    /* eslint-disable quote-props */
    const shortcuts = {
      // '0' Key
      '0': function func() {
        // Set log level to Error
        this.currentStrategy.logLevel = CONST.LOG_LEVEL.ERROR;
        this.storeStrategy();
      },
      // '1' Key
      '1': function func() {
        // Set log level to Warning
        this.currentStrategy.logLevel = CONST.LOG_LEVEL.WARN;
        this.storeStrategy();
      },
      // '2' Key
      '2': function func() {
        // Set log level to Info
        this.currentStrategy.logLevel = CONST.LOG_LEVEL.INFO;
        this.storeStrategy();
      },
      // '3' Key
      '3': function func() {
        // Set log level to Debug
        this.currentStrategy.logLevel = CONST.LOG_LEVEL.DEBUG;
        this.storeStrategy();
      },
      // '+' Key
      '+': function func() {
        // Set log level to Debug
        this.currentStrategy.logLevel = this.getLevelUp();
        this.storeStrategy();
      },
      // '+' Key
      '-': function func() {
        // Set log level to Debug
        this.currentStrategy.logLevel = this.getLevelDown();
        this.storeStrategy();
      },
      // 'i' Key
      'i': function func() {
        const logId = this.getLogId();

        if (logId) {
          // Show Log Id
          const win = window.open('', 'logId_window', 'status=1,width=400,height=200');
          win.document.getElementsByTagName('body')[0].innerHTML = '';
          win.document.write(`<h1>Your LogId is ${logId}</h1>`);
        }
      },
      // '9' Key
      '9': function func() {
        // Disable/Enable Logging
        this.currentStrategy.enabled = !this.currentStrategy.enabled;
        this.storeStrategy();
      },
    };

    // this.keysNamespace[KEYS.l] = false;
    Object.keys(KEYS_INVERT).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(KEYS_INVERT, key)) {
        this.keysNamespace[key] = false;
      }
    });

    /* eslint-disable prefer-arrow-callback */
    document.addEventListener('keydown', function func(e) {
      // alt + shift + l
      if (e.altKey && e.which in this.keysNamespace && !this.keysNamespace[e.which]) {
        this.keysNamespace[e.which] = true;
      } else if (e.altKey && this.keysNamespace[KEYS.l] && shortcuts[KEYS_INVERT[e.which]]) {
        shortcuts[KEYS_INVERT[e.which]].call(this);
      }
    }.bind(this));

    document.addEventListener('keyup', function func(e) {
      // ctrl + shift + l
      if (e.which in this.keysNamespace) {
        this.keysNamespace[e.which] = false;
      }
    }.bind(this));

    return shortcuts;
  },
  initThrottleCheck() {
    if (this.throttleInterval) {
      clearInterval(this.throttleInterval);
    }
    this.tick = new Date().getTime();
    this.count = 0;

    this.throttleInterval = setInterval(function func() {
      if (this.currentStrategy.throttle.duration <= new Date().getTime() - this.tick) {
        this.tick = new Date().getTime();
        this.count = 0;
      }
    }.bind(this), this.currentStrategy.throttle.interval);
  },
  initBufferCheck() {
    if (this.bufferInterval) {
      clearInterval(this.bufferInterval);
    }
    this.buff = new Date().getTime();
    this.bufferInterval = setInterval(function func() {
      if (this.buffer.length
        && this.currentStrategy.buffer.duration <= new Date().getTime() - this.buff) {
        this.publishLogs();
      }
    }.bind(this), this.currentStrategy.buffer.interval);
  },
  isEnabled() {
    // check if the current strategy enables this appender
    return this.currentStrategy.enabled && (this.count < this.currentStrategy.throttle.items);
  },
  escapeText(text) {
    text = (text && text.toString()) || '';

    return text.replace(/&/g, '&#38;')
      .replace(/</g, '&#60;')
      .replace(/>/g, '&#62;')
      .replace(/'/g, '&#39;')
      .replace(/'/g, '&#34;');
  },
  getCustomData() {
    return {
      // You can remove & add custom data here from session/
      // localStorage, cookies, geolocation, language, mimetypes,â€¦
      referrer: this.escapeText(document.referrer),
      userAgent: this.escapeText(navigator.userAgent),
      platform: this.escapeText(navigator.platform),
      vendor: this.escapeText(navigator.vendor),
      language: this.escapeText(navigator.language),
      // eslint-disable-next-line no-restricted-globals
      screenWidth: this.escapeText(screen.width),
      // eslint-disable-next-line no-restricted-globals
      screenHeight: this.escapeText(screen.height),
      // accountId: UrlResolver.getUrlAccountId(),
      logId: this.getLogId(),
    };
  },
  buildLogsRequest(buffer) {
    const logId = this.getLogId();

    return buffer.map((item) => {
      if (typeof item === 'object' && logId) {
        item.logId = logId;
      }
      return item;
    });
  },
  setLogId(logId) {
    if (logId && !this.logId) {
      this.logId = logId;

      this.storeLogId();
    }
  },
  getLogId() {
    return this.logId;
  },
  configureTransports() {
    let configured = false;
    const conf = {
      server: this.wsURL,
      onlogid: this.setLogId.bind(this),
    };

    if (lpTag && lpTag.taglets && lpTag.taglets.loggostransport) {
      configured = lpTag.taglets.loggostransport.configure(conf);
    }

    return configured;
  },
  publishLogs() {
    let logs;
    let limit;

    // Check needed dependencies
    if (lpTag && lpTag.taglets && lpTag.taglets.lpAjax
      && lpTag.taglets.loggostransport && lpTag.taglets.postmessage) {
      logs = this.buildLogsRequest(this.buffer);
      this.buffer.length = 0;

      this.initBufferCheck();

      if (!this.loggosTransportsConfigured) {
        this.loggosTransportsConfigured = this.configureTransports();
      }

      this.sendLogs(logs);
    } else if (this.currentStrategy.buffer
      && this.currentStrategy.buffer.itemsMax && this.buffer.length) {
      limit = this.currentStrategy.cacheMaxSize || this.currentStrategy.buffer.itemsMax;

      while (limit < this.buffer.length) {
        this.buffer.shift();
      }
    }
  },
  sendLogs(logs) {
    const conf = {
      server: this.restURL,
      onlogid: this.setLogId.bind(this),
    };
    // Use the appName you are currently from the module activeModuleName
    // activeModuleName is controlled by LEUI and has custom settings for VUE and non VUE modules
    // ^^ LEUI: LiveEngageLayoutController.js (non vue), PersonalizationController.js (VUE)
    // restURL is also controlled by LEUI apiResources.json
    const appName = (!window.LE || !window.LE.context || !window.LE.context.attributes || !window.LE.context.attributes.activeModuleName) ? 'CCUI' : window.LE.context.attributes.activeModuleName;
    conf.server = conf.server.replace(/(appName=)[^&]+/, `$1${appName}`);

    lpTag.taglets.lpAjax.issueCall({
      transportOrder: ['loggostransport', 'postmessage'],
      url: conf.server,
      data: logs,
      method: 'POST',
      success(data) {
        if (data && data.responseCode >= 400) {
          this.failureCounter += 1;
        } else if (data && data.body && data.body.logId) {
          conf.onlogid(data.body.logId);
        }
      },
      error() {
        this.failureCounter += 1;
      },
    });
  },
  logMsg(options) {
    options = options || {};

    let appName;
    appName = options && options.obj && options.obj.appName ? options.obj.appName : undefined;
    appName = appName || options.appName;
    // eslint-disable-next-line max-len
    appName = appName || (window.LE && window.LE.context && window.LE.context.attributes && window.LE.context.attributes.activeModuleName ? window.LE.context.attributes.activeModuleName : undefined);
    appName = appName || 'CCUI';

    options.appName = appName;
    options.phase = window.LE.sessionManager.getAccountSettingValueByID('le.site.leui.phase');
    if ((!this.currentStrategy.filters || !this.currentStrategy.filters(options))
      && this.isLevelEnable(this.currentStrategy.logLevel, options.logLevel)) {
      this.buffer.push(extend(options, { url: window.location.href }, true));
      this.count += 1;

      if (options.immediate === true
        || (this.buffer.length && this.currentStrategy.buffer.itemsMax <= this.buffer.length)) {
        this.publishLogs();
      }
    }
  },
});

export default RemoteLoggosAppender;
