/* eslint-disable no-underscore-dangle */

import { createRouter, createWebHashHistory } from 'vue-router';
import sessionManager from '../auth/session/sessionManager';
import routeGuard from '../routing/routeGuard';

const isBasePath = (path) => Boolean(path) && path.startsWith('/');

const createBaseRouter = (options, hooks, base = '') => {
  const router = createRouter({ ...options, history: createWebHashHistory(base) });
  const customHooks = {
    __firstBaseVisit: false,
    beforeFirst(callback) {
      router.beforeEach((to, from, next) => {
        if (typeof callback === 'function' && !router.__firstVisit) {
          router.__firstVisit = true;
          callback(to, from, next);
        } else {
          next();
        }
      });
    },
    beforeEachBase(callback) {
      router.beforeEach((to, from, next) => {
        if (typeof callback === 'function' && isBasePath(to.path)) {
          callback(to, from, next);

          const { auth = {} } = routeGuard.validateRoutePermissions(to, from, !sessionManager.hasProfilesFeature()); // eslint-disable-line max-len

          return auth.valid === true
            ? next() // if first route to LE vue router not active and need to trigger first route
            : next(auth.unauthorizedRouteRedirect || false);
        }

        return next();
      });
    },
    beforeFirstBase(callback) {
      router.beforeEach((to, from, next) => {
        if (typeof callback === 'function' && !this.__firstBaseVisit && isBasePath(to.path)) {
          this.__firstBaseVisit = true;
          callback(to, from, next);
        } else {
          next();
        }
      });
    },
  };

  Object.assign(router, {
    // Add an equivalent to the no longer existing method
    addRoutes: (list) => list.forEach((v) => router.addRoute(v)),
    // Custom hooks added from outside
    ...customHooks,
  });

  // Additional hooks from outside
  if (hooks) {
    Object.entries(hooks).forEach(([key, hook]) => router[key](hook));
  }

  return router;
};

export default createBaseRouter;
/* eslint-enable no-underscore-dangle */
