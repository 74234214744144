import { getLogger } from '../logger';

const logger = getLogger('network_appender');
const lpTag = window.lpTag || {};

function doLog(logData = { msg: 'test' }) {
  if (window.LE && window.LE.getConfig && window.LE.sessionManager) {
    const url = `https://${window.LE.getConfig().loggos}/api/loggos/log/?accountId=${window.LE.sessionManager.accountId}&appName=CCUIInfra`;
    const newLogData = {
      ...logData,
      module_url: window.location.href,
      accountId: window.LE.getConfig().accountId,
      userId: window.LE.sessionManager.getUserId(),
    };

    lpTag.taglets.lpAjax.issueCall({
      transportOrder: ['postmessage'],
      url,
      data: newLogData,
      method: 'POST',
      headers: {
        AUTHORIZATION: `Bearer ${window.LE.sessionManager.getGlob()}`,
      },
      success() {
      },
      error(e) {
        try {
          logger.error('Network Error Logger: failed to request loggos', e);
        } catch (ex) {
          /* eslint-disable no-console */
          console.error('Network Error Logger: Error executing logger in doLog', ex);
        }
      },
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function registerNetworkLogger() {
  const realSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function send() {
    try {
      this.addEventListener('readystatechange', function callback() {
        if (this.readyState === 4) {
          if (this.status >= 400 && this.responseURL && this.responseURL.indexOf('loggos/log/') === -1) {
            doLog(
              {
                msg: 'network_error',
                api_url: this.responseURL,
                method: this.method,
                status: this.status,
                request: this.requestData,
                response: this.responseText,
                logLevel: 'metrics',
              },
            );
          }
        }
      }, false);
    } catch (ex) {
      try {
        logger.error('Network Error Logger - Global exception', ex);
      } catch (ex1) {
        // cannot use logger here as it will cause a circular dependency
        /* eslint-disable no-console */
        console.error('Network Error Logger - Error executing logger', ex1);
      }
    } finally {
      // eslint-disable-next-line prefer-rest-params
      realSend.apply(this, arguments);
    }
  };
}
