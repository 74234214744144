/* eslint-disable */
import { createStore, mapActions, mapGetters } from 'vuex';
import globProvider from '../api-layer/GlobProvider';
import infraMutations from './infra/mutations';
import environmentModule from './modules/environment/loader';
import sessionModule from './modules/session/loader';
import { ENVIRONMENT, SESSION } from './types';

function normalizeActions(store) {
  // for spread usage
  // ...mapActions([names,...,...])
  store.mapActions = (function (localKey, payload) {
    const mapped = mapActions(localKey, payload);
    const mappedFns = {};
    for (const key in mapped) {
      mappedFns[key] = mapped[key].bind(this);
    }
    return mappedFns;
  });
}

function normalizeGetters(store) {
  // // for spread usage.
  // // ...mapGetters([names,...,...])
  store.mapGetters = (function (localKey, payload) {
    const mapped = mapGetters(localKey, payload);
    const mappedFns = {};
    for (const key in mapped) {
      mappedFns[key] = mapped[key].call(this);
    }
    return mappedFns;
  });
}

Object.assign(sessionModule.mutations, infraMutations);
Object.assign(environmentModule.mutations, infraMutations);

const store = createStore({
  strict: false,
  modules: {
    [SESSION]: sessionModule,
    [ENVIRONMENT]: environmentModule,
  },
});

normalizeActions(store);
normalizeGetters(store);

globProvider.setGlob(() => store.getters['session/getValue']('glob'));

export { store };
