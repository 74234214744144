import TransportLayer from '../transportLayer/TransportLayer';
import { GET_VALUE } from '../../store/modules/session/getter-types';
import { SESSION } from '../../store/types';
import { SESSION_STATE_KEY as sessionStateKeys } from '../../store/modules/session/keys';

const appServerSessionModel = {
  resourceUrl: null,
  resolve() {
    const wsuk = this.store.getters[`${SESSION}/${GET_VALUE}`](sessionStateKeys.WSUK);
    const accountId = this.store.getters[`${SESSION}/${GET_VALUE}`](sessionStateKeys.ACCOUNT_ID);
    this.resourceUrl = this.resourceUrl.replace('{accountId}', accountId)
      .replace('{wsuk}', wsuk);
  },
  save() {
    this.resolve();
    throw new Error(`${this.name}::save::Method Not Supported`);
  },
  fetch(options) {
    this.resolve();
    /* eslint-disable no-param-reassign */
    options = options || {};
    options.url = this.resourceUrl;
    options.dataType = 'jsonp';
    options.cache = false;
    options.contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
    options.headers = {
      LE2AuthToken: '{token}',
    };

    // Lets do the refresh
    return TransportLayer.fetch.call(this, options, this.transporter);
  },
  destroy(options) {
    this.resolve();
    /* eslint-disable no-param-reassign */
    options = options || {};
    options.url = this.resourceUrl;
    options.dataType = 'jsonp';

    // Lets do the destroy
    return TransportLayer.destroy.call(this, options, this.transporter);
  },

  initialize(options) {
    this.resourceUrl = options.appServerResource;
    this.transporter = options.transporter;
    this.store = options.store;

    return this;
  },
};

export default appServerSessionModel;
