import { SESSION_STATE_KEY } from './keys';
import { getValue, getValues, isValidAttr } from '../../../utils';
import {
  GET_ACCOUNT_DATA,
  GET_ACCOUNT_SETTINGS_BY_ID,
  GET_CONFIG,
  GET_CSDS_RESPONSE,
  GET_FEATURE,
  GET_FEATURE_STATE,
  GET_FEATURES,
  GET_PERSONAS,
  GET_SETTINGS_MAP,
  GET_USER_PRIVILEGE_BY_ID,
  GET_USER_PRIVILEGES_MAP,
  GET_VALUE,
  IS_VALID_STATE_ATTRIBUTE,
} from './getter-types';

const getters = {
  [GET_VALUE](state) {
    return (name) => getValue(name, state);
  },
  [GET_FEATURE](state) {
    return (name) => getValue(name, state[SESSION_STATE_KEY.FEATURES_MAP]);
  },
  [GET_PERSONAS](state) {
    return getValue(SESSION_STATE_KEY.PERSONAS, state);
  },
  [IS_VALID_STATE_ATTRIBUTE](state) {
    return (name) => isValidAttr(name, state);
  },
  [GET_FEATURE_STATE](state) {
    return function getFeature(name) {
      const feature = getValue(name, state[SESSION_STATE_KEY.FEATURES_MAP]);
      if (!feature) {
        return false;
      }
      return feature.value.value;
    };
  },
  [GET_CONFIG](state) {
    return () => ({
      [SESSION_STATE_KEY.LOGIN_NAME]: getValue(SESSION_STATE_KEY.LOGIN_NAME, state),
      [SESSION_STATE_KEY.ACCOUNT_TIMEZONE_ID]: getValue(
        SESSION_STATE_KEY.ACCOUNT_TIMEZONE_ID,
        state,
      ),
      [SESSION_STATE_KEY.ADMIN]: getValue(SESSION_STATE_KEY.ADMIN, state),
      [SESSION_STATE_KEY.LPA]: getValue(SESSION_STATE_KEY.LPA, state),
      [SESSION_STATE_KEY.SERVER_CURRENT_TIME]: getValue(
        SESSION_STATE_KEY.SERVER_CURRENT_TIME,
        state,
      ),
      [SESSION_STATE_KEY.SERVER_TIME_GMT_DIFF]: getValue(
        SESSION_STATE_KEY.SERVER_TIME_GMT_DIFF,
        state,
      ),
      [SESSION_STATE_KEY.SERVER_TIME_ZONE]: getValue(SESSION_STATE_KEY.SERVER_TIME_ZONE, state),
      [SESSION_STATE_KEY.TIME_DIFF]: getValue(SESSION_STATE_KEY.TIME_DIFF, state),
      [SESSION_STATE_KEY.USER_ID]: getValue(SESSION_STATE_KEY.USER_ID, state),
      [SESSION_STATE_KEY.USER_PRIVILEGES]: getValues(state[SESSION_STATE_KEY.USER_PRIVILEGES_MAP]),
      [SESSION_STATE_KEY.PERSONAS]: getValue(SESSION_STATE_KEY.PERSONAS, state),
      [SESSION_STATE_KEY.USER_PID]: getValue(SESSION_STATE_KEY.USER_PID, state),
      [SESSION_STATE_KEY.DEFAULT_PERSONA]: getValue(SESSION_STATE_KEY.DEFAULT_PERSONA, state),
      [SESSION_STATE_KEY.SSO_LOGOUT_URL]: getValue(SESSION_STATE_KEY.SSO_LOGOUT_URL, state),
    });
  },
  [GET_FEATURES](state) {
    return () => getValues(state[SESSION_STATE_KEY.FEATURES_MAP]);
  },
  [GET_CSDS_RESPONSE](state) {
    return () => ({
      [SESSION_STATE_KEY.BASE_URIS]: getValue(SESSION_STATE_KEY.BASE_URIS, state),
    });
  },
  [GET_ACCOUNT_DATA](state) {
    return () => ({
      [SESSION_STATE_KEY.SETTINGS_DATA]: {
        [SESSION_STATE_KEY.SETTINGS]: getValues(state[SESSION_STATE_KEY.SETTINGS_MAP]),
      },
      [SESSION_STATE_KEY.PROVISION_DATA]: {
        [SESSION_STATE_KEY.FEATURES]: getValues(state[SESSION_STATE_KEY.FEATURES_MAP]),
      },
      [SESSION_STATE_KEY.AGENT_GROUPS_DATA]: {
        [SESSION_STATE_KEY.ITEMS]: getValues(state[SESSION_STATE_KEY.AGENT_GROUPS_MAP]),
      },
    });
  },
  [GET_SETTINGS_MAP](state) {
    return () => state[SESSION_STATE_KEY.SETTINGS_MAP];
  },
  [GET_ACCOUNT_SETTINGS_BY_ID](state) {
    return ((obj) => {
      const res = state[SESSION_STATE_KEY.SETTINGS_MAP][obj.id];
      if (!res) {
        return obj.defaultValue;
      }
      return res.propertyValue.value;
    });
  },
  [GET_USER_PRIVILEGES_MAP](state) {
    return () => state[SESSION_STATE_KEY.USER_PRIVILEGES_MAP];
  },
  [GET_USER_PRIVILEGE_BY_ID](state) {
    return (id) => state[SESSION_STATE_KEY.USER_PRIVILEGES_MAP][id];
  },
};

export default getters;
