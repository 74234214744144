/* eslint-disable prefer-rest-params,no-unused-vars, prefer-arrow-callback, func-names */
export default class EventEmitter {
  constructor() {
    this.events = {};
  }

  on(event, listener) {
    if (typeof this.events[event] !== 'object') {
      this.events[event] = [];
    }
    const idxs = this.events[event].filter(function (registeredCb) {
      return listener === registeredCb;
    });
    if (idxs.length === 0) {
      this.events[event].push(listener);
    }
  }

  clearAllListeners(event, listener) {
    if (typeof this.events[event] === 'object') {
      if (listener === undefined) {
        if (this.events[event]) {
          this.events[event] = [];
          delete this.events[event];
        }
      } else {
        this.off(event, listener);
      }
    }
  }

  off(event, listener) {
    if (typeof this.events[event] === 'object') {
      const idx = this.events[event].indexOf(listener);
      if (idx > -1) {
        this.events[event].splice(idx, 1);
      }
    }
  }

  emit(event, payload) {
    const args = [].slice.call(arguments, 1);
    if (typeof this.events[event] === 'object') {
      const listeners = this.events[event].slice();
      const { length } = listeners;
      for (let i = 0; i < length; i += 1) {
        listeners[i].apply(this, args);
      }
    }
  }

  once(event, listener) {
    this.on(event, function g() {
      this.removeListener(event, g);
      listener.apply(this, arguments);
    });
  }
}
