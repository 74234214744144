import { CRUDMethods, HTTP_RESPONSE_STATUS_CODES } from './consts';
import BaseClient from './BaseClient';

export const hasValidateReqDependency = function _hasValidateReqDependency(recipeRequests, recipeRequestsNamesMap, req) {
  let isValidReqDependency = false;

  if (!req.dependsOn) {
    isValidReqDependency = true;
  } else {
    isValidReqDependency = req.dependsOn && recipeRequestsNamesMap[req.dependsOn]
        && req.preSendCallbackFunction
        && typeof req.preSendCallbackFunction === 'function';
  }

  return isValidReqDependency;
};

/**
 * Validate the recipe configuration
 * @param {Array} recipeRequests - an array of all the recipe requests` configuration
 * @returns {boolean} isValid
 */
export const isValidRecipe = function _isValidRecipe(recipeRequests) {
  let isValid = true;

  if (!recipeRequests || !Array.isArray(recipeRequests) || recipeRequests.length <= 0) {
    isValid = false;
  } else {
    const recipeRequestsNamesMap = {};
    recipeRequests.forEach((req) => {
      recipeRequestsNamesMap[req.name] = req.name;
    });

    for (let i = 0; i < recipeRequests.length; i += 1) {
      // Validate mandatory fields
      if (!recipeRequests[i] || !recipeRequests[i].name || !recipeRequests[i].baseURL
        || !hasValidateReqDependency(recipeRequests, recipeRequestsNamesMap, recipeRequests[i])) {
        isValid = false;
      }
    }
  }

  return isValid;
};

/**
 * Create an instance of BaseClient for each request from the recipe
 *
 * @param {Array} recipeRequests - an array of all the recipe requests` configuration
 * @returns {Object} recipeRequestsBaseClients - an object of BaseClients instances for each request
 */
export const createRecipeRequestsBaseClients = function _createRecipeRequestsBaseClients(recipeRequests) {
  const recipeRequestsBaseClients = {};

  recipeRequests.forEach((req) => {
    const method = (req.method && CRUDMethods[req.method.toUpperCase()]) || CRUDMethods.GET;
    const baseURL = req.baseURL || undefined;
    const config = req.config || undefined;
    const retryConfig = req.retryConfig || {};
    const source = req.source || {};

    recipeRequestsBaseClients[req.name] = new BaseClient({
      allowedMethods: [method],
      config: {
        baseURL,
        ...config,
      },
      source,
      retryConfig,
    });
  });

  return recipeRequestsBaseClients;
};

/**
 * Create an array of arrays that represent the dependencies of the requests.
 * Each of the inner array contains the current level's items.
 * Each level depends on the previous level and before sending the requests of the
 * current level we run its sequential functions with the response that the request depends on.
 *
 * @param {Array} recipeRequests - an array of all the recipe requests configuration
 * @returns {Array} dependencyArr - an array of arrays that
 * represent the dependencies of the requests.
 */
export const createDependencyArr = function _createDependencyArr(recipeRequests) {
  const dependencyArr = [];
  let currentLevelArr = [];

  // The root level array will be all the requests that are not depends on other requests responses
  const rootLevelArr = recipeRequests.filter((req) => req.dependsOn === undefined);
  dependencyArr.push(rootLevelArr);

  let lastIndex = 0;
  while (dependencyArr[lastIndex] && dependencyArr[lastIndex].length > 0) {
    currentLevelArr.length = 0;

    // Go over the previous level requests and build the next level array.
    // The next level array will contain all the requests that depend on the previous level requests
    for (let i = 0; i < dependencyArr[lastIndex].length; i += 1) {
      /* eslint-disable no-loop-func */
      const nodeDependencyArr = recipeRequests.filter((req) => req.dependsOn === dependencyArr[lastIndex][i].name);

      currentLevelArr = currentLevelArr.concat(nodeDependencyArr);
    }
    if (currentLevelArr.length > 0) {
      const newArr = currentLevelArr.slice();
      dependencyArr.push(newArr);
    }
    lastIndex += 1;
  }

  return dependencyArr;
};

export const hasResponseSuccessStatus = function _hasResponseSuccessStatus(response) {
  return response && response.status
    && ((response.status >= 200 && response.status < 300)
      || response.status === HTTP_RESPONSE_STATUS_CODES.NOT_MODIFIED);
};
