import {
  bind, filter, find, indexOf, intersection, isArray, isFunction, isNumber, isString, isUndefined, merge,
} from 'lodash-es';
import Events from 'Chronos.Events';
import lpAjax from 'lptransporter/lpajax';
import 'lptransporter/transports/postmessage';
import 'lptransporter/transports/jsonp';
import PRIVILEGES from '../../assets/privileges.json';
import sessionKeepAlive from './sessionKeepAlive';
import AuthManager from '../authManager';
import sessionModel from './sessionModel';
import {
  GET_ACCOUNT_DATA,
  GET_CONFIG,
  GET_CSDS_RESPONSE,
  GET_FEATURE,
  GET_FEATURE_STATE,
  GET_FEATURES,
  GET_VALUE,
} from '../../store/modules/session/getter-types';
import { SET_VALUE } from '../../store/modules/session/action-types';
import {
  AUTH_ERROR, LOGIN_COMMANDS, PERMISSION_GROUP, PERSONA,
} from './keys';

import gChannel from '../../lib/gChannel';
import COMMON_CONSTANTS from '../../assets/common-constants-ids.json';
import { SESSION } from '../../store/types';
import appServerSessionModel from './appServerSessionModel';
import globProvider from '../../api-layer/GlobProvider';

const SessionManager = (() => {
  /*
   * Public ENUM to control
   * The login commands
   */
  const SESSION_STATE_KEY = sessionModel.KEY;

  const ACCOUNT_SESSION_TYPE = {
    NEW: 'new',
    CURRENT: 'current',
    EXTERNAL: 'external',
  };

  const ROLES_UNIQUE_PRIVILEGES = {
    admin: 1548,
    agent: 1740,
    agentManager: 9,
    campaignManager: 56,
  };

  const ver = '0.1';
  const changeHandlers = {};
  let tolerance = {};
  let external;

  function sendLoginCommand(command, options) {
    /* eslint-disable no-param-reassign */
    options = options || {};

    // Stop keep alive
    this.sessionKeepAlive.stop();

    /* jshint validthis:true */
    this.logger.debug('Login navigation command is about to be issued!');
    this.events.publish({ eventName: 'session:sendLoginCommand', data: { command, options } });

    // this.commands.execute(`navigate:${command}`, options);
  }

  function sendCurrentAccountSwitchCommand(options) {
    /* eslint-disable no-param-reassign */
    options = options || {};

    /* jshint validthis: true */
    const newAccountId = this.model.getCurrentAccountId();
    let retVal;
    if (isString(newAccountId)) {
      // Stop keep alive
      this.sessionKeepAlive.stop();
      options.newAccountId = newAccountId;
      // this.commands.execute('navigate: switch', options);

      this.events.publish({ eventName: 'session:sendLoginCommand', data: { command: 'switch', options } });
      retVal = true;
    } else {
      sendLoginCommand.call(this, LOGIN_COMMANDS.LOGIN);
      retVal = false;
    }
    return retVal;
  }

  function parseUrlNewAccountIdPlaceHolder() {
    /* jshint validthis:  true */
    const { accountId } = this;
    if (ACCOUNT_SESSION_TYPE.CURRENT === accountId) {
      // Please do not remove this since the command will
      // only be executed after the returned value is processed
      // So if there is no 'return true/false' the page will be
      // edirected to login or registration instead of the account id switch
      return sendCurrentAccountSwitchCommand.call(this, { accountId });
    }
    if (accountId === ACCOUNT_SESSION_TYPE.NEW) {
      return true;
    }
    if (accountId === ACCOUNT_SESSION_TYPE.EXTERNAL) {
      external = true;
      return true;
    }

    return false;
  }

  function startKeepAlive() {
    this.sessionKeepAlive.start();
    this.sessionKeepAlive.startAppServerKeepAlive();
  }

  async function expire(options) {
    /* eslint-disable prefer-rest-params */
    // Do a DELETE to /session and clear the clientside data
    options = options || {};
    const { success } = options;
    const { error } = options;
    const accountId = options.accountId || this.getAccountId();
    /* jshint validthis: true */
    options.success = bind((model, resp, _options) => {
      // Now call handler
      if (success) {
        success.apply(this, [].slice.apply(arguments));
      }

      this.model.expire(merge({}, _options, resp));
      if (!options.noDestroy) {
        sendLoginCommand.call(this, LOGIN_COMMANDS.LOGIN, _options);
      } else if (accountId && !this.model.hasCurrentAccountId({ accountId })) {
        this.model.setCurrentAccountId(accountId);
      }
    }, this);

    options.error = bind((model, resp, _options) => {
      if (error) {
        error.apply(this, [].slice.apply(arguments));
      }

      this.model.expire(merge({}, _options, resp));
      if (!options.noDestroy) {
        sendLoginCommand.call(this, LOGIN_COMMANDS.LOGIN, _options);
      } else if (accountId && !this.model.hasCurrentAccountId({ accountId })) {
        this.model.setCurrentAccountId(accountId);
      }
    }, this);

    // For hacking multitab support
    // TODO:  Remove when MultiTab support should be enabled
    if (options.noDestroyForCurrent) {
      options.noDestroy = this.model.hasCurrentAccountId();
    }

    gChannel.trigger(COMMON_CONSTANTS.INFRA_CHANNEL, COMMON_CONSTANTS.ON_LOGOUT);
    if (this.model.hasValidValue(SESSION_STATE_KEY.ID) && !options.noDestroy) {
      // Destroy the appserver session
      this.modelAppServer.destroy();

      // Destroy the liveengage session
      this.model.destroy(options);
    } else {
      options.success(this.model, {}, options);
    }

    // Allow Chaining
    return SessionManager;
  }

  function sustain(creds, options) {
    creds = creds || {};
    options = options || {};

    const { success } = options;
    const { error } = options;

    /* 2 because we have two sessions to sustain - LEServer and AppServer */
    /* (two async calls to API's that only when completes
    successfully the success handler should be invoked) */
    /* var renderApp = after(2, runMyApp); */

    /* jshint validthis: true */
    options.success = bind((...args) => {
      this.logger.debug(`Session had been sustained successfully for account
      ${this.store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.ACCOUNT_ID)}`);

      // Nullify the tolerance
      tolerance = {};

      // Do something and than call handler
      if (success) {
        success.apply(this, args);
      }
    }, this);
    options.error = bind((resp) => {
      /* eslint-disable prefer-rest-params */
      this.logger.error('Could not sustain session with LE server!!!');
      resp = resp || {};
      resp.immediate = true;
      // resp.status = resp.responseCode;
      this.logger.dir(resp);

      // Do something and than call handler
      if (error) {
        error.apply(this, [].slice.apply(arguments));
      }
      // Avoid kicking the user out when there is temporary internet problem
      // I'll work here with blacklist instead of whitelist
      // The blacklist will contain all HTTP codes which require an
      // immediate logout (currently defined to include only 401 & 405)
      // If the returned HTTP code is not in the blacklist, I will
      // check it against the fault tolerance list
      // Result codes from within the fault tolerance list will be
      // accepted (tolerated) only for X occurrences (defined in maxTolerance),
      // after which a logout will be initiated
      // Currently I did not implement a separate retry mechanism and I
      // am using a counter over the basic session keep alive mechanism
      // It seems to be enough for now
      let needToExpire = false;
      if (indexOf(this.configOptions.BlacklistCodes, resp.status) !== -1) {
        needToExpire = true;
      } else if (indexOf(this.configOptions.FaultlistCodes, resp.status) !== -1) {
        tolerance[resp.status] = (tolerance[resp.status] ? tolerance[resp.status] + 1 : 1);

        if (this.configOptions.MaxFaultTolerance < tolerance[resp.status]) {
          needToExpire = true;
        }
      }

      if (needToExpire) {
        /* jshint validthis: true */
        this.logger.error(
          `Session Manager needToExpire because missing cookies, KILLING SESSION:  ${resp.status}`,
          `fault state:  ${JSON.stringify(tolerance || {})}`,
          'sessionManager',
          resp,
        );
        setTimeout(() => {
          expire.call(this);
        }, 100);
      } else {
        this.logger.error(
          `Session Manager got BAD RESPONSE :  ${resp.status}`,
          `fault state: ${JSON.stringify(tolerance || {})}`,
          'sessionManager',
          resp,
        );
        this.logger.graph({
          name: 'framework: sessionManager: Session Manager got BAD RESPONSE',
        });
      }
    }, this);
    creds.accountId = creds.accountId
      || this.store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.ACCOUNT_ID);

    // Do a GET with refresh flag to /session
    this.model.sustain(creds, options);

    // Allow Chaining
    return SessionManager;
  }

  // called form the baseClient
  function sessionErrorHandler(error) {
    /* eslint-disable no-underscore-dangle */
    return Promise.reject(error);
  }

  function appServerSustain(creds, options) {
    /* jshint validthis: true */
    options = options || {};
    const { success } = options;
    const { error } = options;
    this.modelAppServer.fetch({
      success: bind((...args) => {
        if (success) {
          success.apply(this, args);
        }
      }, this),
      error: bind((...args) => {
        if (error) {
          error.apply(this, args);
        }
      }, this),
    });
  }

  function isAuthenticated(options) {
    options = options || {};

    // Check for existence of a glob to ensure login
    /* jshint validthis: true */
    const authenticated = this.model.isValidSession();

    if (!authenticated && options.force) {
      /* jshint validthis:  true */
      this.logger.error(
        `Authentication test failed,  Session Manager KILLING SESSION ,fault state:  ${JSON.stringify(tolerance || {})}`,
        'sessionManager',
        { immediate: true },
      );
      expire.call(this);
    }

    return authenticated;
  }

  function getAuthentication(options) {
    // getAuthentication is wrapped around our router
    // before we start any routers let us see if the user is valid
    /* jshint validthis: true */
    options = options || {};
    const accountId = options[this.model.KEY.ACCOUNT_ID]
      || this.store.getters[`${SESSION}/${GET_VALUE}`](this.model.KEY.ACCOUNT_ID) || '';
    const otk = options[this.model.KEY.OTK] || this.store.getters[`${SESSION}/${GET_VALUE}`](this.model.KEY.OTK) || '';
    const persona = options[this.model.KEY.PERSONA]
      || this.store.getters[`${SESSION}/${GET_VALUE}`](this.model.KEY.PERSONA);
    const { success } = options;
    const { error } = options;
    const { invalid } = options;
    this.logger.debug(`accountId = ${accountId}`);
    this.logger.debug(`otk = ${otk}`);
    this.logger.debug(`persona = ${persona}`);

    const params = {};
    params[this.model.KEY.ACCOUNT_ID] = accountId;
    params[this.model.KEY.OTK] = otk;
    params[this.model.KEY.PERSONA] = persona;

    this.store.dispatch(`${SESSION}/${SET_VALUE}`, {
      attribute: SESSION_STATE_KEY.ACCOUNT_ID,
      value: accountId,
    });
    this.store.dispatch(`${SESSION}/${SET_VALUE}`, {
      attribute: SESSION_STATE_KEY.OTK,
      value: otk,
    });
    this.store.dispatch(`${SESSION}/${SET_VALUE}`, {
      attribute: SESSION_STATE_KEY.PERSONA,
      value: persona,
    });

    AuthManager.establish.call(this, params, {
      success, error, invalid, accountId,
    });

    return SessionManager;
  }

  function offAuthenticationChange(id) {
    const handler = changeHandlers[id];

    if (isFunction(handler)) {
      /* jshint validthis: true */
      this.model.unregisterCallback(`change: ${SESSION_STATE_KEY.ID}`);
    }

    // Allow Chaining
    return SessionManager;
  }

  function changeHandler(context, callback) {
    return () => {
      // Do something and than call handler
      if (callback) {
        callback.apply(context, [].slice.apply(arguments));
      }
    };
  }

  function onAuthenticationChange(id, callback) {
    offAuthenticationChange(id);

    /* jshint validthis: true */
    const handler = changeHandler(this, callback);

    this.model.registerCallback(`change: ${SESSION_STATE_KEY.ID}`, handler);

    // Allow Chaining
    return SessionManager;
  }

  function isInitialized() {
    /* jshint validthis: true */
    return this.initialized;
  }

  function getConfig() {
    /* jshint validthis: true */
    if (!isAuthenticated.call(this)) {
      return null;
    }

    return this.store.getters[`${SESSION}/${GET_CONFIG}`]();
  }

  function getDefaultPersona() {
    /* jshint validthis:  true */
    const config = getConfig.call(this);
    if (!config || !config[SESSION_STATE_KEY.DEFAULT_PERSONA]) {
      return PERSONA.GUEST;
    }

    return config[SESSION_STATE_KEY.DEFAULT_PERSONA];
  }

  function hasPersona(persona) {
    /* jshint validthis:  true */
    if (!isAuthenticated.call(this)) {
      return persona === getDefaultPersona.call(this);
    }

    const config = getConfig.call(this);
    if (!config || !config[SESSION_STATE_KEY.PERSONAS]) {
      return false;
    }

    // Iteration on the array may not be needed : )
    if (config[SESSION_STATE_KEY.DEFAULT_PERSONA]
      && config[SESSION_STATE_KEY.DEFAULT_PERSONA] === persona) {
      return true;
    }

    return (config[SESSION_STATE_KEY.PERSONAS].includes(persona));
  }

  /* privile/*ge - numeric:  will match the given privilege
  Array:  will match privileges in the given array based on value given in 'or' argument
  or - false:  will match all privileges in 'privilege' array (with AND)
  true:  will match at least one privilege in 'privilege' array (with OR)
  numeric:  will match the given privilege OR the given privilege in 'privilege' argument
  Array:  will match all privileges in the given arra
  (with AND) OR the given privilege in 'privilege' argument */
  function hasPrivilege(privilege, or) {
    /* jshint validthis:  true */
    const config = getConfig.call(this);
    if (!config || !config[SESSION_STATE_KEY.USER_PRIVILEGES] || !privilege) {
      return false;
    }

    if (isArray(privilege) && privilege.length) {
      if (isArray(or) && or.length) {
        return (privilege.length === intersection(
          config[SESSION_STATE_KEY.USER_PRIVILEGES],
          privilege,
        ).length)
          || (or.length === intersection(config[SESSION_STATE_KEY.USER_PRIVILEGES], or).length);
      } if (isNumber(or)) {
        return (config[SESSION_STATE_KEY.USER_PRIVILEGES].includes(or)
          || (privilege.length === intersection(
            config[SESSION_STATE_KEY.USER_PRIVILEGES],
            privilege,
          ).length));
      } if (or === true) {
        return (intersection(config[SESSION_STATE_KEY.USER_PRIVILEGES], privilege).length > 0);
      }
      return (privilege.length === intersection(
        config[SESSION_STATE_KEY.USER_PRIVILEGES],
        privilege,
      ).length);
    } if (isNumber(privilege)) {
      if (isArray(or) && or.length) {
        return (config[SESSION_STATE_KEY.USER_PRIVILEGES].includes(privilege)
          || (privilege.length
            === intersection(config[SESSION_STATE_KEY.USER_PRIVILEGES], or).length));
      } if (isNumber(or)) {
        return (config[SESSION_STATE_KEY.USER_PRIVILEGES].includes(privilege)
          || config[SESSION_STATE_KEY.USER_PRIVILEGES].includes(or));
      }
      return (config[SESSION_STATE_KEY.USER_PRIVILEGES].includes(privilege));
    }
    return false;
  }

  function getActivePersona() {
    /* jshint validthis: true */
    if (!isAuthenticated.call(this)) {
      return getDefaultPersona.call(this);
    }

    const persona = this.store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.PERSONA);

    if (isUndefined(persona)) {
      return getDefaultPersona.call(this);
    }

    return persona;
  }

  function setActivePersona(persona) {
    /* jshint validthis: true */
    if (hasPersona.call(this, persona)) {
      this.store.dispatch(
        `${SESSION}/${SET_VALUE}`,
        { attribute: SESSION_STATE_KEY.PERSONA, value: persona },
      );
    }

    // Allow Chaining
    return SessionManager;
  }

  function setLoginName(loginName) {
    this.store.dispatch(
      `${SESSION}/${SET_VALUE}`,
      { attribute: SESSION_STATE_KEY.LOGIN_NAME, value: loginName },
    );
    return SessionManager;
  }

  function getPersonas() {
    /* jshint validthis:  true */
    if (!isAuthenticated.call(this)) {
      return [getDefaultPersona.call(this)];
    }

    const config = getConfig.call(this);
    if (!config || !config[SESSION_STATE_KEY.PERSONAS]) {
      return [];
    }

    return config[SESSION_STATE_KEY.PERSONAS];
  }

  function getPermissionGroupByPrivilege() {
    let permissionGroup = null;
    /* in order to get the persona according to the privileges we need to check for those IDs */
    if (this.hasPrivilege) {
      if (this.hasPrivilege(PRIVILEGES.privilegeIDs.LE_VIEW_AGENT_MODULE)
        && !this.hasPrivilege([PRIVILEGES.privilegeIDs.LE_VIEW_CAMPAIGN_MODULE,
          PRIVILEGES.privilegeIDs.LE_VIEW_USER_MANAGEMENT_MODULE,
          PRIVILEGES.privilegeIDs.LE_VIEW_AGENT_MANAGER_MODULE], true)) {
        permissionGroup = PERMISSION_GROUP.AGENT;
      } else if (this.hasPrivilege(PRIVILEGES.privilegeIDs.LE_VIEW_AGENT_MANAGER_MODULE)
        && !this.hasPrivilege(PRIVILEGES.privilegeIDs.LE_VIEW_CAMPAIGN_MODULE)) {
        permissionGroup = PERMISSION_GROUP.AGENT_MANAGER;
      } else if (this.hasPrivilege(PRIVILEGES.privilegeIDs.LE_VIEW_CAMPAIGN_MODULE)
        && !this.hasPrivilege(PRIVILEGES.privilegeIDs.LE_VIEW_AGENT_MANAGER_MODULE)) {
        permissionGroup = PERMISSION_GROUP.CAMPAIGN_MANAGER;
      } else if (this.hasPrivilege([PRIVILEGES.privilegeIDs.LE_VIEW_CAMPAIGN_MODULE,
        PRIVILEGES.privilegeIDs.LE_VIEW_USER_MANAGEMENT_MODULE,
        PRIVILEGES.privilegeIDs.LE_VIEW_AGENT_MANAGER_MODULE])) {
        permissionGroup = PERMISSION_GROUP.ADMIN;
      } else {
        permissionGroup = PERMISSION_GROUP.AGENT;
      }
    }

    return permissionGroup;
  }

  function getPrivileges() {
    /* jshint validthis:  true */
    const config = getConfig.call(this);
    if (!config || !config[SESSION_STATE_KEY.USER_PRIVILEGES]) {
      return [];
    }

    return config[SESSION_STATE_KEY.USER_PRIVILEGES];
  }

  function hasExportAcUsersData() {
    /* jshint validthis:  true */
    if (this.hasPrivilege) {
      if (this.hasPrivilege(PRIVILEGES.privilegeIDs.EXPORT_AC_USERS_DATA)) {
        return true;
      }
    }
    return undefined;
  }

  function hasViewAcLob() {
    /* jshint validthis:  true */
    if (this.hasPrivilege) {
      if (this.hasPrivilege(PRIVILEGES.privilegeIDs.VIEW_AC_LOB)) {
        return true;
      }
    }
    return undefined;
  }

  function hasEditAcLob() {
    /* jshint validthis:  true */
    if (this.hasPrivilege) {
      if (this.hasPrivilege(PRIVILEGES.privilegeIDs.EDIT_AC_LOB)) {
        return true;
      }
    }
    return undefined;
  }

  function hasConfigureDefaultSurveyForSkillChange() {
    /* jshint validthis:  true */
    if (this.hasPrivilege) {
      if (this.hasPrivilege(
        PRIVILEGES.privilegeIDs.DEFAULT_SURVEY_FOR_SKILL_CHANGE_CONFIGURATION,
      )) {
        return true;
      }
    }
    return undefined;
  }

  function getFeatures() {
    return this.store.getters[`${SESSION}/${GET_FEATURES}`]();
  }

  function getGlob() {
    /* jshint validthis: true */
    if (!isAuthenticated.call(this)) {
      return null;
    }
    const glob = globProvider.getGlob();
    return glob;
  }

  function getWsuk() {
    /* jshint validthis: true */
    if (!isAuthenticated.call(this)) {
      return null;
    }
    return this.store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.WSUK);
  }

  function getCsrf() {
    /* jshint validthis: true */
    if (!isAuthenticated.call(this)) {
      return null;
    }

    return this.store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.CSRF);
  }

  function getUserId() {
    /* jshint validthis:  true */
    if (!isAuthenticated.call(this)) {
      return null;
    }

    return this.store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.USER_ID);
  }

  function getAccountId() {
    /* jshint validthis: true */
    return this.store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.ACCOUNT_ID);
  }

  function getFeatureProperty(key) {
    /* jshint validthis:  true */
    const features = this.store.getters[`${SESSION}/${GET_FEATURES}`]();

    if (!features) {
      return null;
    }

    return this.store.getters[`${SESSION}/${GET_FEATURE}`](key);
  }

  function getFeaturePropertyState(key) {
    /* jshint validthis:  true */
    return this.store.getters[`${SESSION}/${GET_FEATURE_STATE}`](key);
  }

  function getConfigProperty(key) {
    /* jshint validthis:  true */
    const config = this.store.getters[`${SESSION}/${GET_CONFIG}`]();
    if (!config || isUndefined(config[key])) {
      return null;
    }

    return config[key];
  }

  function getCsdsResponse() {
    /* jshint validthis: true */
    if (!isAuthenticated.call(this)) {
      return null;
    }

    return this.store.getters[`${SESSION}/${GET_CSDS_RESPONSE}`]();
  }

  function getCsdsResponseProperty(key) {
    /* jshint validthis:  true */
    const response = getCsdsResponse.call(this);
    if (!response || !response[key]) {
      return null;
    }

    return response[key];
  }

  function getBaseUris() {
    /* jshint validthis:  true */
    return this.store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.BASE_URIS);
  }

  function getCsdsServiceURI(key) {
    const csdsResponse = this.getBaseUris();
    const requestedObj = filter(csdsResponse, { service: key });

    if (requestedObj.length === 0) {
      return null;
    }
    return requestedObj[0].baseURI;
  }

  function getLoginName() {
    /* jshint validthis:  true */
    return getConfigProperty.call(this, SESSION_STATE_KEY.LOGIN_NAME);
  }

  function getServerLoginTime() {
    /* jshint validthis:  true */
    return getConfigProperty.call(this, SESSION_STATE_KEY.SERVER_CURRENT_TIME);
  }

  function getServerTimeGMTDiff() {
    /* jshint validthis:  true */
    return getConfigProperty.call(this, SESSION_STATE_KEY.SERVER_TIME_GMT_DIFF);
  }

  function getServerAccountOffset() {
    /* jshint validthis:  true */
    return getConfigProperty.call(this, SESSION_STATE_KEY.TIME_DIFF);
  }

  function getSSOLogoutUrl() {
    /* jshint validthis:  true */
    return getConfigProperty.call(this, SESSION_STATE_KEY.SSO_LOGOUT_URL);
  }

  function getLastTouched() {
    /* jshint validthis: true */
    if (!isAuthenticated.call(this)) {
      return 0;
    }

    return this.model.getLastTouched();
  }

  function getAccountZone() {
    return this.LEConfig.lp_zone;
  }

  function getLEConfigProp(configName) {
    return this.LEConfig && this.LEConfig[configName];
  }

  function getLogger() {
    /* jshint validthis: true */
    return this.logger;
  }

  function getAccountData() {
    /* jshint validthis: true */
    if (!isAuthenticated.call(this)) {
      return null;
    }
    return this.store.getters[`${SESSION}/${GET_ACCOUNT_DATA}`]();
  }

  function getAccountSettings() {
    /* jshint validthis:  true */
    if (!isAuthenticated.call(this)) {
      return null;
    }
    const accountData = getAccountData.call(this);
    if (accountData) {
      const settingsData = accountData[SESSION_STATE_KEY.SETTINGS_DATA];
      if (settingsData) {
        return settingsData[SESSION_STATE_KEY.SETTINGS];
      }
    }
    return null;
  }

  function getAgentGroupsData() {
    /* jshint validthis:  true */
    if (!isAuthenticated.call(this)) {
      return null;
    }
    const accountData = getAccountData.call(this);
    if (accountData) {
      const agentGroupsData = accountData[SESSION_STATE_KEY.AGENT_GROUPS_DATA];
      if (agentGroupsData) {
        return agentGroupsData;
      }
    }
    return null;
  }

  function getAccountSettingByID(id) {
    /* jshint validthis:  true */
    const settings = getAccountSettings.call(this);
    if (id && settings) {
      return find(settings, (val) => val.id === id);
    }
    return null;
  }

  function getAccountSettingValueByID(id, defaultValue) {
    /* jshint validthis:  true */
    let result = getAccountSettingByID.call(this, id);
    if (result) {
      result = result.propertyValue.value;
    } else {
      result = defaultValue;
    }
    return result;
  }

  function getBillingData() {
    /* jshint validthis:  true */
    const accountData = getAccountData.call(this);
    if (accountData) {
      return accountData[SESSION_STATE_KEY.BILLING_DATA];
    }
    return null;
  }

  function getServerTimeZoneID() {
    /* jshint validthis:  true */
    return getConfigProperty.call(this, SESSION_STATE_KEY.SERVER_TIME_ZONE);
  }

  function hasLiveEngage2Feature() {
    return sessionModel.hasLiveEngage2Feature();
  }

  function hasCoBrowseFeature() {
    return sessionModel.hasCoBrowseFeature();
  }

  function hasAIAssistWidgetFeature() {
    return sessionModel.hasAIAssistWidgetFeature();
  }

  function hasAsyncMessagingFeature() {
    return sessionModel.hasAsyncMessagingFeature();
  }

  function hasWrapUpTimeFeature() {
    return sessionModel.hasWrapUpTimeFeature();
  }

  function hasSLAPerSkillFeature() {
    return sessionModel.hasSLAPerSkillFeature() && sessionModel.hasAsyncMessagingFeature();
  }

  function hasUserTypeBotFeature() {
    return sessionModel.hasUserTypeBotFeature() && sessionModel.hasProfilesFeature();
  }

  function hasConversationSourcesFeature() {
    return sessionModel.hasConversationSourcesFeature();
  }

  function hasMessagingAutoMessagesFeature() {
    return sessionModel.hasMessagingAutoMessagesFeature();
  }

  function hasEditMessagingAutoMessagesFeature() {
    return sessionModel.hasEditMessagingAutoMessagesFeature();
  }

  function hasUnauthMessagingFeature() {
    return sessionModel.hasUnauthMessagingFeature();
  }

  function hasGoogleSourceFeature() {
    return sessionModel.hasGoogleSourceFeature();
  }

  function hasLpAuthenticationSupportFeature() {
    return sessionModel.hasLpAuthenticationSupportFeature();
  }

  function hasDataEncryptionDataFeature() {
    return sessionModel.hasDataEncryptionDataFeature();
  }

  function hasMigratingFeature() {
    return sessionModel.hasMigratingFeature();
  }

  function hasAgentGroupsFeature() {
    return sessionModel.hasAgentGroupsFeature();
  }

  function hasVisitorFeedPreFiltering() {
    return sessionModel.hasVisitorFeedPreFiltering();
  }

  function hasBlockAddingUsersFeature() {
    return sessionModel.hasBlockAddingUsersFeature();
  }

  function hasBlockSupportAccessFeature() {
    return sessionModel.hasBlockSupportAccessFeature();
  }

  function hasBlockMSTRAccessFeature() {
    return sessionModel.hasBlockMSTRAccessFeature();
  }

  function hasApiKeyManagementFeature() {
    return sessionModel.hasApiKeyManagementFeature();
  }

  function hasIpRestrictionFeature() {
    return sessionModel.hasIpRestrictionFeature();
  }

  function hasLEMyAccount() {
    return sessionModel.hasLEMyAccount();
  }

  function hasReportBuilderAccessFeature() {
    return sessionModel.hasReportBuilderAccessFeature();
  }

  function hasReportBuilderNonLpaAccessFeature() {
    return sessionModel.hasReportBuilderNonLpaAccessFeature();
  }

  function isSeatBasedFeature() {
    return sessionModel.hasSeatBasedFeature();
  }

  function isUnifiedWindowEnabled() {
    return sessionModel.isUnifiedWindowEnabled();
  }

  function hasLimitStickySizeFeature() {
    return sessionModel.hasLimitStickySizeFeature();
  }

  function hasHotTopicsFeature() {
    return sessionModel.hasHotTopicsFeature();
  }

  function hasAuthenticationChatFeature() {
    return sessionModel.hasAuthenticationChatFeature();
  }

  function hasProfilesFeature() {
    return sessionModel.hasProfilesFeature();
  }

  function hasAuditTrailFeature() {
    return sessionModel.hasAuditTrailFeature();
  }
  function hasAutoClosePerSkillFeature() {
    return sessionModel.hasAutoClosePerSkillFeature();
  }

  function hasMultipleAgentStatesFeature() {
    return sessionModel.hasMultipleAgentStatesFeature();
  }

  function hasAgentTimer() {
    return sessionModel.hasAgentTimer();
  }

  function hasConfigForMaximumCustomAwayStatus() {
    return sessionModel.hasConfigForMaximumCustomAwayStatus();
  }

  function isLPA() {
    return sessionModel.isLPA();
  }

  function isExternal() {
    return !!external;
  }

  function getRolesLost() {
    const lst = [];
    if (this.isAdmin()) {
      lst.push('admin');
    }
    if (this.isAgent()) {
      lst.push('agent');
    }
    if (this.isAgentManager()) {
      lst.push('agentManager');
    }
    if (this.isCampaignManager()) {
      lst.push('campaignManager');
    }
    return lst;
  }

  function isAdmin() {
    return sessionModel.isAdmin();
  }

  function isAgent() {
    return this.hasPrivilege(ROLES_UNIQUE_PRIVILEGES.agent);
  }

  function isAgentManager() {
    return this.hasPrivilege(ROLES_UNIQUE_PRIVILEGES.agentManager);
  }

  function isCampaignManager() {
    return this.hasPrivilege(ROLES_UNIQUE_PRIVILEGES.campaignManager);
  }

  async function getPosition(options) {
    let coords = {};
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      });
      coords = position.coords;
    } catch (e) {
      this.logger.debug(`can not calculate position ${e}`);
    }
    return coords;
  }

  function hasMultiChatCenterLoadBalancing() {
    return sessionModel.hasMultiChatCenterLoadBalancing();
  }

  function hasMultiChatCenterCascadingLoadBalancing() {
    return sessionModel.hasMultiChatCenterCascadingLoadBalancing();
  }

  function hasLobFeature() {
    return sessionModel.hasLobFeature();
  }

  function hasEntitiesTaggingByLobFeature() {
    /* jshint validthis:  true */
    const lobSettings = getAccountSettingValueByID.call(
      this,
      sessionModel.ACCOUNT_SETTINGS_ID.LOB_ENABLED,
    );
    return sessionModel.hasEntitiesTaggingByLobFeature() && lobSettings === 'true';
  }

  function hasConnectionBarCaseManagementVisible() {
    return sessionModel.hasConnectionBarCaseManagementVisible();
  }

  function hasConnectionBarNotificationVisible() {
    return sessionModel.hasConnectionBarNotificationVisible();
  }

  function hasConnectionBarOpenCaseVisible() {
    return sessionModel.hasConnectionBarOpenCaseVisible();
  }

  function hasConnectionBarDisplay() {
    return sessionModel.hasConnectionBarDisplay();
  }

  function hasLpaProfilesAssignmentFeature() {
    return sessionModel.hasLpaProfilesAssignmentFeature();
  }

  function hasRefreshButtonFeature() {
    return sessionModel.hasRefreshButtonFeature();
  }

  function hasLiveChatQueuePriorityFeature() {
    return sessionModel.hasLiveChatQueuePriorityFeature();
  }

  function hasMessagingFileSharingFeature() {
    /* jshint validthis: true */
    const fileSharingSetting = getAccountSettingValueByID.call(
      this,
      sessionModel.ACCOUNT_SETTINGS_ID.MESSAGING_FILE_SHARING_ENABLED,
    );
    return sessionModel.hasAsyncMessagingFeature() && fileSharingSetting === 'true';
  }

  function hasContentProviderAccountFeature() {
    return sessionModel.hasContentProviderAccountFeature();
  }

  function hasMessagingSurveyFeature() {
    return sessionModel.hasMessagingSurveyFeature();
  }

  function hasMessagingTransferToAgentFeature() {
    return sessionModel.hasMessagingTransferToAgentFeature();
  }

  function hasAutomationAreaFeature() {
    return sessionModel.hasAutomationAreaFeature();
  }

  // For hacking multitab support
  // TODO:  Remove when MultiTab support should be enabled
  //        var invalidateAccountSession = function (accountId, removeFromCurrent) {
  //            this.model.invalidateAccountSession(accountId);
  //
  //            if (removeFromCurrent) {
  //                this.model.removeAccountIdFromCurrent(accountId);
  //            }
  //        };

  function logVersion() {
    /* jshint validthis:  true */
    getLogger.call(this).info(`sessionManager.js version ${ver}`);
  }

  function touched() {
    this.sessionKeepAlive.touched();
  }

  function validateLoginOwnership(context, options) {
    options = options || {};

    const { key } = options;
    const { newValue } = options;
    const { oldValue } = options;

    if (SESSION_STATE_KEY.CURRENT_ACCOUNT_ID === key) {
      const currentAccounts = context.model.getCurrentAccountIds();
      const isOwner = context.model.isLastUpdateOwner();
      const lastTouched = context.model.getLastTouched();

      context.logger.debug(`oldValue = ${oldValue}`);
      context.logger.debug(`newValue = ${newValue}`);
      context.logger.debug(`isOwner = ${isOwner}`);
      context.logger.debug(`lastTouched = ${lastTouched}`);
      context.logger.debug(`currentAccounts = ${currentAccounts}`);

      if (!isOwner && currentAccounts.length > 0) {
        const accountId = context.getAccountId();
        const currentHasAccountId = context.model.hasCurrentAccountId({ accountId });
        const lastIsAccountId = accountId === context.model.getCurrentAccountId();
        const oldHasAccountId = oldValue && oldValue.indexOf(accountId) >= 0;
        // If the change was another account logging in/out - we don't need to expire
        const otherAccountRemoved = oldHasAccountId && currentHasAccountId
          && oldValue.length > newValue.length;
        const noDestroyForCurrent = currentHasAccountId && lastIsAccountId;

        context.logger.debug(`accountId = ${accountId}`);
        context.logger.debug(`currentHasAccountId = ${currentHasAccountId}`);
        context.logger.debug(`lastIsAccountId = ${lastIsAccountId}`);
        context.logger.debug(`noDestroyForCurrent = ${noDestroyForCurrent}`);
        context.logger.debug(`otherAccountRemoved = ${otherAccountRemoved}`);

        if (noDestroyForCurrent && !otherAccountRemoved) {
          /* jshint validthis:  true */
          context.logger.error(
            `Another tab is opened with a session to liveengage, this tab will be expired! Session Manager KILLING SESSION ,fault state:  ${JSON.stringify(tolerance || {})}`,
            'sessionManager',
            { immediate: true },
          );
          setTimeout(() => {
            context.expire({
              noDestroyForCurrent,
              accountId,
              errorCode: AUTH_ERROR.ERROR_LOGIN_DIFFERENT_BROWSER,
            });
          }, 100);
        }
      }
    }
  }

  function registerEvent(actionName, callback) {
    this.events.bind({
      eventName: actionName,
      func: (EventData) => {
        callback(EventData);
      },
    });
  }

  function buildDefaultLEConf(options) {
    const configOptions = {};
    configOptions.BlacklistCodes = (options.configOptions
      && options.configOptions.BlacklistCodes)
      || options.LEConfig.Session.BlacklistCodes;
    configOptions.FaultlistCodes = (options.configOptions
      && options.configOptions.FaultlistCodes)
      || options.LEConfig.Session.FaultlistCodes;
    configOptions.MaxFaultTolerance = (options.configOptions
      && options.configOptions.MaxFaultTolerance)
      || options.LEConfig.Session.MaxFaultTolerance;
    configOptions.KeepAliveInterval = (options.configOptions
      && options.configOptions.KeepAliveInterval)
      || options.LEConfig.Session.KeepAliveInterval;
    configOptions.AppServerKeepAliveInterval = (options.configOptions
      && options.configOptions.AppServerKeepAliveInterval)
      || options.LEConfig.Session.AppServerKeepAliveInterval;
    configOptions.WarnBeforePeriod = (options.configOptions
      && options.configOptions.WarnBeforePeriod)
      || options.LEConfig.Session.WarnBeforePeriod;
    configOptions.LeServer = (options.configOptions
      && options.configOptions.LeServer)
      || options.LEConfig.Session.LeServer;
    return configOptions;
  }

  function setBaseClientBaseUrl(url) {
    if (!url) return;
    this.model.setBaseClientBaseUrl(url);
  }

  function initialize(options) {
    const context = this;
    /* jshint validthis: true */
    /* eslint-disable no-param-reassign */
    if (this.initialized) {
      // Allow Chaining
      return SessionManager;
    }

    this.resourceUrl = options.ACResource.replace('{accountId}', options.accountId);

    options = options || {};
    // this.Media = options.Media;
    // this.vent = options.vent || this.Media.channel('SessionManager').vent;
    // this.commands = options.commands || this.Media.channel('SessionManager').commands;
    // this.reqres = options.reqres || this.Media.channel('SessionManager').reqres;
    this.translator = options.translator || { translate: (value) => value };
    this.configOptions = buildDefaultLEConf(options);
    this.notifier = options.notifier;
    this.accountId = options.accountId;
    this.LEConfig = options.LEConfig;
    this.events = new Events({
      cloneEventData: false,
      eventBufferLimit: 50,
    });

    // context logger
    this.logger = options.logger;

    this.model = sessionModel;
    this.model.initialize({
      store: this.store,
      logger: this.logger,
      ACResource: this.resourceUrl,
      accountId: this.accountId,
      transporter: lpAjax,
      LEConfig: this.LEConfig,
      sessionErrorHandler: sessionErrorHandler.bind(this),
    });
    if (options.AppServerSessionModel) {
      this.modelAppServer = options.AppServerSessionModel;
    } else {
      this.modelAppServer = appServerSessionModel.initialize({
        appServerResource: options.appServerResource,
        accountId: this.accountId,
        events: this.events,
        transporter: lpAjax,
        store: this.store,
      });
    }
    // optimisation for same browser different tab logout
    if ('localStorage' in window && window.localStorage !== null) {
      window.addEventListener('storage', (e) => {
        validateLoginOwnership(context, e.originalEvent);
      });
    }
    // Listen to a request on the events bus to return the session authentication state
    // this.reqres.setHandler('session: isAuthenticated', bind(() => {
    //   isAuthenticated.call(this);
    // }, this));
    //
    // // Listen to a command on the events bus to expire and logout
    // this.commands.setHandler('session: expire', bind((_options) => {
    //   expire.call(this, _options);
    // }, this));

    // Listen to a change in the id
    this.onAuthenticationChange('SessionManager', bind(() => {
      if (!isAuthenticated.call(this)) {
        sendLoginCommand.call(this, LOGIN_COMMANDS.LOGIN);
      }
    }, this));

    // Invalid call for save - move back to login
    this.model.registerCallback('invalid', bind(() => {
      expire.call(this);
    }, this));

    this.sessionKeepAlive = sessionKeepAlive.initialize({
      sessionManager: this,
      translator: options.translator,
      notifier: options.notifier,
      configOptions: this.configOptions,
      logger: this.logger,
      events: this.events,
    });

    this.initialized = true;

    // Allow Chaining
    return SessionManager;
  }

  function setStore(store) {
    this.store = store;
  }

  return {
    model: sessionModel,
    initialize,
    getManager: initialize,
    isInitialized,
    sendLoginCommand,
    sendCurrentAccountSwitchCommand,
    parseUrlNewAccountIdPlaceHolder,
    startKeepAlive,
    sustain,
    appServerSustain,
    expire,
    isAuthenticated,
    getAuthentication,
    offAuthenticationChange,
    onAuthenticationChange,
    hasPersona,
    hasPrivilege,
    getDefaultPersona,
    getActivePersona,
    setActivePersona,
    setLoginName,
    getPersonas,
    getPrivileges,
    getPermissionGroupByPrivilege,
    getConfig,
    getConfigProperty,
    getFeatures,
    getFeatureProperty,
    getFeaturePropertyState,
    getGlob,
    getWsuk,
    getCsrf,
    getUserId,
    getAccountId,
    getLoginName,
    getCsdsResponse,
    getCsdsResponseProperty,
    getCsdsServiceURI,
    getBaseUris,
    getLastTouched,
    getAccountZone,
    getLEConfigProp,
    LOGIN_COMMANDS,
    KEY: sessionModel.KEY,
    ACCOUNT_SETTINGS_ID: sessionModel.ACCOUNT_SETTINGS_ID,
    PERSONA,
    ACCOUNT_SESSION_TYPE,
    // TODO: ITAI - Check with Yahav what are the real needed privileges
    get PRIVILEGES() {
      return PRIVILEGES.privilegeIDs;
    },
    version: logVersion,
    // Delegate this.sessionKeepAlive.touched method
    touched,
    getAccountSettingByID,
    getAgentGroupsData,
    getAccountSettingValueByID,
    getServerLoginTime,
    getServerTimeGMTDiff,
    getServerAccountOffset,
    getSSOLogoutUrl,
    getBillingData,
    getServerTimeZoneID,
    hasLiveEngage2Feature,
    hasDataEncryptionDataFeature,
    hasMigratingFeature,
    hasAsyncMessagingFeature,
    hasSLAPerSkillFeature,
    hasWrapUpTimeFeature,
    hasUserTypeBotFeature,
    hasConversationSourcesFeature,
    hasMessagingAutoMessagesFeature,
    hasEditMessagingAutoMessagesFeature,
    hasUnauthMessagingFeature,
    hasGoogleSourceFeature,
    hasLpAuthenticationSupportFeature,
    hasAgentGroupsFeature,
    hasVisitorFeedPreFiltering,
    hasBlockAddingUsersFeature,
    hasBlockSupportAccessFeature,
    hasBlockMSTRAccessFeature,
    hasApiKeyManagementFeature,
    hasIpRestrictionFeature,
    hasLEMyAccount,
    hasReportBuilderAccessFeature,
    hasReportBuilderNonLpaAccessFeature,
    isSeatBasedFeature,
    isUnifiedWindowEnabled,
    hasLimitStickySizeFeature,
    hasHotTopicsFeature,
    hasAuthenticationChatFeature,
    hasExportAcUsersData,
    hasAutoClosePerSkillFeature,
    hasViewAcLob,
    hasEditAcLob,
    hasConfigureDefaultSurveyForSkillChange,
    isLPA,
    isExternal,
    isAdmin,
    hasMultiChatCenterLoadBalancing,
    hasCoBrowseFeature,
    hasMultiChatCenterCascadingLoadBalancing,
    hasProfilesFeature,
    hasLobFeature,
    hasEntitiesTaggingByLobFeature,
    hasConnectionBarCaseManagementVisible,
    hasConnectionBarDisplay,
    hasConnectionBarOpenCaseVisible,
    hasConnectionBarNotificationVisible,
    hasLpaProfilesAssignmentFeature,
    hasAuditTrailFeature,
    hasMultipleAgentStatesFeature,
    hasRefreshButtonFeature,
    hasLiveChatQueuePriorityFeature,
    validateLoginOwnership,
    hasMessagingFileSharingFeature,
    hasContentProviderAccountFeature,
    hasMessagingSurveyFeature,
    hasMessagingTransferToAgentFeature,
    hasAutomationAreaFeature,
    hasAIAssistWidgetFeature,
    // register ean out side component for inner events.
    registerEvent,
    isAgent,
    isAgentManager,
    isCampaignManager,
    getRolesLost,
    getPosition,
    hasAgentTimer,
    hasConfigForMaximumCustomAwayStatus,
    setBaseClientBaseUrl,
    setStore,
  };
  // </editor-fold desc='Private Methods'>
})();

export default SessionManager;
// </editor-fold desc='AMD Wrapper'>
