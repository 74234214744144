export const LOGIN_COMMANDS = {
  LOGIN: 'login',
  ACCESSDENIED: 'denied',
};

// TODO: remove req
export const PERMISSION_GROUP = {
  LPA: 'lpa',
  ADMIN: 'admin',
  REP: 'rep',
  AGENT: 'agent',
  AGENT_MANAGER: 'agentManager',
  CAMPAIGN_MANAGER: 'campaignManager',
};

export const USER_PERMISSION_GROUP = {
  AGENT: 'operators',
  ADMINISTRATOR: 'administrators',
  AGENT_MANAGER: 'agent managers',
  CAMPAIGN_MANAGERS: 'campaign managers',
};

export const AUTHENTICATION_SERVER = {
  COLLECTION_REFRESH_TIME: 3600000,
};

// TODO: remove req
export const PERSONA = {
  GUEST: 'guest',
  AGENT: 'agent',
  MESSAGING_AGENT: 'messagingAgent',
  MARKETER: 'marketer',
  ADMIN: 'admin',
  SETTINGS_ADMIN: 'settingsAdmin',
  CUSTOMIZER: 'customizer',
  TEST: 'test',
  AGENT_MANAGER: 'agentManager',
  AUTOMATION: 'automation',
};

export const PRIVILEGE_TO_PERSONA_MAP = {};
PRIVILEGE_TO_PERSONA_MAP[PERSONA.ADMIN] = 1501;
PRIVILEGE_TO_PERSONA_MAP[PERSONA.MARKETER] = [
  1500,
];
PRIVILEGE_TO_PERSONA_MAP[PERSONA.AGENT] = [20, 1502];
PRIVILEGE_TO_PERSONA_MAP[PERSONA.GUEST] = -100; // Can Never be a result of the API
PRIVILEGE_TO_PERSONA_MAP[PERSONA.CUSTOMIZER] = [1531, 1533];
PRIVILEGE_TO_PERSONA_MAP[PERSONA.TEST] = 0;
PRIVILEGE_TO_PERSONA_MAP[PERSONA.AUTOMATION] = 1763;

export const DEFAULT_PERSONA_PRIORITY_MAP = {};
DEFAULT_PERSONA_PRIORITY_MAP[PERSONA.ADMIN] = 50;
DEFAULT_PERSONA_PRIORITY_MAP[PERSONA.MARKETER] = 300;
DEFAULT_PERSONA_PRIORITY_MAP[PERSONA.AGENT] = 400;
DEFAULT_PERSONA_PRIORITY_MAP[PERSONA.TEST] = 100;
DEFAULT_PERSONA_PRIORITY_MAP[PERSONA.GUEST] = 0;

export const DEFAULT_PERSONA_TOOLTIP_MAP = {};
DEFAULT_PERSONA_TOOLTIP_MAP[PERSONA.ADMIN] = 'LEUIComponents.persona.admin.tooltip';
DEFAULT_PERSONA_TOOLTIP_MAP[PERSONA.MARKETER] = 'LEUIComponents.persona.marketer.tooltip';
DEFAULT_PERSONA_TOOLTIP_MAP[PERSONA.AGENT] = 'LEUIComponents.persona.agent.tooltip';
DEFAULT_PERSONA_TOOLTIP_MAP[PERSONA.AGENT_MANAGER] = 'LEUIComponents.persona.agentManagerWorkspace.tooltip';
DEFAULT_PERSONA_TOOLTIP_MAP[PERSONA.AUTOMATION] = 'LEUIComponents.persona.automation.tooltip';

export const PERSONA_TITLE = {};
PERSONA_TITLE[PERSONA.AGENT] = 'LEUIComponents.persona.agent.text';
PERSONA_TITLE[PERSONA.MARKETER] = 'LEUIComponents.persona.marketer.text';
PERSONA_TITLE[PERSONA.ADMIN] = 'LEUIComponents.persona.admin.text';
PERSONA_TITLE[PERSONA.AUTOMATION] = 'LEUIComponents.persona.automation.text';
// TODO: replace to "agents" when in production
PERSONA_TITLE[PERSONA.MESSAGING_AGENT] = 'LEUIComponents.persona.newAgentWorkspace.text'; // 'LEUIComponents.persona.admin.text';
PERSONA_TITLE[PERSONA.AGENT_MANAGER] = 'LEUIComponents.persona.agentManagerWorkspace.text';
// TODO: replace to "settings" when in production
PERSONA_TITLE[PERSONA.SETTINGS_ADMIN] = 'SETTINGS_VUE'; // 'LEUIComponents.persona.settingsAdmin.text';

export const AUTH_ERROR = {
  GENERAL_ERROR: 1,
  MAX_OPERATORS_REACH: 2,
  ERROR_LOGIN_DIFFERENT_BROWSER: 3,
  OPERATOR_DISABLED: 4,
  SITE_DISABLED: 5,
  SITE_EXPIRED: 6,
  ONE_SEAT: 7,
};

const LOGIN_ERROR = {
  ERROR_BAD_LOGIN: 1,
  ERROR_OTHER: 4,
  ERROR_LPADMIN_RESTRICTED: 6,
  ERROR_OLD_LPADMIN_RESTRICTED: 7,
  PASSWORD_NEED_CHANGE: 9,
  SESSION_TERMINATED: 10,
  SITE_DISABLED: 11,
  SITE_EXPIRED: 12,
  LM_LOGIN_ERROR: 13,
  ERROR_BAD_SAML_ASSERTION: 14,
  ERROR_SAML_MISSING_SITE: 15,
  ERROR_SAML_MISSING_USER_ID: 16,
  ERROR_SAML_MISSING_ASSERTION: 17,
  ERROR_SAML_FEATURE_OFF: 18,
  ERROR_SAML_IMPROPER_SITE: 19,
  ERROR_LOGIN_DIFFERENT_BROWSER: 21,
  ERROR_SERVICE_PATH_EMPTY: 101,
  ERROR_SERVICE_PATH_INVALID: 102,
  ERROR_SERVICE_PATH_GENERAL: 103,
  ERROR_SERVICE_PATH_ESAPI_VALIDATION: 104,
  ERROR_GENERAL: 1000,
  ERROR_ONE_SEAT: 110,
};

export const LOGIN_ERROR_TO_AUTH_ERROR_MAP = {};
LOGIN_ERROR_TO_AUTH_ERROR_MAP[AUTH_ERROR.GENERAL_ERROR] = LOGIN_ERROR.ERROR_GENERAL;
LOGIN_ERROR_TO_AUTH_ERROR_MAP[AUTH_ERROR.MAX_OPERATORS_REACH] = LOGIN_ERROR.ERROR_BAD_LOGIN;
LOGIN_ERROR_TO_AUTH_ERROR_MAP[AUTH_ERROR.ERROR_LOGIN_DIFFERENT_BROWSER] = LOGIN_ERROR.ERROR_LOGIN_DIFFERENT_BROWSER;
LOGIN_ERROR_TO_AUTH_ERROR_MAP[AUTH_ERROR.OPERATOR_DISABLED] = LOGIN_ERROR.ERROR_BAD_LOGIN;
LOGIN_ERROR_TO_AUTH_ERROR_MAP[AUTH_ERROR.SITE_DISABLED] = LOGIN_ERROR.SITE_DISABLED;
LOGIN_ERROR_TO_AUTH_ERROR_MAP[AUTH_ERROR.SITE_EXPIRED] = LOGIN_ERROR.SITE_EXPIRED;
LOGIN_ERROR_TO_AUTH_ERROR_MAP[AUTH_ERROR.ONE_SEAT] = LOGIN_ERROR.ERROR_ONE_SEAT;
