import {
  ADD_ROUTES,
  ADD_VALID_APPLICATION,
  CURRENT_PERSONA,
  OUTER_ROUTE,
  PERSONA_NOTIFICATION,
  PERSONALIZATION,
  PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT,
  PERSONALIZATION_WIDGET_IS_OPEN,
  PERSONALIZATION_WIDGET_IS_REPORTING_OPEN,
  PERSONALIZATION_WIDGET_PAGE,
  SET_MODULES,
  SET_REGIONS,
  SET_ROUTES,
  TOP_BAR_NOTIFICATION,
  SET_LOGGED_IN_USER_DETAILS,
  SET_AGENT_TIMER,
} from './mutationTypes';

import { ENVIRONMENT_STATE_KEY } from './keys';

const mutations = {};
/* eslint-disable no-param-reassign */
mutations[SET_MODULES] = (state, obj) => {
  state.modules = obj;
};

mutations[SET_REGIONS] = (state, obj) => {
  state.regions = obj;
};

mutations[SET_LOGGED_IN_USER_DETAILS] = (state, obj) => {
  state.loggedInUserDetails = obj;
};

mutations[SET_AGENT_TIMER] = (state, value) => {
  state.agentTimer = value;
};

mutations[SET_ROUTES] = (state, arr) => {
  state.routes = arr;
};

mutations[ADD_VALID_APPLICATION] = (state, app) => {
  if (state.validApps.indexOf(app) === -1) {
    state.validApps.push(app);
  }
};

mutations[ADD_ROUTES] = (state, arr) => {
  state.routes = arr.concat(state.routes);
};

mutations[TOP_BAR_NOTIFICATION] = (state, payload) => {
  state[TOP_BAR_NOTIFICATION] = payload;
};

mutations[PERSONALIZATION] = (state, payload) => {
  state[PERSONALIZATION][payload.attribute] = payload.value;
};

mutations[OUTER_ROUTE] = (state, value) => {
  state[OUTER_ROUTE] = value;
};

mutations[PERSONALIZATION_WIDGET_PAGE] = (state, value) => {
  state[PERSONALIZATION][ENVIRONMENT_STATE_KEY.PAGE] = value;
};

mutations[PERSONALIZATION_WIDGET_IS_OPEN] = (state, value) => {
  state[PERSONALIZATION][ENVIRONMENT_STATE_KEY.IS_OPEN] = value;
};

mutations[PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT] = (state, value) => {
  state[PERSONALIZATION][ENVIRONMENT_STATE_KEY.FEATURE_BY_ROUT_COUNT] = value;
};

mutations[PERSONALIZATION_WIDGET_IS_REPORTING_OPEN] = (state, value) => {
  state[PERSONALIZATION][ENVIRONMENT_STATE_KEY.IS_REPORTING_OPEN] = value;
};

mutations[CURRENT_PERSONA] = (state, value) => {
  state[CURRENT_PERSONA] = value;
};

mutations[PERSONA_NOTIFICATION] = (state, value) => {
  state[PERSONA_NOTIFICATION][value.persona] = value;
};

export default mutations;
