import axios from 'axios';
import axiosRetry from 'axios-retry';
import { cloneDeep } from 'lodash-es';
import apiLayerUtils from './apiLayerUtils';
import EventEmitter from '../lib/EventEmitter';
import { CRUDMethods, EVENTS } from './consts';
import globProvider from './GlobProvider';

let logger;
let sessionManager;
const { CancelToken } = axios;
const DEFAULTS = {
  contentType: {
    JSON: 'application/json',
  },
};

export const setSessionManager = (sessionManagerArg) => {
  sessionManager = sessionManagerArg;
};

export const setLogger = (loggerArg) => {
  logger = loggerArg.getLogger('Infra');
};

const DEFAULT_HEADERS = {
  Accept: '*/*',
  'Content-Type': DEFAULTS.contentType.JSON,
};

function loadDefaultHeaders(headers = {}) {
  return { ...DEFAULT_HEADERS, ...headers };
}

function loadAuthHeaders(headers) {
  const toExtend = headers || {};
  const glob = globProvider.getGlob();
  if (glob) {
    Object.assign(toExtend, { Authorization: `Bearer ${glob}` });
  }
  return toExtend;
}

function defaultErrorHandler(error) {
  /* eslint-disable no-underscore-dangle */
  if (error) {
    if (axios.isCancel(error)) {
      logger.debug('This request has been CANCELED.... SEE YA, NOTHING TO DO...');
    } else if (error.response && error.response.status === 401) {
      logger.debug('BaseClient:errorHandler, response with status code 500 try sustain');
      sessionManager.sustain({ async: false });
    }
  }
  return Promise.reject(error);
}

function loadResponseInterceptors(instance, errorHandler = defaultErrorHandler) {
  instance.interceptors.response.use((response) => response, errorHandler);
}

/**
 * Add a random number to the request to
 * make sure we don't get the cached browser
 * response back
 * @param request
 */

function addBusterToQueryString(request) {
  const buster = Math.floor(Math.random() * 100000);
  request.params = { ...request.params, __d: buster };
  return request;
}

function loadRequestInterceptors(instance, { addBuster = true }) {
  if (addBuster) {
    instance.interceptors.request.use((request) => addBusterToQueryString(request));
  }
}

/**
 * Create BaseHttpService extends EventEmitter.
 * http client for AC.
 *
 * @param {Object} options - config object.
 * @param {Object} options.allowedMethods - list of method to expose.
 * @param {Object} options.config.headers - headers.
 * @param {Object} options.config.isExternalApi - is client used to contact external (not LP's) API
 * in that case, auth headers will not be attached.
 * It also removes handling of 401 (Unauthorized) error, that enabled
 * by default for LP's APIs
 * @param {Object} options.retryConfig - retry configure
 * @param {Object} options.retryConfig.retries - number of retries (default 3)
 * should be reset between retries
 * @param {Object} options.retryConfig.retryCondition - A function to determine if
 * the error can be retried
 * @param {Object} options.retryConfig.retryDelay - A function to determine the
 * delay between retry requests
 *
 */

/* eslint-disable no-param-reassign  */
/* eslint-disable no-shadow  */
export default class BaseClient extends EventEmitter {
  constructor(options) {
    if (!options || !options.config) {
      throw new Error('BaseClient must get options for basic config: config : { baseURI:  }');
    }
    if (!options.config.params) {
      options.config.params = {};
    }
    options.allowedMethods = options.allowedMethods || [CRUDMethods.GET];

    super();
    this.logger = logger;
    this.options = options;
    this.options.config.headers = options.config.isExternalApi
      ? loadDefaultHeaders(options.config.headers)
      : loadAuthHeaders(loadDefaultHeaders(options.config.headers));
    if (BaseClient.checkIfSourceExists(options) === false) {
      this.options.config.params = cloneDeep(this.options.config.params);
      this.options.config.params.source = BaseClient.buildSourceName(options.source);
    }
    const config = {
      ...options.config,
      validateStatus: (status) => (status >= 200 && status < 300) || status === 304,
    };
    this.instance = axios.create(config);
    axiosRetry(this.instance, options.retryConfig || {});

    options.allowedMethods.forEach((opt) => {
      this[opt] = this.instance[opt];
    });

    if (!options.config.isExternalApi) {
      loadResponseInterceptors(this.instance, options.errorHandler);
    }

    loadRequestInterceptors(this.instance, options);
  }

  setBaseUrl(url) {
    this.instance.defaults.baseURL = url;
  }

  getBaseUri() {
    return this.instance.defaults.baseURL;
  }

  getBaseDefaults() {
    return this.instance.defaults;
  }

  useRequestInterceptor(interceptor, errorHandler = (error) => Promise.reject(error)) {
    this.instance.interceptors.request.use(interceptor, errorHandler);
  }

  useResponseInterceptor(interceptor, errorHandler = (error) => Promise.reject(error)) {
    this.instance.interceptors.response.use(interceptor, errorHandler);
  }

  /**
   * Checks if the source parameter already exists in the baseUrl or in the query parameters object
   * @param options
   * @returns {boolean}
   */
  static checkIfSourceExists(options) {
    if (!options || !options.config) {
      return false;
    }
    const url = (options.config && options.config.baseURL) || '';
    return (!!url && url.includes('source='))
      || Object.prototype.hasOwnProperty.call((options.config.params) || {}, 'source');
  }

  /**
   * Returns the string to be used for the source query parameter
   * @param source Optional source object, containing projectName, appName and contextName.
   * @returns {string}
   */
  static buildSourceName(source = {}) {
    return apiLayerUtils.buildSourceName(source);
  }

  /**
   * unsubscribe
   * use axios cancel obj, clear all timeouts.
   * @param {Object} cancelObject - config object (return object of subscription).
   *
   */
  unsubscribe(cancelObject, clearSubscriptions = true) {
    if (!cancelObject) {
      logger.error('BaseClient:unsubscribe, cancelObject is required');
      throw new Error('BaseClient:unsubscribe, cancelObject is required');
    } else if (cancelObject.cancel) {
      if (clearSubscriptions) {
        /*
          by default, we want the clearSubscription() to be invoked when unsubscribe()
          is called from outside (the invoker client intentionally unsubscribes)
          however there are cases where we do not want to clear the subscription
          (when we update the subscription in case of a revision change in the ACReadClient,
          see the comment inm the updateRevisionedSubscription method)
        */
        this.clearSubscription();
      }
      cancelObject.cancel();
    }
    return clearTimeout(this.pollingTimeoutRef);
  }

  clearSubscription() {
    this.clearAllListeners(EVENTS.onSubscriptionUpdateNotification);
    this.clearAllListeners(EVENTS.onSubscriptionError);
    this.clearAllListeners(EVENTS.onSubscriptionBeforeRequestNotification);
  }

  /**
   * subscribe
   * subscribe and get events....
   *
   * @param {Object} options - config object.
   * @param {Number} options.interval - interval.
   * @param {Number} options.retries - number of retries (if set to -1 will fail for ever).
   * @param {String} options.method - crud method.
   * @param {String} options.uri - uri.
   * @param {Object} options.body - body to send with the request.
   * @param {Object} options.config - config for axios.
   *
   */
  subscribe(options = {}) {
    const source = CancelToken.source();
    const interval = options.interval || 10000;
    const method = options.method || CRUDMethods.GET;
    const uri = options.uri || undefined;
    const body = options.body || undefined;
    const config = options.config || undefined;

    Object.assign(config || {}, { cancelToken: source.token });

    const pollingOptions = {
      interval,
      method,
      source,
    };

    async function pollingLoop(uri, body, config) {
      const context = this;
      try {
        context.emit(EVENTS.onSubscriptionBeforeRequestNotification, body, config);
        const res = await context.instance[pollingOptions.method](
          uri,
          body || config,
          config,
        );
        if (res) {
          context.emit(EVENTS.onSubscriptionUpdateNotification, res);
          // verify previous timeout is clear.
          clearTimeout(context.pollingTimeoutRef);
          context.pollingTimeoutRef = setTimeout(pollingLoop.bind(
            context,
            uri,
            body,
            config,
          ), pollingOptions.interval);
        }
      } catch (e) {
        const errorObj = e || {};
        errorObj.cancelled = axios.isCancel(e);
        context.emit(EVENTS.onSubscriptionError, errorObj);
        if (!errorObj.cancelled) {
          logger.error(`pollingLoop: ${errorObj}`);
        }
      }
    }

    pollingLoop.call(this, uri, body, config);
    return source;
  }
}
