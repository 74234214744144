export const EVENTS = {
  onSubscriptionError: 'onSubscriptionError',
  onSubscriptionUpdateNotification: 'onSubscriptionUpdateNotification',
  onSubscriptionBeforeRequestNotification: 'onSubscriptionBeforeRequestNotification',
  onRevisionedSubscriptionError: 'onRevisionedSubscriptionError',
  onRevisionedSubscriptionUpdateNotification: 'onRevisionedSubscriptionUpdateNotification',
};

export const HEADERS = {
  IF_MATCH_HEADER: 'If-Match',
  LAST_MODIFIED_HEADER: 'X-LP-Last-Modified',
  E_TAG: 'etag', // old version revision header
  AC_REVISION: 'ac-revision', // newer version revision header - used when services moves to nginx
};

export const DEFAULT_PARSE_ID_NAME = 'id';

export const CRUDMethods = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  HEAD: 'head',
  OPTIONS: 'options',
  PUT: 'put',
  PATCH: 'patch',
};

export const CLIENTS_CSDS = {
  write: 'accountConfigReadWrite',
  read: 'accountConfigReadOnly',

};

export const HTTP_RESPONSE_STATUS_CODES = {
  NOT_MODIFIED: 304,
  FAILED_DEPENDENCY: 424,
};

export const RECIPE_CLIENT_EVENTS = {
  onRecipeUpdateNotification: 'onRecipeUpdateNotification',
  onRecipePollingStopped: 'onRecipePollingStopped',
};

export const RECIPE_RESPONSE_STATUSES = {
  SUCCESS: 'SUCCESS',
  PARTIAL_SUCCESS: 'PARTIAL_SUCCESS',
  FAILED: 'FAILED',
};

export default {
  EVENTS,
  CRUDMethods,
  HEADERS,
  DEFAULT_PARSE_ID_NAME,
  CLIENTS_CSDS,
  HTTP_RESPONSE_STATUS_CODES,
  RECIPE_CLIENT_EVENTS,
  RECIPE_RESPONSE_STATUSES,
};
