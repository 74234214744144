import { SESSION_STATE_KEY } from './keys';

const getDefaultState = () => ({
  [SESSION_STATE_KEY.ID]: undefined,
  [SESSION_STATE_KEY.CSRF]: undefined,
  [SESSION_STATE_KEY.WSUK]: undefined,
  [SESSION_STATE_KEY.GLOB]: undefined,
  [SESSION_STATE_KEY.TTL]: undefined,
  [SESSION_STATE_KEY.OTK]: undefined,
  [SESSION_STATE_KEY.USER_PID]: undefined,
  [SESSION_STATE_KEY.CONFIG]: {},

  [SESSION_STATE_KEY.LOGIN_NAME]: undefined,
  [SESSION_STATE_KEY.USER_ID]: undefined,
  [SESSION_STATE_KEY.SERVER_CURRENT_TIME]: undefined,
  [SESSION_STATE_KEY.TIME_DIFF]: undefined,
  [SESSION_STATE_KEY.SERVER_TIME_ZONE]: undefined,
  [SESSION_STATE_KEY.SERVER_TIME_GMT_DIFF]: undefined,
  [SESSION_STATE_KEY.LPA]: undefined,
  [SESSION_STATE_KEY.ADMIN]: undefined,
  [SESSION_STATE_KEY.ACCOUNT_ID]: undefined,

  [SESSION_STATE_KEY.ACCOUNT_TIMEZONE_ID]: undefined,
  [SESSION_STATE_KEY.USER_PRIVILEGES]: [],
  [SESSION_STATE_KEY.USER_PRIVILEGES_MAP]: {},
  [SESSION_STATE_KEY.BASE_URIS]: [],

  [SESSION_STATE_KEY.SSO_LOGOUT_URL]: undefined,
  [SESSION_STATE_KEY.FEATURES_MAP]: {},
  [SESSION_STATE_KEY.SETTINGS_MAP]: {},
  [SESSION_STATE_KEY.AGENT_GROUPS_MAP]: {},

  [SESSION_STATE_KEY.DEFAULT_PERSONA]: SESSION_STATE_KEY.GUEST,
  [SESSION_STATE_KEY.PERSONA]: undefined,
  [SESSION_STATE_KEY.PERSONAS]: [],
});

export default getDefaultState;
