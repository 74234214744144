import {
  clone, cloneDeep, isBoolean, isFunction, isUndefined,
} from 'lodash-es';
import { SESSION_STATE_KEY } from '../../store/modules/session/keys';
import { GET_VALUE } from '../../store/modules/session/getter-types';
import { SESSION } from '../../store/types';

let store;
let logger;

const setStore = (storeArg) => {
  store = storeArg;
};

const setLogger = (loggerArg) => {
  logger = loggerArg.getLogger('LEFramework');
};

const METHODS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
};

const DEFAULTS = {
  timeout: 30000,
  retries: 0,
  cache: 'default',
  XMLHTTPOverride: true,
  contentType: {
    JSON: 'application/json',
    text: 'text/plain',
  },
};

const DEFAULT_HEADER = {
  Accept: '*/*',
  'Content-Type': DEFAULTS.contentType.JSON,
};

function generateCrossRequest() {
  const req = cloneDeep({
    url: '',
    method: 'GET',
    encoding: 'utf-8',
    cache: 'no-cache',
    headers: {},
    callback: 'cb',
    data: {},
    query: {},
    retries: 2,
    timeout: 30,
    XMLHTTPOverride: true,
    mimeType: 'application/json',
    error: () => {
    },
    success: () => {
    },
    context: {},
  });
  // Default to not use init
  delete req.init;

  delete req.transportOrder;
  // Default to not use any progress handler which will get called
  // back for every XHR update and incurs a post message from the iframe
  // For any specific need for this progress handler, it should be supplied via the options object
  delete req.progress;

  return req;
}

function getBody(data) {
  let body = (data && data.body) || data;
  if (body && typeof body === 'string') {
    try {
      body = JSON.parse(body);
    } catch (ex) {
      logger.error(`Cannot parse JSON body from API, \nERROR:${ex}, \nBODY:${body}`);
      logger.graph({ name: 'jquery.crossdomain:_getBody:CannotParseJSONBody' });
    }
  }
  return body;
}

function getResponseCode(data, dataType) {
  /* eslint-disable no-param-reassign */
  let resCode = 400;

  if (data && (data.responseCode || Number(data.HTTPStatus))) {
    resCode = data.responseCode || Number(data.HTTPStatus);
  } else if (data && data.ResultSet) {
    if (data.ResultSet.lpCallId) {
      resCode = 200;
    }
  } else if (data && dataType === 'jsonp' && data.status) {
    data.HTTPStatus = data.HTTPStatus || data.status;
    if (data.status.toLowerCase() === 'ok') {
      resCode = 200;
    }
  }

  return resCode;
}

const transportTypes = {
  lpajax: {
    jquery: 'lpajax-lpajax',
    lpajax: 'lpajax',
    getCode: getResponseCode,
    getBody,
  },
};

function getTransportOrder(req, options) {
  let transportOrder;
  if (options.dataType === 'jsonp') {
    transportOrder = ['jsonp'];
  } else if (options.dataType === 'filedownload') {
    transportOrder = ['filedownload'];
  } else if (options.dataType === 'postmessage') {
    transportOrder = ['postmessage'];
  } else {
    // transportOrder = ['fetch','postmessage'];
    transportOrder = ['fetch'];
    // transportOrder = ['postmessage'];

    /* ************ Temporarily removing WebSockets until Batchelor issues are resolved ******* */
    // if( !req.headers.Accept ||
    //    ( ||
    //        req.headers.Accept.indexOf('*/*') > -1){
    //    transportOrder.unshift('websocket2http');
    // }
    /* ************ Temporarily removing WebSockets until Batchelor issues are resolved ******* */
  }
  return transportOrder;
}

function buildHeaders(options) {
  const csrf = store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.CSRF);
  const glob = store.getters[`${SESSION}/${GET_VALUE}`](SESSION_STATE_KEY.GLOB);
  const headers = clone(DEFAULT_HEADER);
  if (options.dataType) {
    headers['Content-Type'] = options.dataType;
  }

  if (csrf) {
    headers['x-csrf-token'] = csrf;
  }

  if (glob) {
    headers.authorization = `Bearer ${glob}`;
  }

  return headers;
}

function setUpRequest(req, options, transportType) {
  req.headers = buildHeaders(options);
  req.method = options.method;
  req.callbackName = options.lpAjaxCallbackName || req.callbackName;
  req.transportOrder = getTransportOrder(req, options);
  // Pass data attribute for lpAjax to use inside the body of the
  // Request only (POST/PUT). Otherwise, Jquery already parsed it to the
  // URL as querystring parameters and we don't want lpAjax to add it the second time
  // Therfore we first check if jQuery already parsed it
  // (if options.data is undefined it means that jquery already parsed it)
  if (!isUndefined(options.data)) {
    req.data = options.data;
  }
  req.url = options.url;
  req.timeout = options.timeout || DEFAULTS.timeout;
  req.retries = options.retries || DEFAULTS.retries;
  req.callback = options.lpAjaxCallbackParamName || req.callback;
  req.XMLHTTPOverride = DEFAULTS.XMLHTTPOverride;
  req.mimeType = options.contentType || DEFAULTS.contentType;
  req.transportOrder.unshift(transportType.lpajax);
  req.cache = isBoolean(options.cache) ? options.cache : DEFAULTS.cache;
  return req;
}

function fetch(options, transporter) {
  const { success } = options;
  const errorCallback = options.error;
  options.method = METHODS.GET;

  let req = generateCrossRequest();
  req = setUpRequest(req, options, transportTypes.lpajax);

  req.error = errorCallback;

  req.success = (resp) => {
    if (isFunction(success)) {
      success(resp.body, resp, options);
    }
  };

  return transporter.issueCall(req);
}

function destroy(options, transporter) {
  options = options || {};
  options.method = METHODS.DELETE;
  const successFunc = options.success;
  const errorFunc = options.error;

  options.error = (model, response, opts) => {
    // var calcName = model._getModeLName();
    const calcStatus = (options && options.xhr && options.xhr.status) ? options.xhr.status : '';

    logger.warn(
      `framework:BaseModel:deleteError: ${calcStatus},'destroy`,
      { options: opts, response },
    );

    if (isFunction(errorFunc)) {
      errorFunc(model, response, options);
    }
  };

  options.success = (resp) => {
    if (isFunction(successFunc)) {
      successFunc(resp.body, resp, options);
    }
  };

  let req = generateCrossRequest();
  req = setUpRequest(req, options, transportTypes.lpajax);
  req.error = options.error;
  req.success = options.success;

  return transporter.issueCall(req);
}

function create(options, transporter) {
  const successFn = options.success;
  const errorCallback = options.error;

  options.method = METHODS.POST;

  let req = generateCrossRequest();
  req = setUpRequest(req, options, transportTypes.lpajax);
  req.success = (resp) => {
    successFn(resp.body, resp, options);
  };

  req.error = errorCallback;

  return transporter.issueCall(req);
}

export default {
  setStore,
  setLogger,
  create,
  fetch,
  destroy,
  DEFAULTS,
  generateCrossRequest,
  getBody,
  getResponseCode,
  getTransportOrder,
  buildHeaders,
  setUpRequest,
};
