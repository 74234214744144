import { AUTH_ERROR } from './keys';
import COMMON_CONSTANTS from '../../assets/common-constants-ids.json';
import gChannel from '../../lib/gChannel';

const SessionKeepAlive = (() => {
  let AUTHENTICATION_CHECK_INTERVAL;
  let APP_SERVER_KEEP_ALIVE_SESSION_INTERVAL;
  const MAGIC_NUMBER_TIME_DIFF = 30 * 1000;

  function initialize(options) {
    /* jshint validthis: true */
    this.translator = options.translator;
    this.notifier = options.notifier;
    this.sessionManager = options.sessionManager;
    this.logger = options.logger;
    this.configOptions = options.configOptions;
    this.events = options.events;
    AUTHENTICATION_CHECK_INTERVAL = this.configOptions.KeepAliveInterval * 1000;
    APP_SERVER_KEEP_ALIVE_SESSION_INTERVAL = this.configOptions.AppServerKeepAliveInterval * 1000;

    this.logger.debug('initialize: sessionKeepAlive was initialized');
    return this;
  }

  function forceSessionExpire(options) {
    /* jshint validthis: true */
    this.stop();
    this.sessionManager.expire(options);
  }

  function checkAuthentication() {
    /* eslint-disable no-use-before-define */
    /* jshint validthis: true */
    // First see if we are authenticated
    this.logger.debug('checkAuthentication: It is time to check if the session should be refreshed');
    if (this.sessionManager.isAuthenticated()) {
      this.logger.debug('checkAuthentication: Current session is valid');
      // Keep alive the session in case the touched flag is set to true
      if (this.isTouched) {
        this.logger.debug('checkAuthentication: User is active, Going to refresh the session');
        doKeepAlive.call(this);
      } else {
        this.logger.debug('checkAuthentication: User is inactive, Wait for next check');
      }

      this.logger.debug(`checkAuthentication: Set next check in ${AUTHENTICATION_CHECK_INTERVAL} millis`);
      this.authTimeoutId = setTimeout(
        checkAuthentication.bind(this),
        AUTHENTICATION_CHECK_INTERVAL,
      );
    } else {
      this.logger.debug('checkAuthentication: Current session is invalid, Going to force logout');
      // Probably logout operations performed from other tabs of the same browser
      forceSessionExpire.call(this, {
        noDestroyForCurrent: true,
        errorCode: AUTH_ERROR.ERROR_LOGIN_DIFFERENT_BROWSER,
      });
    }
  }

  function getSessionLifeTime() {
    /* jshint validthis: true */
    return Number(this.sessionManager.getAccountSettingValueByID(
      this.sessionManager.ACCOUNT_SETTINGS_ID.SESSION_TIMEOUT,
    ))
      || this.configOptions.LeServer * 1000;
  }

  function getSessionStartTime() {
    /* jshint validthis: true */
    this.sessionStartTime = this.sessionManager.getLastTouched()
      || this.sessionStartTime || (new Date()).getTime();
    return this.sessionStartTime;
  }

  function getUnTouchedPeriod() {
    /* jshint validthis: true */
    return ((new Date()).getTime() - getSessionStartTime.call(this));
  }

  function getSessionTimeLeft() {
    /* jshint validthis: true */
    return getSessionLifeTime.call(this) - getUnTouchedPeriod.call(this);
  }

  function forceSessionRefresh() {
    /* jshint validthis: true */
    this.stop();
    this.isTouched = true;
    checkAuthentication.call(this);
  }

  function checkSessionState() {
    /* jshint validthis: true */
    let timeLeft;

    this.logger.debug('checkSessionState: It is time to check if the session expiry popup should be shown');
    // Only if the touched flag is no set to true (there was no
    // activity in the last AUTHENTICATION_CHECK_INTERVAL moments
    if (!this.isTouched) {
      this.logger.debug('checkSessionState: User is inactive, continue to check for popup');
      // How long we still have for the current session?
      timeLeft = getSessionTimeLeft.call(this);

      if (timeLeft > this.configOptions.WarnBeforePeriod * 1000) {
        this.logger.debug(`checkSessionState: Set the timer for showing the popup in 
        ${(timeLeft - (this.configOptions.WarnBeforePeriod * 1000))} millis of inactivity`);
        // This means that we still have more time on the session than the time we should warn
        // Might happen, but not expected - keep on watching
        this.timeoutId = setTimeout(
          checkSessionState.bind(this),
          timeLeft - (this.configOptions.WarnBeforePeriod * 1000),
        );
      } else if (timeLeft > 0) {
        this.logger.debug('checkSessionState: Popup should be shown');
        // First lets check that there is no notification on now
        gChannel.trigger(COMMON_CONSTANTS.INFRA_CHANNEL, COMMON_CONSTANTS.ON_SESSION_END);
        this.events.publish({
          eventName: 'session:onSessionStatePopupShow',
          data: {
            notification: {
              title: this.translator.translate('LEFramework.keepAlive.timeout.title'),
              content: this.translator.translate('LEFramework.keepAlive.timeout.message'),
              objectAction: {
                text: this.translator.translate('LEFramework.keepAlive.timeout.okText'),
                callback: forceSessionRefresh.bind(this),
                textAdditionalButton: this.translator.translate('LEFramework.signOutConfirmation.okText'),
                cancelCallback: forceSessionExpire.bind(this),
              },
            },
          },
        });

        // In case the user does nothing or cancel set timer for shorter time - for expiration
        this.logger.debug('checkSessionState: Set the expiration timer');
        this.expirationTimeoutId = setTimeout(checkSessionState.bind(this), timeLeft);
      } else {
        // Check if session had been sustained from a different
        // In this case, if the last inform is future, session had already been sustained
        this.logger.debug('checkSessionState: Check if session had been sustained from a different browser tab');
        const lastInform = getSessionStartTime.call(this)
          + (this.configOptions.WarnBeforePeriod * 1000);
        if (lastInform > ((new Date()).getTime())) {
          // Session had been sustained from a different browser tab - Just restart
          this.logger.debug('checkSessionState: Session had been sustained from a different browser tab - Just restart');
          this.start();
        } else {
          this.logger.debug('checkSessionState: Current session is invalid, Going to force logout');
          forceSessionExpire.call(this);
        }
      }
    }
  }

  function start() {
    /* jshint validthis: true */
    this.logger.debug('start: First need to stop all existing timers');
    this.stop();

    this.logger.debug('start: Restart all needed timers');
    getSessionStartTime.call(this);

    this.isTouched = false;
    this.authTimeoutId = setTimeout(checkAuthentication.bind(this), AUTHENTICATION_CHECK_INTERVAL);

    checkSessionState.call(this);
  }

  function doKeepAlive() {
    /* jshint validthis: true */
    this.logger.debug('_doKeepAlive: Going to refresh the session');
    this.sessionManager.sustain(null, {
      success: start.bind(this),
      error: start.bind(this),
    });
  }

  function stopAppServerKeepAlive() {
    /* jshint validthis: true */
    this.logger.debug('stopAppServerKeepAlive: Stop app server session');
    if (this.appServerKeepAliveTimeoutId) {
      // Clear remaining keep alive appServer session timer
      clearTimeout(this.appServerKeepAliveTimeoutId);
      delete this.appServerKeepAliveTimeoutId;
    }
  }

  function appServerKeepAlive() {
    /* eslint-disable no-use-before-define */
    /* jshint validthis: true */
    // First see if we are authenticated
    this.logger.debug('appServerKeepAlive: It is time to check if the appServer session should be refreshed');
    if (this.sessionManager.isAuthenticated()) {
      this.logger.debug('appServerKeepAlive: Current appServer session is valid');
      this.sessionManager.appServerSustain(null, {
        success: startAppServerKeepAlive.bind(this),
        error: stopAppServerKeepAlive.bind(this),
      });
    } else {
      this.logger.debug('_keepAliveAppServerSession: Current app server session is invalid');
      stopAppServerKeepAlive.call(this);
    }
  }

  function startAppServerKeepAlive() {
    /* jshint validthis: true */
    this.logger.debug('startAppServerKeepAlive: start AppServer Session');
    this.appServerKeepAliveTimeoutId = setTimeout(
      appServerKeepAlive.bind(this),
      APP_SERVER_KEEP_ALIVE_SESSION_INTERVAL,
    );
  }

  function touched() {
    /* jshint validthis: true */
    let timeLeft;

    if (!this.isTouched) {
      this.logger.debug('touched: User is back to activity, Lets check if session should be refreshed now');
      timeLeft = getSessionTimeLeft.call(this);

      if (timeLeft <= (AUTHENTICATION_CHECK_INTERVAL + MAGIC_NUMBER_TIME_DIFF)) {
        this.logger.debug('touched: The current registered timer, will not be able to refresh the session on time, Going to force a session refresh now!');
        forceSessionRefresh.call(this);
      } else {
        this.logger.debug('touched: The current registered timer, will refresh the session on next tick! Just mark the activity and do nothing else for now');
        this.isTouched = true;
      }
    }
  }

  function stop() {
    /* jshint validthis: true */
    this.logger.debug('stop: Stopping all timers');
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      delete this.timeoutId;
    }
    if (this.expirationTimeoutId) {
      // Clear remaining expiration timer
      clearTimeout(this.expirationTimeoutId);
      delete this.expirationTimeoutId;
    }
    if (this.authTimeoutId) {
      // Clear remaining authenticated timer
      clearTimeout(this.authTimeoutId);
      delete this.authTimeoutId;
    }
    this.events.publish({
      eventName: 'session:onSessionStatePopupClean',
    });

    delete this.sessionStartTime;
    delete this.isTouched;
  }

  return {
    initialize,
    start,
    startAppServerKeepAlive,
    touched,
    stop,
  };
})();

export default SessionKeepAlive;
