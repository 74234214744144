export const GET_CONFIG = 'getConfig';
export const GET_PERSONAS = 'getPersonas';
export const GET_SETTINGS_MAP = 'getSettingsMap';
export const GET_ACCOUNT_SETTINGS_BY_ID = 'getAccountSettingsById';
export const GET_USER_PRIVILEGES_MAP = 'getUserPrivilegesMap';
export const GET_USER_PRIVILEGE_BY_ID = 'getUserPrivilegeById';
export const GET_FEATURE = 'getFeature';
export const GET_FEATURE_STATE = 'getFeatureState';
export const GET_FEATURES = 'getFeatures';
export const GET_CSDS_RESPONSE = 'getCsdsResponse';
export const GET_ACCOUNT_DATA = 'getAccountData';
export const IS_VALID_STATE_ATTRIBUTE = 'isValidAttr';
export const GET_VALUE = 'getValue';
