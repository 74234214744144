class GlobProvider {
  constructor() {
    this.glob = null;
  }

  setGlob(glob) {
    this.glob = glob;
  }

  getGlob() {
    if (this.glob) {
      return this.glob();
    }
    throw new Error('glob not set!');
  }
}

const globProvider = new GlobProvider();
export default globProvider;
